import { BUSINESS_FORM_DATA, BUSINESS_FEE_DATA } from "../actions/business"
import { GET_BUSINESS_FORM_DATA, GET_BUSINESS_FEE_DATA, GET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS } from "../getters/business"
import { SET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS } from "../mutations/business"
import { requestStatus } from "../../utils/constants"
import apiCall from "@/utils/api"

const state = {
    businessFormData: null,
    fetchingBusinessFormData: requestStatus.NOT_SENDING,
    businessFeeData: null
}

const getters = {
    [GET_BUSINESS_FORM_DATA]: state => state.businessFormData,
    [GET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS]: state => state.fetchingBusinessFormData,
    [GET_BUSINESS_FEE_DATA]: state => state.businessFeeData
}

const mutations = {
    [BUSINESS_FORM_DATA]: (state, businessFormData) => {
        state.businessFormData = businessFormData
    },
    [BUSINESS_FEE_DATA]: (state, businessFeeData) => {
        state.businessFeeData = businessFeeData
    },
    [SET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS]: (state, requestStatus) => {
        state.fetchingBusinessFormData = requestStatus
    }
}

const actions = {
    [BUSINESS_FORM_DATA]: async ({ commit }) => {
        try {
            commit(SET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS, requestStatus.SENDING)
            const response = await apiCall({
                url: "/api/client/business?type=formData",
                method: "GET",
            })
            commit(BUSINESS_FORM_DATA, response)
            commit(SET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS, requestStatus.COMPLETE)
        } catch (error) {
            commit(SET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS, requestStatus.ERROR)
            console.log(error)
        }
    },
    [BUSINESS_FEE_DATA]: async ({ commit }) => {
        try {
            const response = await apiCall({
                url: '/api/client/business?type=FeeData',
                method: 'GET'
            })
            commit(BUSINESS_FEE_DATA, response)
        } catch (error) {
            console.log(error)
        }
    }
}

export default {
    state, getters, actions, mutations
}