import { onMounted, reactive } from 'vue'

const initialSnackbarData = {
    open: false,
    text: '',
    color: null,
    timeout: 2000,
    top: true
}

export const snackbarData = reactive(initialSnackbarData)

export function resetSnackbarOnMount() {
    onMounted(() => {
        Object.entries(initialSnackbarData).forEach(([property, value]) => {
            snackbarData[property] = value
        })
    })
}

export function setSnackbar({ open, text, color, timeout, top }) {
    snackbarData.text = text
    snackbarData.color = color
    snackbarData.open = open
}
