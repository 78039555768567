import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en: {
        //notifications
        anErrorOccurredCouldntGetFormDataMsg: "An error occurred; Couldn't get Form Data",
        propertyUpdatedSuccessfullyMsg: "Property updated successfully.",
        anErrorOccurredMsg: "An error occurred.",
        propertyAddedSuccessfullyMsg: "Property added successfully.",
        ownersAddedSuccessfullyMsg: "Owners added successfully.",
        pleaseAddAClientMsg: "Please add a client",
        pleaseAddADateFromMsg: "Please add a Date From",
        ownerAddedTentativelyMsg: "Owner added tentatively",
        propertyDeletedSuccesfullyMsg: "Property Deleted Succesfully",
        propertyOwnerDeletedSuccesfullyMsg: "Property Owner Deleted Succesfully",
        areYouSureYouWantToDeletePropertyMsg: "Are you sure you want to delete property?",
        areYouSureYouWantToDeletePropertyOwnerMsg: "Are you sure you want to delete property owner?",
        wrongEmailOrPasswordAccountIsNotActivatedMsg: "Wrong email or password/Account is not activated/verified.",
        weveSentYouAResetLinkViaEmailMsg: "We've sent you a reset link via email.",
        accountDoesNotExistMsg: "Account does not exist",
        passwordsDoNotMatchMsg: "Passwords do not match",
        passwordResetSuccessfulMsg: "Password reset successful.",
        resetPasswordMsg: "Reset Password",
        newPasswordMsg: "New Password",
        confirmPasswordMsg: "Confirm Password",
        passwordIsRequiredMsg: "Password is required",
        minimum8CharactersMsg: "Minimum 8 characters",
        mustHaveAtLeastOneUppercaseCharacterMsg: "Must have at least one uppercase character",
        mustHaveAtLeastOneNumberMsg: "Must have at least one number",
        mustHaveAtLeastOneSpecialCharacterMsg: "Must have at least one special character [!@#$%.]",
        passwordSuccessfullyChangedMsg: "Password successfully changed",
        invalidExpiredTokenMsg: "Invalid/Expired Token",
        backHomeMsg: "Back Home",

        //global
        settingsMsg: 'Settings',
        languageMsg: 'Language',
        themeMsg: 'Theme',
        darkModeMsg: 'Dark Mode',
        lightModeMsg: 'Light Mode',
        loadingMsg: 'Loading..',
        dashboardMsg: 'Dashboard',
        searchMsg: 'Search',
        actionsMsg: 'Actions',
        selectsMsg: 'Select',
        viewMsg: 'View',
        editMsg: 'Edit',
        deleteMsg: 'Delete',
        removeMsg: 'Remove',
        totalMsg: 'Total',
        saveMsg: 'Save',
        statusMsg: 'Status',
        addMsg: 'Add',
        nextMsg: 'Next',
        backMsg: 'Back',
        profileMsg: 'Profile',
        loginMsg: 'Login',
        signOutMsg: 'Sign Out',
        resetMsg: 'Reset',
        monthNamesMsg: [
            { name: "January" },
            { name: "February" },
            { name: "March" },
            { name: "April" },
            { name: "May" },
            { name: "June" },
            { name: "July" },
            { name: "August" },
            { name: "September" },
            { name: "October" },
            { name: "November" },
            { name: "December" }
        ],
        dateMsg: 'Date',
        yesMsg: 'Yes',
        noMsg: 'No',
        darkModeOnMsg: "Dark Mode On",
        darkModeOffMsg: "Dark Mode Off",

        //home page
        revenueTodayMsg: 'REVENUE TODAY',
        revenueYesterdayMsg: 'REVENUE YESTERDAY',
        revenueThisWeekMsg: 'REVENUE THIS WEEK',
        revenueThisMonthMsg: 'REVENUE THIS MONTH',
        CashInHandMsg: 'CASH IN HAND',
        CashAtAfrilandMsg: 'CASH AT AFRILAND',
        revenuePerModuleMsg: 'REVENUE PER MODULE',
        revenuePerPOSMsg: 'REVENUE PER POS',
        graphsMsg: 'Graphs',
        revenuePerStationMsg: 'Revenue Per Station',
        revenuePerServiceMsg: 'Revenue Per Service',
        periodMsg: 'Period',
        revenueMsg: 'Revenue',
        todayMsg: 'Today',
        yesterdayMsg: 'Yesterday',
        thisWeekMsg: 'This Week',
        thisMonthMsg: 'This Month',

        //navigation drawer
        homeMsg: 'Home',

        accessControlMsg: 'Access Control',
        rolesMsg: 'Roles',
        usersMsg: 'Users',

        administrationMsg: 'Administration',

        financeMsg: 'Finance',
        financeDepartmentsMsg: 'Finance Departments',
        financeSectionsMsg: 'Finance Sections',
        financeCostCentresMsg: 'Finance Cost Centres',
        costCentresFeesMsg: 'Cost Centres Fees',
        ifmisAccountsMsg: 'IFMIS Accounts',
        incomeTypesMsg: 'Income Types',
        paymentModesMsg: 'Payment Modes',

        locationMsg: 'Location',
        subCountiesMsg: 'Subcounties',
        wardsMsg: 'Wards',
        townsMsg: 'Towns',
        townMsg: 'Town',
        villagesMsg: 'Villages',

        zonesMsg: 'Zones',
        zoneNamesMsg: 'Zone Names',
        zoneFeesMsg: 'Zone Fees',
        zoneLocationMsg: 'Zone Location',

        countryMsg: 'Country',
        countryNamesMsg: 'Country Names',
        currencyMsg: 'Currency',

        banksMsg: 'Banks',
        bankNamesMsg: 'Bank Names',
        bankBranchesMsg: 'Bank Branches',
        bankAccountsMsg: 'Bank Accounts',

        schedulesMsg: 'Schedules',
        scheduleNamesMsg: 'Schedule Names',
        schedulePartsMsg: 'Schedule Parts',
        scheduleSubPartsMsg: 'Schedule Sub Parts',
        scheduleSubPartFeesMsg: 'Schedule Sub Part Fees',

        advertisementsMsg: 'Advertisements',
        adSizeTypesMsg: 'Ad Size Types',
        adCategoriesMsg: 'Ad Categories',
        adSubCategoriesMsg: 'Ad Sub Categories',

        customerManagementMsg: 'Customer Management',
        propertiesMsg: 'Properties',
        liquorLicenseMsg: 'Liquor License',
        businessIntelligenceMsg: 'Business Intelligence',
        businessPermitsMsg: 'Business Permits',
        generalBillingMsg: 'General Billing',
        paymentsMsg: 'Payments',
        periodicBillingMsg: 'Periodic Billing',
        creditNoteWaiverMsg: 'Credit Note Waiver',
        houseRentalMsg: 'House Rental',
        propertyRatesMsg: 'Property Rates',
        reportsMsg: 'Reports',
        selfHelpGroupMsg: 'Self Help Group',
        elearningMsg: 'Elearning',
        supportMsg: 'Support',

        //customer management
        customerManagementMsg: 'Customer Management',
        businessManagementMsg: 'Business Management',
        newCustomerMsg: 'New Customer',
        noCustomerFoundMsg: 'No Customer Found..',
        customerNumberMsg: 'Customer Number',
        customerNameMsg: 'Customer Name',
        emailAddressMsg: 'Email Address',
        customerTypeMsg: 'Customer Type',
        customerSerialMsg: 'Customer Serial',
        editCustomerMsg: 'Edit Customer',
        createCustomerMsg: 'Create Customer',
        firstNameMsg: 'First Name',
        lastNameMsg: 'Last Name',
        otherNameMsg: 'Other Name(s)',
        organisationNameMsg: 'Organisation Name',
        postalCodeMsg: 'Postal Code',
        postalAddressMsg: 'Postal Address',
        postalTownMsg: 'Postal Town',
        residentialAddressMsg: 'Residential Address',
        identificationNumberMsg: 'Identification Number',
        designationMsg: 'Designation',
        phoneNumberMsg: 'Phone Number',
        businessRegistrationNumberMsg: 'Business Registration Number',
        genderMsg: 'Gender',
        dateOfBirthMsg: 'Date of Birth',
        viewCustomerMsg: 'View Customer',
        businessNumberMsg: 'Business Number',
        primaryPhoneNoMsg: 'Primary Phone No',
        secondaryPhoneNoMsg: 'Secondary Phone No',
        businessMsg: 'Business',
        houseStallMsg: 'House/Stall',
        billsMsg: 'Bills',
        buildingsMsg: 'Buildings',
        vehiclesMsg: 'Vehicles',
        customerDetailsMsg: 'Customer Details',
        idNumberMsg: 'ID Number',
        customerDetailsUpdatedSuccessfullyMsg: 'Customer details updated successfully.',
        customerRegisteredSuccessfullyMsg: 'Customer Registered Successfully.',
        clientDeletedSuccesfullyMsg: 'Client Deleted Succesfully',
        areYouSureYouWantToDeleteCustomerMsg: 'Are you sure you want to delete customer?',
        disabilityMsg: 'Disability',

        //properties
        noPropertyFoundMsg: 'No Property Found..',
        viewPropertyMsg: 'View property',
        addPropertyMsg: 'Add Property',
        prnMsg: 'PRN',
        plotNumberMsg: 'Plot Number',
        blockLRNoMsg: 'Block LR No',
        documentNoMsg: 'Document No',
        minuteNumberMsg: 'Minute Number',
        minuteDateMsg: 'Minute Date',
        physicalLocationMsg: 'Physical Location',
        propertyDocumentTypeMsg: 'Property Document Type',
        formNoMsg: 'Form No',
        subcountyMsg: 'Centre',
        wardMsg: 'Post',
        zoneMsg: 'Zone',
        zoneLocationMsg: 'Zone Location',
        editPropertyMsg: 'Edit Property',
        addPropertyMsg: 'Add Property',
        propertyApplicationSourceMsg: 'Property Application Source',
        propertyAreaUnitMsg: 'Property Area Unit',
        valuationMsg: 'Valuation',
        propertyRoleTypeMsg: 'Property Role Type',
        propertyLandUseTypeMsg: 'Property Land Use Type',
        propertyValuationMsg: 'Property Valuation',
        propertyValuationMethodMsg: 'Property Valuation Method',
        propertyInterestTypeMsg: 'Property Interest Type',
        propertyOwnershipMsg: 'Property Ownership',
        propertyRatingMsg: 'Property Rating',
        propertyRentTypeMsg: 'Property Rent Type',
        propertyCategoryMsg: 'Property Category',
        lengthMsg: 'Length',
        widthMsg: 'Width',
        areaMsg: 'Area',
        totalAreaMsg: 'Total Area',
        siteValueMsg: 'Site Value',
        annualRateMsg: 'Annual Rate',
        annualGroundRentMsg: 'Annual Ground Rent',
        rateableValueMsg: 'Rateable Value',
        authorityReferenceMsg: 'Authority Reference',
        motherPlotPRNMsg: 'Mother Plot PRN',
        assignPropertyToOwnerMsg: 'Assign Property to Owner(s)',
        propertyDetailsMsg: 'Property Details',
        ownerDetailsMsg: 'Owner Details',
        dateFromMsg: 'Date From',
        dateToMsg: 'Date To',
        noOwneraddedMsg: 'No Owner(s) added..',
        noOwnersFound: 'No Owner(s) found..',
        ownersMsg: 'Owner(s)',
        primaryOwnerMsg: 'Primary Owner',
        saveOwnersMsg: 'Save Owners',
        addOwnersMsg: 'Add Owner(s)',
        propertySubDivisionDetailsMsg: 'Property Sub Division Details',
        noSubdivisionFoundMsg: 'No Subdivision(s) found..',
        addSubdivisionMsg: 'Add Subdivision(s)',
        propertyDataMsg: 'Property Data',
        propertyInformationMsg: 'Property Information',
        valuationInformationMsg: 'Valuation Information',
        blockLRNumberMsg: 'Block LR Number',

        //roles
        viewRoleMsg: 'View Role',
        addRoleMsg: 'Add Role',
        editRoleMsg: 'Edit Role',
        noRoleFoundMsg: 'No role found..',
        roleNameMsg: 'Role Name',
        roleGuardMsg: 'Role Guard',
        permissionsMsg: 'Permissions',
        guardNameMsg: 'Guard Name',
        selectedMsg: 'Selected',
        pleasePickAtLeastOnePermissionMsg: 'Please pick at least one permission',
        roleUpdatedSuccessfullyMsg: 'Role updated successfully',
        roleAddedSuccessfullyMsg: 'Role Added Successfully.',
        roleDeletedSuccesfullyMsg: 'Role Deleted Succesfully',
        areYouSureYouWantToDeleteRoleMsg: 'Are you sure you want to delete role?',

        //users
        viewUserMsg: "View User",
        addUserMsg: "Add User",
        noUserFoundMsg: "No user found..",
        nameMsg: "Name",
        dateAddedMsg: "Date Added",
        roleMsg: "Role",
        accountActiveMsg: "Account Active",
        noneMsg: "None",
        editUserMsg: "Edit User",
        leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg: "Leave blank to avoid unintentionally updating the user's password",
        userUpdatedSuccessfullyMsg: "User updated successfully.",
        areYouSureYouWantToCreateUserMsg: "Are you sure you want to create user?",
        userAddedSuccessfullyMsg: "User Added Successfully.",
        anErrorOccurredEmailAlreadyTakenMsg: "An error occurred/Email already taken.",
        areYouSureYouWantToDeleteUserMsg: "Are you sure you want to delete user?",
        userDeletedSuccesfullyMsg: "User Deleted Succesfully",
        areYouSureYouWantToUpdateUserMsg: "Are you sure you want to update user?",

        //profile
        uploadProfilePictureMsg: "Upload Profile Picture",
        selectImageMsg: "Select Image",
        submitMsg: "Submit",
        changeAccountPasswordMsg: "Change Account Password",
        currentPasswordMsg: "Current Password",
        updateMsg: "Update",
        changeImageMsg: "Change Image",
        accountCreationDateMsg: "Account Creation Date",
        accountStatusMsg: "Account Status",
        activeMsg: "Active",
        inactiveMsg: "Inactive",
        changePasswordMsg: "Change Password",
        kindlySelectAFileMsg: "Kindly Select a File",
        newPasswordAndConfirmPasswordDoNotMatchMsg: "New Password and Confirm Password do not match",
        areYouSureYouWantToChangeYourAccountPasswordMsg: "Are you sure you want to change your account password?",
        passwordUpdatedSuccessfullyMsg: "Password Updated Successfully",
        currentPasswordDoesNotMatchWithRecordsMsg: "Current Password does not match with records",
        profilePictureUploadedSuccessfullyMsg: "Profile Picture Uploaded Successfully",

        //general billing
        generalBillingMsg: 'General Billing',
        viewBillgMsg: 'View bill',
        createBillMsg: 'Create Bill',
        editBillMsg: 'Edit Bill',
        noBillFoundMsg: 'No bill found..',
        billNumberMsg: 'Bill Number',
        clientMsg: 'Client',
        billTypeMsg: 'Bill Type',
        //subCountyMsg: 'Sub County',
        remarksMsg: 'Remarks',
        billStatusMsg: 'Bill Status',
        billDetailsMsg: 'Bill Details',
        addBillItemsMsg: 'Add Bill Items',
        billItemsMsg: 'Bill Items',
        searchBillsMsg: 'Search Bills',
        filterBillsMsg: 'Filter Bills',
        feeNameMsg: 'Fee Name',
        subPartMsg: 'Sub Part',
        feeDescriptionMsg: 'Fee Description',
        uoMMsg: 'UoM',
        amountMsg: 'Amount',
        scheduleMsg: 'Schedule',
        schedulePartsMsg: 'Schedule Parts',
        scheduleSubPartsMsg: 'Schedule Sub Parts',
        billItemDetailsMsg: 'Bill Item Details',
        billItemNameMsg: 'Bill Item Name',
        unitCostMsg: 'Unit Cost',
        quantityMsg: 'Quantity',
        subtotalCostMsg: 'Subtotal Cost',
        itemNameMsg: 'Item Name',
        subtotalMsg: 'Subtotal',
        totalPaidMsg: 'Total Paid',
        totalInvoiceGeneratedMsg: 'Total Invoice Generated',
        totalInvoicePaidMsg: 'Total Invoice Paid',
        previousMsg: 'Previous',
        nextMsg: 'Next',
        billDateMsg: 'Bill Date',
        invoicesMsg: 'Invoices',
        noBillItemFoundMsg: 'No bill item(s) found..',
        printBillMsg: 'Download Bill',
        printInvoiceMsg: 'Download Invoice',
        addBillItemMsg: 'Add Bill Item',
        noInvoiceFoundMsg: 'No invoice(s) found..',
        invoiceNumberMsg: 'Invoice Number',
        descriptionMsg: 'Description',
        invoiceAmountMsg: 'Invoice Amount',
        generateInvoiceMsg: 'Generate Invoice',
        clientNameMsg: 'Client Name',
        clientNumberMsg: 'Client Number',
        invoiceDetailsMsg: 'Invoice Details',
        generateMsg: 'Generate',
        billUpdatedSuccessfullyMsg: 'Bill updated successfully.',
        billAddedSuccessfullyMsg: 'Bill Added Successfully.',
        invoiceGeneratedSuccessfullyMsg: 'Invoice Generated Successfully.',
        billPdfGeneratedSuccessfullyMsg: 'Bill pdf generated successfully.',
        invoicePdfGeneratedSuccessfullyMsg: 'Invoice pdf generated successfully.',
        billDeletedSuccesfullyMsg: 'Bill Deleted Succesfully',
        areYouSureYouWantToDeleteBillItemMsg: 'Are you sure you want to delete bill item?',
        billItemDeletedSuccesfullyMsg: 'Bill Item Deleted Succesfully',
        pleaseAddAnItemMsg: 'Please add an item',
        pleaseAddQuantityMsg: 'Please add quantity',
        billItemAddedTentativelyMsg: 'Bill item added tentatively',
        areYouSureYouWantToDeleteBillMsg: 'Are you sure you want to delete bill?',
        invoiceAmountEnteredInAdditionToPreviouslyGeneratedInvoicesExceedsBillTotalCostMsg: "Invoice amount entered, in addition to previously generated invoices, exceeds bill total cost",

        //payments
        viewPaymentMsg: 'View payment',
        addPaymentMsg: 'Add Payment',
        editPaymentMsg: 'Edit Payment',
        noPaymentFoundMsg: 'No payment found..',
        refNumberMsg: 'Ref Number',
        paymentDateMsg: 'Payment Date',
        paymentModeMsg: 'Payment Mode',
        paymentDetailsMsg: 'Payment Details',
        refNumberCodeMsg: 'Ref Number/Code',
        refDateMsg: 'Ref Date',
        refBankMsg: 'Ref Bank',
        totalBillAmountMsg: 'Total Bill Amount',
        receiptDetailsMsg: 'Receipt Details',
        invoiceDateMsg: 'Invoice Date',
        bankMsg: 'Bank',
        noUnpaidInvoiceFoundMsg: 'No unpaid invoice found..',
        receiptRefMsg: 'Receipt Ref',
        printReceiptMsg: 'Print Receipt',
        areYouSureYouWantToMakeThisPaymentMsg: 'Are you sure you want to make this payment?',
        areYouSureYouWantToDeleteReceiptMsg: 'Are you sure you want to delete receipt?',
        receiptUpdatedSuccessfullyMsg: 'Receipt updated successfully.',
        paymentAddedSuccessfullyMsg: 'Payment Added Successfully.',
        receiptDeletedSuccesfullyMsg: 'Receipt Deleted Succesfully',
        receiptPdfGeneratedSuccessfullyMsg: 'Receipt Pdf Generated Successfully',


        //dashboard
        revenueManagementSystemMsg: 'Revenue Management System',
        aboutMsg: 'About',
        homeP1Msg: "BIRAS is A Robust, Ready To Use Software For Centralized/Decentralized Government Processes At The Province, Territory And Block Level In A Uniform Manner, Encompassing Global E-Governance Standards. The Solution Is An Integrated Web Based Software For All Departments And Divisions of Provincial Governments, Which Enables Information Sharing Across The Province.",
        homeP2Msg: "The Software Complies With All Laws And Regulations, And Is Also Compliant With Internationally Governed Rights Security Levels, And Encryption Standards. The System Is Developed In Adherence To Global EGovernance Standards, And Provides Tools To Eliminate Unscrupulous Practices, And To Introduce Transparency And Accountability By Providing Electronic Tracking Of Citizen Service Delivery And Revenue Collections.",
        homeP3Msg: "The Solution Offers A Citizen Centric Portal To Promote Effective Governance, To Increase Citizen Participation In Governance, And To Enable Access To All Government Services Online.",
        homeP4Msg: "As Opposed To Commonly Deployed Revenue Receipting Systems, BIRAS Enables Governments To Augment Revenues By Focusing On Operations Management And Services Delivery, That Results In Increased Revenues.The Solution, Make It A Unique Solution.",

        //auth
        poweredByMsg: 'Powered by',
        passwordMsg: 'Password',
        forgotPasswordMsg: 'Forgot Password',
        sendMeALinkMsg: 'Send me a link..',
        backToLoginMsg: 'Back to Login',

        //footer
        quickLinksMsg: 'Quick Links',
        ourWorkMsg: 'Our Work',
        contactsMsg: 'Contacts',
        privacyPolicyMsg: 'Privacy Policy',
        termsOfUseMsg: 'Terms of Use',

        //house Rental
        houseGenerateInvoiceMsg: 'Generate Invoice',
        houseLandlordsMsg: 'House Landlords',
        houseRegistrationMsg: 'House Registration',
        houseTenantsMsg: 'House Tenants',
        houseEstatesMsg: 'House Estates',
        houseStallTypeMsg: 'House Stall Type',
        houseStatusMsg: 'House Status',
        housepplicationMsg: 'House Application',
        tenantPaymodeMsg: 'Tenant Paymode',
        tenantEmployerMsg: 'Tenant Employer',
        houseConfigurationMsg: 'Configuration',
        houseApartmentMsg: 'Apartments',
        //register Apartments
        apartmentRegistrationMsg: 'Apartment Registration',
        newApartmentMsg: 'New Apartment',
        noApartmentMsg: 'No Apartment found..',
        apartmentNumberMsg: 'Apartment Number',
        estateAreaMsg: 'Estate Area',
        editApartmentMsg: 'Edit Apartment',
        apartmentHRNMsg: 'Apartment HRN',
        blockNoMsg: 'Block Number',
        plotNoMsg: 'Plot Number',
        estatesMsg: 'Estate',
        floorsMsg: 'Floors',
        landlordsMsg: 'Landlord',
        propertyCodeMsg: 'Property Code',
        landlordNameMsg: 'Landlord Name',
        tenantTaxMsg: 'Tenant Tax %',
        tenantBalanceMsg: 'Tenant Balance',
        landlordtaxMsg: 'Ladlord Tax %',
        landlordbalanceMsg: 'Landlord Balance',
        addLandlordeMsg: 'Add Landlord',


        newLandlordMsg: 'New Landlord',
        houseNumberMsg: 'House Number',
        houseTypeMsg: 'House Type',
        houseFloorMsg: 'House Floor',
        houseStatusMsg: 'House Status',
        occupiedMsg: 'Occupied',
        unoccupiedMsg: 'Unoccupied',
        tenantNameMsg: 'Tenant Name',
        submitSelfMsg: 'Self',
        tenantEmployerMsg: 'Tenant Employer',
        formNumberMsg: 'Form Number',
        monthlyRentMsg: 'Monthly Rent',
        rentArrearsMsg: 'Rent Arrears',
        currentBalanceMsg: 'Current Balance',
        registerHouseMsg: 'Register House',
        taxAmountMsg: 'Tax Amount',
        whoSubmitsMsg: 'Who Submits Rental tax ?',
        newTenantMsg: 'New Tenant',
        tenantDetailsMsg: 'Tenant Details',
        landlordDetailsMsg: 'Landlord Details',
        newtenantEmployerMsg: 'New Tenant Employer',
        vacateHouseMsg: 'Vacate House',
        newEstateMsg: 'New Estate',
        newHouseStatusMsg: 'New House Status',
        vacateMsg: 'Vacate',
        longtitudeMsg: 'Longtitude',
        assignApartmentToLordMsg: 'Assign Apartment to Landlord(s)',

        //Finance Department
        noDepartmentFoundMsg: 'No Department Found..',
        viewDepartmentMsg: 'View department',
        addDepartmentMsg: 'Add Department',
        editDepartmentMsg: 'Edit Department',
        departmentcodeMsg: 'Department Code',
        departmentnameMsg: 'Department Name',
        departmentsMsg: 'Finance Departments',
        departmentupdatedsuccessMsg: "Department updated successfully",
        departmentaddedsuccessMsg: "Department Added Successfully.",
        departmentdeletewarningMsg: "Are you sure you want to delete department?",
        departmentdeletesuccessMsg: "Department Deleted Succesfully",





        //Finance Sections
        nosectionFoundMsg: 'No finance section Found..',
        viewsectionMsg: 'View section',
        addsectionMsg: 'Add section',
        editsectionMsg: 'Edit section',
        sectioncodeMsg: 'Section Code',
        sectionnameMsg: 'Section Name',
        sectionsMsg: 'Finance Section',
        selectdepartmentmsg: 'Select Department',
        selectcostcentremsg: 'Select Cost Centre',
        selectsubpartMsg: "Select Sub Part Fee",
        sectionupdatedsuccessMsg: "Department Section updated successfully",
        sectionaddedsuccessMsg: "Department Section Added Successfully.",
        sectiondeletewarningMsg: "Are you sure you want to delete department section?",
        sectiondeletesuccessMsg: "Department Section Deleted Succesfully",



        //Finance Cost Centres

        nocostcentreFoundMsg: 'No finance cost centre Found..',
        viewcostcentreMsg: 'View Cost Centre',
        addcostcentreMsg: 'Add Cost Centre',
        editcostcentreMsg: 'Edit Cost Centre',
        costcentrecodeMsg: 'Cost Center Code',
        costcentrenameMsg: 'Cost  Centre Name',
        selectsectionmsg: 'Select Section',
        costcentreupdatedsuccessMsg: "Cost Center updated successfully",
        costcentreaddedsuccessMsg: "Cost Center Added Successfully.",
        costcentredeletewarningMsg: "Are you sure you want to delete cost center?",
        costcentredeletesuccessMsg: "Cost Center Deleted Succesfully",



        //Finance Cost Centres Fees

        nocostcentrefeeFoundMsg: 'No finance cost centre fee Found..',
        viewcostcentrefeeMsg: 'View cost centre fee',
        addcostcentrefeeMsg: 'Add Cost Centre Fee',
        editcostcentrefeeMsg: 'Edit Cost Centre Fee',
        costcentrefeeupdatedsuccessMsg: "Cost Center Fee updated successfully",
        costcentrefeeaddedsuccessMsg: "Cost Center Fee Added Successfully.",
        costcentrefeedeletewarningMsg: "Are you sure you want to delete cost center fee ?",
        costcentrefeedeletesuccessMsg: "Cost Center  Fee Deleted Succesfully",

        //Income Type
        noincometypefoundMsg: 'No income type found ',
        viewincometypeMsg: 'View Income Type',
        addincometypeMsg: 'Add Income Type',
        editincometypeMsg: 'Edit Income Type',
        incometypeMsg: 'Income Type',
        incometypeupdatedsuccessMsg: "Income Type updated successfully",
        incometypeaddedsuccessMsg: "Income Type Added Successfully.",
        incometypedeletewarningMsg: "Are you sure you want to delete income type?",
        incometypedeletesuccessMsg: "Income Type Deleted Succesfully",

        //Payment Mode
        nopaymentmodefoundMsg: 'No payment mode found ',
        viewpaymentmodeMsg: 'View payment mode',
        addpaymentmodeMsg: 'Add payment mode',
        editpaymentmodeMsg: 'Edit payment mode',
        paymentmodeMsg: 'Payment mode',
        paymentmodeupdatedsuccessMsg: "Payment Mode updated successfully",
        paymentmodeaddedsuccessMsg: "Payment Mode Added Successfully.",
        paymentmodedeletewarningMsg: "Are you sure you want to delete payment mode?",
        paymentmodedeletesuccessMsg: "Payment Mode Deleted Succesfully",

        //Banks
        nobanksfoundMsg: 'No Banks found ',
        viewbanksMsg: 'View Bank',
        addbanksMsg: 'Add Bank Name',
        editbanksMsg: 'Edit Bank Name',
        banknameMsg: 'Bank Name',
        bankupdatedsuccessMsg: "Bank Name updated successfully",
        bankaddedsuccessMsg: "Bank Name Added Successfully.",
        bankdeletewarningMsg: "Are you sure you want to delete bank name?",
        bankdeletesuccessMsg: "Bank Name Deleted Succesfully",

        //Bank Branch
        nobranchsfoundMsg: 'No bank branch found ',
        viewbranchsMsg: 'View Branch',
        addbranchsMsg: 'Add Branch',
        editbranchsMsg: 'Edit Branch',
        branchnameMsg: 'Branch Name',
        addressMsg: 'Address',
        selectbankMsg: 'Select Bank',
        branchupdatedsuccessMsg: "Branch updated successfully",
        branchaddedsuccessMsg: "Branch Added Successfully.",
        branchdeletewarningMsg: "Are you sure you want to delete branch?",
        branchdeletesuccessMsg: "Branch Deleted Succesfully",

        //Bank Account
        noaccountfoundMsg: 'No bank account found ',
        viewaccountMsg: 'View Account',
        addaccountMsg: 'Add Account',
        editaccountMsg: 'Edit Account',
        accountnameMsg: 'Account Name',
        accountnumberMsg: 'Account Number',
        selectbranchMsg: 'Select Branch',
        bankaccountupdatedsuccessMsg: "Bank account updated successfully",
        bankaccountaddedsuccessMsg: "Bank account Added Successfully.",
        bankaccountdeletewarningMsg: "Are you sure you want to delete bank account?",
        bankaccountdeletesuccessMsg: "Bank account Deleted Succesfully",

        //Zones
        nozonesfoundMsg: 'No zones found ',
        viewzoneMsg: 'View Zone',
        addzoneMsg: 'Add Zone',
        editzoneMsg: 'Edit Zone',
        zonenameMsg: 'Zone Name',
        zoneupdatedsuccessMsg: "Zone updated successfully",
        zoneaddedsuccessMsg: "Zone Added Successfully.",
        zonedeletewarningMsg: "Are you sure you want to delete zone?",
        zonedeletesuccessMsg: "Zone Deleted Succesfully",

        //Zone Fees
        nozonesfeefoundMsg: 'No zones fees found ',
        viewzonefeeMsg: 'View Zone Fee',
        addzonefeeMsg: 'Add Zone Fee',
        editzonefeeMsg: 'Edit Zone Fee',
        zonefeenameMsg: 'Fee Name',
        amountMsg: 'Amount',
        selectzoneMsg: 'Select Zone',
        zonefeesupdatedsuccessMsg: "Zone Fees updated successfully",
        zonefeesaddedsuccessMsg: "Zone Fees Added Successfully.",
        zonefeesdeletewarningMsg: "Are you sure you want to delete zone fee?",
        zonefeesdeletesuccessMsg: "Zone Fees Deleted Succesfully",

        //Zone Location
        nozoneslocationfoundMsg: 'No zones location found ',
        viewzonelocationMsg: 'View Zone Location',
        addzonelocationMsg: 'Add Zone Location',
        editzonelocationMsg: 'Edit Zone Location',
        locationnameMsg: 'Location Name',
        selectzoneMsg: 'Select Zone',
        zonnelocationupdatedsuccessMsg: "Zone Location updated successfully",
        zonnelocationaddedsuccessMsg: "Zone Location Added Successfully.",
        zonnelocationdeletewarningMsg: "Are you sure you want to delete zone location?",
        zonnelocationdeletesuccessMsg: "Zone Location Deleted Succesfully",



        //Countries
        nocountriesfoundMsg: 'No countries found ',
        viewcountryMsg: 'View Country',
        addcountryMsg: 'Add Country',
        editcountryMsg: 'Edit Country',
        countrynameMsg: 'Country Name',
        countrycodeMsg: 'Country Code',
        nationalityMsg: 'Nationality',
        countryupdatedsuccessMsg: "Country updated successfully",
        countryaddedsuccessMsg: "Country Added Successfully.",
        countrydeletewarningMsg: "Are you sure you want to delete country?",
        countrydeletesuccessMsg: "Country Deleted Succesfully",


        //Currencies
        nocurrenciesfoundMsg: 'No countries found ',
        viewcurrencyMsg: 'View currency',
        addcurrencyMsg: 'Add currency',
        editcurrencyMsg: 'Edit currency',
        currencynameMsg: 'Currency Name',
        currencycodeMsg: 'Currency Code',
        selectcountryMsg: 'Select Country',
        currencyupdatedsuccessMsg: "Currency updated successfully",
        currencyaddedsuccessMsg: "Currency Added Successfully.",
        currencydeletewarningMsg: "Are you sure you want to delete currency?",
        currencydeletesuccessMsg: "Currency Deleted Succesfully",

        //Waivers
        nowaiversfoundMsg: 'No waivers found ',
        viewwaiverMsg: 'View waiver',
        addwaiverMsg: 'Add waiver',
        editwaiverMsg: 'Edit waiver',
        financialyearMsg: 'Financial Year',
        //subcountyMsg: 'Subcounty',
        startdateMsg: 'Start Date',
        enddateMsg: 'End Date',
        waiverpercentageMsg: 'Waiver Percentage',
        scopevalueMsg: 'Scope value',
        statusMsg: 'Status',
        waiverupdatedsuccessMsg: "Waiver updated successfully",
        waiveraddedsuccessMsg: "Waiver Added Successfully.",
        waiverdeletewarningMsg: "Are you sure you want to delete waiver?",
        waiverdeletesuccessMsg: "Waiver Deleted Succesfully",


        //Schedules
        noschedulesfoundMsg: 'No schedules found ',
        viewscheduleMsg: 'View schedule',
        addscheduleMsg: 'Add Schedule',
        editscheduleMsg: 'Edit Schedule',
        schedulenameMsg: 'Schedule Name',
        scheduleupdatedsuccessMsg: "Schedules updated successfully",
        scheduleaddedsuccessMsg: "Schedules Added Successfully.",
        scheduledeletewarningMsg: "Are you sure you want to delete schedule?",
        scheduledeletesuccessMsg: "Schedules Deleted Succesfully",


        //Schedules Parts
        noschedulespartsfoundMsg: 'No schedules parts found ',
        viewschedulepartMsg: 'View Schedule Part',
        addschedulepartMsg: 'Add Schedule Part',
        editschedulepartMsg: 'Edit Schedule Part',
        schedulepartnameMsg: 'Part Name',
        schedulepartupdatedsuccessMsg: "Schedule Part updated successfully",
        schedulepartaddedsuccessMsg: "Schedule Part Added Successfully.",
        schedulepartdeletewarningMsg: "Are you sure you want to delete schedule parts?",
        schedulepartdeletesuccessMsg: "Schedule Part Deleted Succesfully",


        //Schedules Sub Parts
        noschedulessubpartsfoundMsg: 'No schedules sub parts found ',
        viewschedulesubpartMsg: 'View Schedule Sub Part',
        addschedulesubpartMsg: 'Add Schedule Sub Part',
        editschedulesubpartMsg: 'Edit Schedule Sub Part',
        subpartnameMsg: 'Sub Part Name',
        selectscheduleparts: 'Select Schedule Part',
        schedulesubpartsupdatedsuccessMsg: "Schedule Sub Part updated successfully",
        schedulesubpartsaddedsuccessMsg: "Schedule Sub Part Added Successfully.",
        schedulesubpartsdeletewarningMsg: "Are you sure you want to delete schedule sub parts?",
        schedulesubpartsdeletesuccessMsg: "Schedule Sub Part Deleted Succesfully",

        //Schedules Sub Parts Fees
        noschedulessubpartsfeefoundMsg: 'No schedules sub parts fees found',
        viewschedulesubpartfeeMsg: 'View Schedule Sub Part Fee',
        addschedulesubpartfeeMsg: 'Add Schedule Sub Part Fee',
        editschedulesubpartfeeMsg: 'Edit Schedule Sub Part Fee',
        subpartfeenameMsg: 'Fee Name',
        subpartfeedescriptionMsg: 'Fee Description',
        subpartfeeamountMsg: 'Amount',
        posServiceCategoryMsg: 'POS Service Category',
        posServiceSubCategoryMsg: 'POS Service Sub Category',
        unitofmeasureMsg: 'Unit of Measure',
        selectscheduleparts: 'Select Schedule Sub Part',
        posfee: "POS fee",
        posservicecategory: "POS Service Category",
        posservicesubcategory: "POS Service Sub Category",
        schedulesubpartfeesupdatedsuccessMsg: "Schedule Sub Part Fees updated successfully",
        schedulesubpartfeesaddedsuccessMsg: "Schedule  Sub Part Fees Added Successfully.",
        schedulesubpartfeesdeletewarningMsg: "Are you sure you want to delete schedule sub part fees?",
        schedulesubpartfeesdeletesuccessMsg: "Schedule Sub Part Fees Deleted Succesfully",


        //Subcounty
        nosubcountiesfoundMsg: 'No Centre found ',
        viewsubcountyMsg: 'View Centre',
        addsubcountyMsg: 'Add Centre',
        editsubcountyMsg: 'Edit Centre',
        subcountynameMsg: 'Centre Name',
        subcountyupdatedsuccessMsg: "Centre updated successfully",
        subcountyaddedsuccessMsg: "Centre Added Successfully.",
        subcountydeletewarningMsg: "Are you sure you want to delete Centre?",
        subcountydeletesuccessMsg: "Centre Deleted Succesfully",


        //Wards
        nowardsfoundMsg: "No Wards found",
        viewWardMsg: "View Ward",
        addwardMsg: "Add Ward",
        editwardMsg: "Edit Ward",
        wardnameMsg: "Ward Name",
        selectsubcountyMsg: "Select Centre",
        wardupdatedsuccessMsg: "Ward updated successfully",
        wardaddedsuccessMsg: "Ward Added Successfully.",
        warddeletewarningMsg: "Are you sure you want to delete ward?",
        warddeletesuccessMsg: "Ward Deleted Succesfully",

        //Towns
        notownsfoundMsg: "No towns found",
        viewtownsMsg: "View Town",
        addtownMsg: "Add Town",
        edittownMsg: "Edit Town",
        townnameMsg: "Town Name",
        selectwardMsg: "Select Post",
        townsupdatedsuccessMsg: "Town updated successfully",
        townsaddedsuccessMsg: "Town Added Successfully.",
        townsdeletewarningMsg: "Are you sure you want to delete town?",
        townsdeletesuccessMsg: "Town Deleted Succesfully",

        //Villages
        novillagesfoundMsg: "No villages found",
        viewvillagesMsg: "View Village",
        addvillagesMsg: "Add Village",
        editvillagesMsg: "Edit Village",
        villagenameMsg: "Village Name",
        villageupdatedsuccessMsg: "Village updated successfully",
        villageaddedsuccessMsg: "Village Added Successfully.",
        villagedeletewarningMsg: "Are you sure you want to delete village?",
        villagedeletesuccessMsg: "Village Deleted Succesfully",


        //Advertisements
        noadvertisementfoundMsg: "No advertisements found",
        viewadvertisementMsg: "View Advertisement",
        addadvertisementMsg: "Add Advertisement",
        editadvertisementMsg: "Edit Advertisement",
        advertisementnameMsg: "Advertisement Name",
        longitudeMsg: "Longitude",
        latitudeMsg: "Latitude",
        advertisementsizetypeMsg: "Advertisement Size Type",
        advertisementSize: 'Advertisement Size',
        advertisementdescriptionMsg: 'Advertisement Description',
        advertisementtypeMsg: 'Advertisement Type',
        advertisementSubCategoryMsg: 'Advertisement Sub Category',
        physicalLocation: 'Physical Location',
        recurrencyMsg: "Recurrency",
        advertisementupdatedsuccessMsg: "Advertisement updated successfully",
        advertisementaddedsuccessMsg: "Advertisement Added Successfully.",
        advertisementdeletewarningMsg: "Are you sure you want to delete advertisement?",
        advertisementdeletesuccessMsg: "Advertisement Deleted Succesfully",
        assignAdvertismentToOwnerMsg: "Assign Advert to Owner",
        selectTownMsg: "Select Town",
        selectSizeTypeMsg: "Select Size Type",
        selectCategoryMsg: "Select Category",
        selectRecurrencyMsg: "Select Recurrency",
        validuntilMsg: "Valid Until",
        parentadMsg: "Parent Advertisement",


        //Advertisements Size
        noadvertisementsizefoundMsg: "No advertisements size found",
        viewadvertisementsizeMsg: "View Advertisement Size",
        addadvertisementsizeMsg: "Add Advertisement Size",
        editadvertisementsizeMsg: "Edit Advertisement Size",
        advertisementSize: 'Advertisement Size',
        advertisementsizeupdatedsuccessMsg: "Advertisement Size updated successfully",
        advertisementsizeaddedsuccessMsg: "Advertisement Size Added Successfully.",
        advertisementsizedeletewarningMsg: "Are you sure you want to delete advertisement size ?",
        advertisementsizedeletesuccessMsg: "Advertisement Size Deleted Succesfully",


        //Advertisements Category
        noadvertisementcategoryfoundMsg: "No advertisements category found",
        viewadvertisementcategoryMsg: "View Advertisement Category",
        addadvertisementcategoryMsg: "Add Advertisement Category",
        editadvertisementcategoryMsg: "Edit Advertisement Category",
        advertisementCategoryMsg: 'Advertisement Category',
        advertcategorynameMsg: "Category Name",
        advertisementcategoryupdatedsuccessMsg: "Advertisement Category updated successfully",
        advertisementcategoryaddedsuccessMsg: "Advertisement Category Added Successfully.",
        advertisementcategorydeletewarningMsg: "Are you sure you want to delete advertisement category ?",
        advertisementcategorydeletesuccessMsg: "Advertisement Category Deleted Succesfully",


        //Advertisements Sub Category
        noadvertisementsubcategoryfoundMsg: "No advertisements sub category found",
        viewadvertisementsubcategoryMsg: "View Advertisement Sub Category",
        addadvertisementsubcategoryMsg: "Add Advertisement Sub Category",
        editadvertisementsubcategoryMsg: "Edit Advertisement Sub Category",
        advertisementSubCategoryMsg: 'Advertisement Sub Category',
        advertsubcategorynameMsg: "Sub Category ",
        advertisementsubcategoryupdatedsuccessMsg: "Advertisement Sub Category updated successfully",
        advertisementsubcategoryaddedsuccessMsg: "Advertisement Sub Category Added Successfully.",
        advertisementsubcategorydeletewarningMsg: "Are you sure you want to delete advertisement sub category ?",
        advertisementsubcategorydeletesuccessMsg: "Advertisement Sub Category Deleted Succesfully",




        //POS
        posManagementMsg: "POS Management",
        posUsersMsg: "POS Users",
        posTransactionsMsg: "POS Transactions",
        posDevicesMsg: "POS Devices",
        posUserSessionsMsg: "POS User Sessions",
        posTransactionTypesMsg: "POS Transaction Types",
        posAgentsMsg: "POS Agents",
        posAgentAccountsMsg: "POS Agent Accounts",
        posBusinessesMsg: "POS Businesses",
        posBusinessAccountsMsg: "POS Business Accounts",
        posMerchantsMsg: "POS Merchants",

        //POS Users
        viewPosUsersMsg: "View POS Users",
        addPosUserMsg: "Add POS User",
        editPosUserMsg: "Edit POS User",
        noPOSUserFoundMsg: "No POS User Found",
        posUserLastNameMsg: "Last Name",
        posUserFirstNameMsg: "First Name",
        posUserOtherNamesMsg: "Other Names",
        posUserFullNameMsg: "Full Name",
        posUserEmailMsg: "Email Address",
        posUserPhoneMsg: "Phone Number",
        posUserBusinessMsg: "Business",
        posUserCountryMsg: "Country",
        posUserIDNoMsg: "ID Number",
        posUserPINNoMsg: "PIN Number",
        posUserMidagaSyncStatusMsg: "Midaga Sync Status",
        posUserMidagaIdMsg: "Midaga ID",
        posUserDeleteWarningMsg: "Are you sure you want to delete the POS User?",
        posUserUpdatedSuccessMsg: "POS User updated successfully",
        posUserAddedSuccessMsg: "POS User Added Successfully.",
        posUserDeleteSuccessMsg: "POS User Deleted Succesfully",
        posUserSyncedMsg: "Synced",
        posUserUnsyncedMsg: "Unsynced",


        //POS Transactions
        viewPosTransactionsMsg: "View POS Transactions",
        addPosTransactionMsg: "Add POS Transaction",
        editPosTransactionsMsg: "Edit POS Transactions",
        deletePosTransactionMsg: "Delete POS Transaction",
        noPosTransactionFoundMsg: "No POS Transaction Found",
        posTransactionTransactionCodeMsg: "Transaction Code",
        posTransactionTransactionTypeMsg: "Transaction Type",
        posTransactionCurrencyMsg: "Currency",
        posTransactionAmountMsg: "Amount",
        posTransactionServiceCategoryMsg: "Service Category",
        posTransactionRecordDateMsg: "Transaction Date",
        posTransactionLatCoordinateMsg: "Latitude",
        posTransactionLongCoordinateMsg: "Longitude",
        posTransactionGPSCoordianteMsg: "GPS Coordinates",
        posTransactionDeviceMsg: "Device",
        posTransactionFeeMsg: "Fee",
        posTransactionPayModeMsg: "Pay Mode",
        posTransactionUserMsg: "Device User",
        posTransactionCustomerNameMsg: "Customer Name",
        posTransactionCustomerPhoneMsg: "Customer Phone",
        posTransactionInboundMsg: "Inbound",
        posTransactionOutboundMsg: "Outbound",
        posTransactionOtherDetailsMsg: "Other Details",
        posTransactionVehicleRegistrationMsg: "Vehicle Registration",
        posTransactionMidagaAuthStatusMsg: "Midaga Authorized",
        posTransactionMidagaTransactionIDMsg: "Midaga Transaction ID",
        posTransactionAuthorizedMsg: "Authorized",
        posTransactionUnauthorizedMsg: "Unauthorized",
        posTransactionAgentCodeMsg: "Agent Code",
        posTransactionMerchantCodeMsg: "Merchant Code",
        posTransactionDeleteWarningMsg: "Are you sure you want to delete the POS Transaction?",
        posTransactionUpdatedSuccessMsg: "POS Transaction updated successfully",
        posTransactionAddedSuccessMsg: "POS Transaction Added Successfully.",
        posTransactionDeleteSuccessMsg: "POS Transaction Deleted Succesfully",


        //POS Devices







    },
    fr: {
        //notifications
        anErrorOccurredCouldntGetFormDataMsg: "Une erreur s'est produite ; Impossible d'obtenir les données du formulaire",
        propertyUpdatedSuccessfullyMsg: "Propriété mise à jour avec succès.",
        anErrorOccurredMsg: "Une erreur s'est produite.",
        propertyAddedSuccessfullyMsg: "Propriété ajoutée avec succès.",
        ownersAddedSuccessfullyMsg: "Propriétaires ajoutés avec succès.",
        pleaseAddAClientMsg: "Veuillez ajouter un client",
        pleaseAddADateFromMsg: "Veuillez ajouter une date de",
        ownerAddedTentativelyMsg: "Propriétaire ajouté provisoirement",
        propertyDeletedSuccesfullyMsg: "Propriété supprimée avec succès",
        propertyOwnerDeletedSuccesfullyMsg: "Propriétaire de propriété supprimé avec succès",
        areYouSureYouWantToDeletePropertyMsg: "Êtes-vous sûr de vouloir supprimer la propriété?",
        areYouSureYouWantToDeletePropertyOwnerMsg: "Êtes-vous sûr de vouloir supprimer le propriétaire?",
        wrongEmailOrPasswordAccountIsNotActivatedMsg: "E-mail ou mot de passe erroné/Compte non activé.",
        weveSentYouAResetLinkViaEmailMsg: "Nous vous avons envoyé un lien de réinitialisation par e-mail.",
        accountDoesNotExistMsg: "Le compte n'existe pas",
        passwordsDoNotMatchMsg: "Les mots de passe ne correspondent pas",
        passwordResetSuccessfulMsg: "Réinitialisation du mot de passe réussie.",
        resetPasswordMsg: "Réinitialiser le mot de passe",
        newPasswordMsg: "Nouveau mot de passe",
        confirmPasswordMsg: "Confirmez le mot de passe",
        passwordIsRequiredMsg: "Mot de passe requis",
        minimum8CharactersMsg: "Au moins 8 caractères",
        mustHaveAtLeastOneUppercaseCharacterMsg: "Doit avoir au moins un caractère majuscule",
        mustHaveAtLeastOneNumberMsg: "Doit avoir au moins un numéro",
        mustHaveAtLeastOneSpecialCharacterMsg: "Doit avoir au moins un caractère spécial [!@#$%.]",
        passwordSuccessfullyChangedMsg: "Mot de passe changé avec succès",
        invalidExpiredTokenMsg: "Jeton invalide/expiré",
        backHomeMsg: "Retour à la page d'accueil",

        //global
        settingsMsg: 'Les paramètres',
        languageMsg: 'Langue',
        themeMsg: 'Thème',
        darkModeMsg: 'Mode Sombre',
        lightModeMsg: 'Mode Lumière',
        loadingMsg: 'Chargement en cours..',
        dashboardMsg: 'Tableau de bord',
        searchMsg: 'Chercher',
        actionsMsg: 'Actions',
        selectsMsg: 'Sélectionner',
        viewMsg: 'Vue',
        editMsg: 'Modifier',
        deleteMsg: 'Effacer',
        removeMsg: 'Supprimer',
        totalMsg: 'Le total',
        saveMsg: 'Enregistrer',
        statusMsg: 'Statut',
        addMsg: 'Ajouter',
        nextMsg: 'Suivante',
        backMsg: 'Arrière',
        profileMsg: 'Profil',
        loginMsg: 'Connexion',
        signOutMsg: 'Se déconnecter',
        resetMsg: 'Réinitialiser',
        monthNamesMsg: [
            { name: "Janvier" },
            { name: "Février" },
            { name: "Mars" },
            { name: "Avril" },
            { name: "Peut" },
            { name: "Juin" },
            { name: "Juillet" },
            { name: "Août" },
            { name: "Septembre" },
            { name: "Octobre" },
            { name: "Novembre" },
            { name: "Décembre" }
        ],
        dateMsg: 'Date',
        yesMsg: 'Oui',
        noMsg: 'Non',
        darkModeOnMsg: "Mode sombre activé",
        darkModeOffMsg: "Mode sombre désactivé",

        //home page
        revenueTodayMsg: "REVENU AUJOURD'HUI",
        revenueYesterdayMsg: 'REVENU HIER',
        revenueThisWeekMsg: 'REVENU CETTE SEMAINE',
        revenueThisMonthMsg: 'REVENU CE MOIS',
        CashInHandMsg: 'DU LIQUIDE EN MAIN',
        CashAtAfrilandMsg: 'CASH À AFRILAND',
        revenuePerModuleMsg: 'REVENU PAR MODULE',
        revenuePerPOSMsg: 'REVENU PAR POS',
        graphsMsg: 'Graphiques',
        revenuePerStationMsg: 'Revenu par Station',
        revenuePerServiceMsg: 'Revenu par Service',
        periodMsg: 'Période',
        revenueMsg: 'Revenu',
        todayMsg: "Aujourd'hui",
        yesterdayMsg: 'Hier',
        thisWeekMsg: 'Cette semaine',
        thisMonthMsg: 'Ce mois-ci',

        //navigation drawer
        homeMsg: 'Accueil',

        accessControlMsg: "Contrôle d'accès",
        rolesMsg: 'Les Rôles',
        usersMsg: 'Utilisateurs',

        administrationMsg: 'Administration',

        financeMsg: 'La finance',
        financeDepartmentsMsg: 'Départements Financiers',
        financeSectionsMsg: 'Sections des finances',
        financeCostCentresMsg: 'Centres de coûts financiers',
        costCentresFeesMsg: 'Frais des centres de coûts',
        ifmisAccountsMsg: 'Comptes IFMIS',
        incomeTypesMsg: 'Type de revenus',
        paymentModesMsg: 'Modes de paiement',

        locationMsg: 'Emplacement',
        subCountiesMsg: 'Center',
        wardsMsg: 'Posts',
        townsMsg: 'Les villes',
        townMsg: 'Ville',
        villagesMsg: 'Villages',

        zonesMsg: 'Zones',
        zoneNamesMsg: 'Noms des zones',
        zoneFeesMsg: 'Frais de zone',
        zoneLocationMsg: 'Emplacement des zones',

        countryMsg: 'Pays',
        countryNamesMsg: 'Noms de pays',
        currencyMsg: 'Monnaie',

        banksMsg: 'Banques',
        bankNamesMsg: 'Noms de banque',
        bankBranchesMsg: 'Agences bancaires',
        bankAccountsMsg: 'Comptes bancaires',

        schedulesMsg: 'Des horaires',
        scheduleNamesMsg: 'Noms des programmes',
        schedulePartsMsg: 'Planifier les pièces',
        scheduleSubPartsMsg: 'Planifier des sous-parties',
        scheduleSubPartFeesMsg: 'Horaire des frais de sous-partie',

        advertisementsMsg: 'Annonces',
        adSizeTypesMsg: 'Types de taille des publicités',
        adCategoriesMsg: 'Catégories de publicités',
        adSubCategoriesMsg: "Sous-catégories de publicités",

        customerManagementMsg: 'Gestion de la clientèle',
        propertiesMsg: 'Propriétés',
        liquorLicenseMsg: "Permis d'alcool",
        businessIntelligenceMsg: "L'intelligence d'entreprise",
        businessPermitsMsg: "Permis d'affaires",
        generalBillingMsg: 'Facturation générale',
        paymentsMsg: 'Paiements',
        periodicBillingMsg: 'Facturation périodique',
        creditNoteWaiverMsg: 'Renonciation à la note de crédit',
        houseRentalMsg: 'Location de maison',
        propertyRatesMsg: 'Tarifs de la propriété',
        reportsMsg: 'Rapports',
        selfHelpGroupMsg: "Groupe d'entraide",
        elearningMsg: 'Elearning',
        supportMsg: 'Soutien',

        //customer management
        customerManagementMsg: 'Gestion de la clientèle',
        newCustomerMsg: 'Nouveau client',
        noCustomerFoundMsg: 'Aucun client trouvé..',
        customerNumberMsg: 'Numéro de client',
        customerNameMsg: 'Nom du client',
        emailAddressMsg: 'Adresse e-mail',
        customerTypeMsg: 'Type de client',
        customerSerialMsg: 'Numéro de série client',
        editCustomerMsg: 'Modifier le client',
        createCustomerMsg: 'Créer un client',
        firstNameMsg: 'Prénom',
        lastNameMsg: 'Nom de famille',
        otherNameMsg: 'Autre(s) nom(s)',
        organisationNameMsg: "Nom de l'organisme",
        postalCodeMsg: 'Code Postal',
        postalAddressMsg: 'Adresse Postale',
        postalTownMsg: 'Ville postale',
        residentialAddressMsg: 'Adresse résidentielle',
        identificationNumberMsg: "Numéro d'identification",
        designationMsg: 'La désignation',
        phoneNumberMsg: 'Numéro de téléphone',
        businessRegistrationNumberMsg: "Numéro d'enregistrement professionel",
        genderMsg: 'Genre',
        dateOfBirthMsg: 'Date de naissance',
        viewCustomerMsg: 'Afficher le client',
        businessNumberMsg: "Numéro d'entreprise",
        primaryPhoneNoMsg: 'Numéro de téléphone principal',
        secondaryPhoneNoMsg: 'Numéro de téléphone secondaire',
        businessMsg: 'Entreprise',
        houseStallMsg: 'Maison/Étalon',
        billsMsg: 'Factures',
        buildingsMsg: 'Immeubles',
        vehiclesMsg: 'Véhicules',
        customerDetailsMsg: 'Détails du client',
        idNumberMsg: "Numéro d'identification",
        customerDetailsUpdatedSuccessfullyMsg: 'Détails du client mis à jour avec succès.',
        customerRegisteredSuccessfullyMsg: 'Client enregistré avec succès.',
        clientDeletedSuccesfullyMsg: 'Client supprimé avec succès',
        areYouSureYouWantToDeleteCustomerMsg: 'Êtes-vous sûr de vouloir supprimer le client?',
        disabilityMsg: 'Invalidité',

        //properties
        noPropertyFoundMsg: 'Aucune propriété trouvée..',
        viewPropertyMsg: 'Voir la propriété',
        addPropertyMsg: 'Ajouter une propriété',
        prnMsg: 'PRN',
        plotNumberMsg: 'Numéro de parcelle',
        blockLRNoMsg: 'Bloquer le numéro LR',
        documentNoMsg: 'Numéro de document',
        minuteNumberMsg: 'Nombre de minutes',
        minuteDateMsg: 'Minutes Date',
        physicalLocationMsg: 'Emplacement physique',
        propertyDocumentTypeMsg: 'Type de document de propriété',
        formNoMsg: 'Numéro du formulaire',
        //subcountyMsg: 'Sous-comté',
        wardMsg: 'Salle',
        zoneMsg: 'Zone',
        zoneLocationMsg: 'Emplacement des zones',
        editPropertyMsg: 'Modifier la propriété',
        addPropertyMsg: 'Ajouter une propriété',
        propertyApplicationSourceMsg: 'Source de demande de propriété',
        propertyAreaUnitMsg: 'Unité de superficie de la propriété',
        valuationMsg: 'Évaluation',
        propertyRoleTypeMsg: 'Type de rôle de propriété',
        propertyLandUseTypeMsg: "Type d'utilisation des terres de la propriété",
        propertyValuationMsg: 'Évaluation des biens',
        propertyValuationMethodMsg: "Méthode d'évaluation de la propriété",
        propertyInterestTypeMsg: "Type d'intérêt de propriété",
        propertyOwnershipMsg: 'Propriété',
        propertyRatingMsg: 'Évaluation de la propriété',
        propertyRentTypeMsg: 'Type de location de propriété',
        propertyCategoryMsg: 'Catégorie de propriété',
        lengthMsg: 'Longueur',
        widthMsg: 'Largeur',
        areaMsg: 'Zone',
        totalAreaMsg: 'Superficie totale',
        siteValueMsg: 'Valeur du site',
        annualRateMsg: 'Taux annuel',
        annualGroundRentMsg: 'Loyer annuel du terrain',
        rateableValueMsg: 'Valeur imposable',
        authorityReferenceMsg: "Référence d'autorité",
        motherPlotPRNMsg: 'Parcelle mère PRN',
        assignPropertyToOwnerMsg: 'Attribuer la propriété aux propriétaires',
        propertyDetailsMsg: 'Détails de la propriété',
        ownerDetailsMsg: 'Détails du propriétaire',
        dateFromMsg: 'Dater de',
        dateToMsg: 'Date à',
        noOwneraddedMsg: 'Aucun propriétaire ajouté..',
        noOwnersFound: 'Aucun propriétaire trouvé..',
        ownersMsg: '(les) propriétaire(s)',
        primaryOwnerMsg: 'Propriétaire principal',
        saveOwnersMsg: 'Enregistrer les propriétaires',
        addOwnersMsg: 'Ajouter un propriétaire',
        propertySubDivisionDetailsMsg: 'Détails de la sous-division de la propriété',
        noSubdivisionFoundMsg: 'Aucune subdivision(s) trouvée(s)..',
        addSubdivisionMsg: 'Ajouter une subdivision',
        propertyDataMsg: 'Données de propriété',
        propertyInformationMsg: 'Informations sur la propriété',
        valuationInformationMsg: "Informations sur l'évaluation",
        blockLRNumberMsg: 'Bloquer le numéro LR',

        //roles
        viewRoleMsg: 'Afficher le rôle',
        addRoleMsg: 'Ajouter un rôle',
        editRoleMsg: 'Modifier le rôle',
        noRoleFoundMsg: 'Aucun rôle trouvé..',
        roleNameMsg: 'Nom de rôle',
        roleGuardMsg: 'Rôle Garde',
        permissionsMsg: 'Autorisations',
        guardNameMsg: 'Nom du gardien',
        selectedMsg: 'Choisi',
        pleasePickAtLeastOnePermissionMsg: 'Veuillez sélectionner au moins une autorisation',
        roleUpdatedSuccessfullyMsg: 'Rôle mis à jour avec succès',
        roleAddedSuccessfullyMsg: 'Rôle ajouté avec succès.',
        roleDeletedSuccesfullyMsg: 'Rôle supprimé avec succès',
        areYouSureYouWantToDeleteRoleMsg: 'Êtes-vous sûr de vouloir supprimer le rôle?',

        //users
        viewUserMsg: "Afficher l'utilisateur",
        addUserMsg: "Ajouter un utilisateur",
        noUserFoundMsg: "Aucun utilisateur trouvé..",
        nameMsg: "Nom",
        dateAddedMsg: "Date ajoutée",
        roleMsg: "Rôle",
        accountActiveMsg: "Compte Actif",
        noneMsg: "Rien",
        editUserMsg: "Modifier l'utilisateur",
        leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg: "Laissez vide pour éviter de mettre à jour involontairement le mot de passe de l'utilisateur",
        userUpdatedSuccessfullyMsg: "Utilisateur mis à jour avec succès.",
        areYouSureYouWantToCreateUserMsg: "Êtes-vous sûr de vouloir créer un utilisateur?",
        userAddedSuccessfullyMsg: "Utilisateur ajouté avec succès.",
        anErrorOccurredEmailAlreadyTakenMsg: "Une erreur s'est produite/Email déjà pris.",
        areYouSureYouWantToDeleteUserMsg: "Êtes-vous sûr de vouloir supprimer l'utilisateur?",
        userDeletedSuccesfullyMsg: "Utilisateur supprimé avec succès",
        areYouSureYouWantToUpdateUserMsg: "Êtes-vous sûr de vouloir mettre à jour l'utilisateur?",

        //profile
        uploadProfilePictureMsg: "Télécharger une photo de profil",
        selectImageMsg: "Sélectionnez une image",
        submitMsg: "Soumettre",
        changeAccountPasswordMsg: "Changer le mot de passe du compte",
        currentPasswordMsg: "Mot de passe actuel",
        updateMsg: "Mettre à jour",
        changeImageMsg: "Changer l'image",
        accountCreationDateMsg: "Date de création du compte",
        accountStatusMsg: "Statut du compte",
        activeMsg: "Actif",
        inactiveMsg: "Inactif",
        changePasswordMsg: "Changer le mot de passe",
        kindlySelectAFileMsg: "Veuillez sélectionner un fichier",
        newPasswordAndConfirmPasswordDoNotMatchMsg: "Le nouveau mot de passe et la confirmation du mot de passe ne correspondent pas",
        areYouSureYouWantToChangeYourAccountPasswordMsg: "Êtes-vous sûr de vouloir changer le mot de passe de votre compte?",
        passwordUpdatedSuccessfullyMsg: "Mot de passe mis à jour avec succès",
        currentPasswordDoesNotMatchWithRecordsMsg: "Le mot de passe actuel ne correspond pas aux enregistrements",
        profilePictureUploadedSuccessfullyMsg: "Photo de profil téléchargée avec succès",

        //general billing
        generalBillingMsg: 'Facturation générale',
        viewBillgMsg: 'Voir la facture',
        createBillMsg: 'Créer une facture',
        editBillMsg: 'Modifier la facture',
        noBillFoundMsg: 'Aucune facture trouvée..',
        billNumberMsg: 'Numéro de facture',
        clientMsg: 'Client',
        billTypeMsg: 'Type de facture',
        //subCountyMsg: 'Sous-comté',
        remarksMsg: 'Remarques',
        billStatusMsg: 'Statut de la facture',
        billDetailsMsg: 'Détails de la facture',
        addBillItemsMsg: 'Ajouter des éléments de facture',
        billItemsMsg: 'Éléments de facture',
        searchBillsMsg: 'Rechercher des factures',
        filterBillsMsg: 'Filtrer les factures',
        feeNameMsg: 'Nom des frais',
        subPartMsg: 'Sous-partie',
        feeDescriptionMsg: 'Description des frais',
        uoMMsg: 'UdeM',
        amountMsg: 'Montant',
        scheduleMsg: 'Calendrier',
        schedulePartsMsg: 'Planifier les pièces',
        scheduleSubPartsMsg: 'Planifier des sous-parties',
        billItemDetailsMsg: "Détails de l'article de facture",
        billItemNameMsg: "Nom de l'élément de facture",
        unitCostMsg: 'Coût unitaire',
        quantityMsg: 'Quantité',
        subtotalCostMsg: 'Coût total partiel',
        itemNameMsg: "Nom de l'article",
        subtotalMsg: 'Total',
        totalPaidMsg: 'Total Payé',
        totalInvoiceGeneratedMsg: 'Facture totale générée',
        totalInvoicePaidMsg: 'Total de la facture payée',
        previousMsg: 'Précédent',
        nextMsg: 'Prochain',
        billDateMsg: 'Date de facturation',
        invoicesMsg: 'Factures',
        noBillItemFoundMsg: 'Aucun élément de facture trouvé..',
        printBillMsg: 'Télécharger la Facture',
        printInvoiceMsg: "Télécharger la facture",
        addBillItemMsg: 'Ajouter un élément de facture',
        noInvoiceFoundMsg: 'Aucune facture trouvée..',
        invoiceNumberMsg: 'Numéro de facture',
        descriptionMsg: 'La description',
        invoiceAmountMsg: 'Montant de la facture',
        generateInvoiceMsg: 'Générer une facture',
        clientNameMsg: 'Nom du client',
        clientNumberMsg: 'Numéro client',
        invoiceDetailsMsg: 'Détails de la facture',
        generateMsg: 'Produire',
        billUpdatedSuccessfullyMsg: 'Facture mise à jour avec succès.',
        billAddedSuccessfullyMsg: 'Facture ajoutée avec succès.',
        invoiceGeneratedSuccessfullyMsg: 'Facture générée avec succès.',
        billPdfGeneratedSuccessfullyMsg: 'Facture pdf générée avec succès.',
        invoicePdfGeneratedSuccessfullyMsg: 'Facture pdf générée avec succès.',
        billDeletedSuccesfullyMsg: 'Facture supprimée avec succès',
        areYouSureYouWantToDeleteBillItemMsg: "Êtes-vous sûr de vouloir supprimer l'élément de facture?",
        billItemDeletedSuccesfullyMsg: 'Élément de facture supprimé avec succès',
        pleaseAddAnItemMsg: 'Veuillez ajouter un article',
        pleaseAddQuantityMsg: 'Veuillez ajouter la quantité',
        billItemAddedTentativelyMsg: 'Élément de facture ajouté provisoirement',
        areYouSureYouWantToDeleteBillMsg: 'Êtes-vous sûr de vouloir supprimer la facture?',
        invoiceAmountEnteredInAdditionToPreviouslyGeneratedInvoicesExceedsBillTotalCostMsg: "Le montant de la facture saisi, en plus des factures générées précédemment, dépasse le coût total de la facture",

        //payments
        viewPaymentMsg: 'Voir le paiement',
        addPaymentMsg: 'Ajouter un paiement',
        editPaymentMsg: 'Modifier le paiement',
        noPaymentFoundMsg: 'Aucun paiement trouvé..',
        refNumberMsg: 'Numéro de référence',
        paymentDateMsg: 'Date de paiement',
        paymentModeMsg: 'Mode de paiement',
        paymentDetailsMsg: 'Détails de paiement',
        refNumberCodeMsg: 'Numéro/Code de référence',
        refDateMsg: 'Date de référence',
        refBankMsg: 'Banque de référence',
        totalBillAmountMsg: 'Montant total de la facture',
        receiptDetailsMsg: 'Détails du reçu',
        invoiceDateMsg: 'Date de la facture',
        bankMsg: 'Banque',
        noUnpaidInvoiceFoundMsg: 'Aucune facture impayée trouvée..',
        receiptRefMsg: 'Référence du reçu',
        printReceiptMsg: 'Imprimer le Reçu',
        areYouSureYouWantToMakeThisPaymentMsg: 'Êtes-vous sûr de vouloir effectuer ce paiement?',
        areYouSureYouWantToDeleteReceiptMsg: 'Êtes-vous sûr de vouloir supprimer le reçu?',
        receiptUpdatedSuccessfullyMsg: 'Reçu mis à jour avec succès.',
        paymentAddedSuccessfullyMsg: 'Paiement ajouté avec succès.',
        receiptDeletedSuccesfullyMsg: 'Reçu supprimé avec succès',

        //dashboard
        revenueManagementSystemMsg: 'Système de gestion des revenus',
        aboutMsg: 'Sur',
        homeP1Msg: "BIRAS est un logiciel robuste et prêt à l'emploi pour les processus gouvernementaux centralisés/décentralisés au niveau de la province, du territoire et du bloc de manière uniforme, englobant les normes mondiales de gouvernance électronique. La solution est un logiciel Web intégré pour tous les départements et divisions des gouvernements provinciaux, qui permet le partage d'informations à travers la province.",
        homeP2Msg: "Le logiciel est conforme à toutes les lois et réglementations, et est également conforme aux niveaux de sécurité des droits internationaux et aux normes de cryptage. Le système est développé dans le respect des normes mondiales de gouvernance électronique et fournit des outils pour éliminer les pratiques peu scrupuleuses et pour introduire la transparence et la responsabilité en fournissant un suivi électronique de la prestation de services aux citoyens et de la collecte des revenus.",
        homeP3Msg: "La solution offre un portail centré sur les citoyens pour promouvoir une gouvernance efficace, accroître la participation des citoyens à la gouvernance et permettre l'accès à tous les services gouvernementaux en ligne.",
        homeP4Msg: "Contrairement aux systèmes de réception des recettes couramment déployés, BIRAS permet aux gouvernements d'augmenter leurs revenus en se concentrant sur la gestion des opérations et la prestation de services, ce qui entraîne une augmentation des revenus. La solution en fait une solution unique.",

        //auth
        poweredByMsg: 'Alimenté par',
        passwordMsg: 'Mot de passe',
        forgotPasswordMsg: 'Mot de passe oublié?',
        sendMeALinkMsg: 'Envoie moi un lien..',
        backToLoginMsg: 'Retour connexion',

        //footer
        quickLinksMsg: 'Liens rapides',
        ourWorkMsg: 'Notre travail',
        contactsMsg: 'Contacts',
        privacyPolicyMsg: 'Politique de confidentialité',
        termsOfUseMsg: "Conditions d'utilisation",
        //house Rental
        houseGenerateInvoiceMsg: 'Générer une facture',
        houseLandlordsMsg: 'Propriétaires de maison',
        houseRegistrationMsg: 'Enregistrement de maison',
        houseTenantsMsg: 'Locataires de la maison',
        houseEstatesMsg: 'Domaines de la maison',
        houseStallTypeMsg: 'Type de décrochage de la maison',
        houseStatusMsg: 'Statut de la maison',
        housepplicationMsg: 'Demande de maison',
        tenantPaymodeMsg: 'Mode de paiement du locataire',
        tenantEmployerMsg: 'Locataire Employeur',
        houseConfigurationMsg: 'Configuration',
        houseApartmentMsg: 'Appartements',
        //register Apartments
        apartmentRegistrationMsg: 'Enregistrement de lappartement',
        newApartmentMsg: 'Nouvel appartement',
        noApartmentMsg: 'Aucun appartement trouvé..',
        apartmentNumberMsg: 'Numéro d appartement',
        estateAreaMsg: 'Domaine du Domaine',
        editApartmentMsg: 'Modifier l appartement',
        apartmentHRNMsg: 'Appartement HRN',
        blockNoMsg: 'Numéro de bloc',
        plotNoMsg: 'Numéro de parcelle',
        estatesMsg: 'Domaine',
        floorsMsg: 'Étages',
        landlordsMsg: 'Propriétaire',
        propertyCodeMsg: 'Code de propriété',
        //house Landlords
        landlordNameMsg: 'Nom du propriétaire',
        tenantTaxMsg: 'Taxe du locataire %',
        tenantBalanceMsg: 'Solde du locataire',
        landlordtaxMsg: 'Taxe foncière %',
        addLandlordeMsg: 'Ajouter un propriétaire',
        newLandlordMsg: 'Nouveau propriétaire',
        houseTypeMsg: 'Type de maison',
        houseNumberMsg: 'Numéro de maison',
        houseFloorMsg: 'Étage de la maison',
        houseStatusMsg: 'Statut de la maison',
        occupiedMsg: 'Occupé',
        unoccupiedMsg: 'Inoccupé',
        tenantNameMsg: 'Nom du locataire',
        submitSelfMsg: 'Soi',
        tenantEmployerMsg: 'Locataire Employeur',
        formNumberMsg: 'Numéro de formulaire',
        monthlyRentMsg: 'Loyer mensuel',
        rentArrearsMsg: 'Arriérés de loyer',
        currentBalanceMsg: 'Solde actuel',
        registerHouseMsg: 'S inscrire Maison',
        taxAmountMsg: 'Montant de la taxe',
        whoSubmitsMsg: 'Qui soumet la taxe locative ?',
        newTenantMsg: 'Nouveau locataire',
        tenantDetailsMsg: 'Détails du locataire',
        landlordDetailsMsg: 'Détails du propriétair',
        newtenantEmployerMsg: 'Nouveau locataire employeur',
        vacateHouseMsg: 'Vider la maison',
        newEstateMsg: 'Nouveau statut',
        newHouseStatusMsg: 'Nouveau statut de maison',
        vacateMsg: 'Quitter',
        longtitudeMsg: 'Longtitude',
        assignApartmentToLordMsg: 'Attribuer un appartement au(x) propriétaire(s)',

        //Finance Department
        noDepartmentFoundMsg: 'Aucun département trouvé',
        viewDepartmentMsg: 'Voir le département',
        addDepartmentMsg: 'Ajouter un département',
        departmentcodeMsg: 'Code départemental',
        departmentnameMsg: 'Nom du département',
        departmentsMsg: 'Département financier',
        editDepartmentMsg: 'Modifier le département',
        departmentupdatedsuccessMsg: "Département mis à jour avec succès",
        departmentaddedsuccessMsg: "Département ajouté avec succès.",
        departmentdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer le département ?",
        departmentdeletesuccessMsg: "Département supprimé avec succès",



        //Finance Sections
        nosectionFoundMsg: 'Aucune section Finance trouvée',
        viewsectionMsg: 'Voir la section',
        addsectionMsg: 'Ajouter une section',
        editsectionMsg: 'Modifier la section',
        sectioncodeMsg: 'Code de la section',
        sectionnameMsg: 'Nom de la section',
        selectdepartmentmsg: 'Sélectionnez le département',
        sectionupdatedsuccessMsg: "Section du département mise à jour avec succès",
        sectionaddedsuccessMsg: "Section de département ajoutée avec succès.",
        sectiondeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la section département ?",
        sectiondeletesuccessMsg: "Section du département supprimée avec succès",


        //Finance Cost Centres
        nocostcentreFoundMsg: 'Aucune Centre de coûts Finance trouvée',
        viewcostcentreMsg: 'Voir la centre de coûts',
        addcostcentreMsg: 'Ajouter une centre de coûts',
        editcostcentreMsg: 'Modifier la Centre de coûts',
        costcentrecodeMsg: 'Code de la Centre de coûts',
        costcentrenameMsg: 'Nom de la Centre de coûts',
        selectsectionmsg: 'Sélectionnez la section',
        selectdepartmentmsg: 'Sélectionnez le département',
        selectcostcentremsg: "Sélectionnez le centre de coûts",
        selectsubpartMsg: "Sélectionnez les frais de sous-partie",
        costcentreupdatedsuccessMsg: "Centre de coûts mis à jour avec succès",
        costcentreaddedsuccessMsg: "Centre de coûts ajouté avec succès.",
        costcentredeletewarningMsg: "Voulez-vous vraiment supprimer le centre de coûts ?",
        costcentredeletesuccessMsg: "Centre de coûts supprimé avec succès",


        //Finance Cost Centres Fees

        nocostcentrefeeFoundMsg: 'Aucun frais de centre de coûts financiers trouvé..',
        viewcostcentrefeeMsg: 'Voir les frais du centre de coûts',
        addcostcentrefeeMsg: 'Ajouter des frais de centre de coûts',
        editcostcentrefeeMsg: 'Modifier les frais de centre de coûts',
        costcentrefeeupdatedsuccessMsg: "Frais de centre de coûts mis à jour avec succès",
        costcentrefeeaddedsuccessMsg: "Frais de centre de coûts ajoutés avec succès.",
        costcentrefeedeletewarningMsg: "Voulez-vous vraiment supprimer les frais de centre de coûts ?",
        costcentrefeedeletesuccessMsg: "Frais de centre de coûts supprimés avec succès",


        //Income Type
        noincometypefoundMsg: 'Aucun type de revenu trouvé',
        viewincometypeMsg: 'Afficher le type de revenu',
        addincometypeMsg: 'Ajouter un type de revenu',
        editincometypeMsg: 'Modifier le type de revenu',
        incometypeMsg: 'Type de revenu',
        incometypeupdatedsuccessMsg: "Type de revenu mis à jour avec succès",
        incometypeaddedsuccessMsg: "Type de revenu ajouté avec succès.",
        incometypedeletewarningMsg: "Voulez-vous vraiment supprimer le type de revenu ?",
        incometypedeletesuccessMsg: "Type de revenu supprimé avec succès",

        //Payment Mode
        nopaymentmodefoundMsg: 'Aucun mode de paiement trouvé',
        viewpaymentmodeMsg: 'Voir le mode de paiement',
        addpaymentmodeMsg: 'Ajouter un mode de paiement',
        editpaymentmodeMsg: 'Modifier le mode de paiement',
        paymentmodeMsg: 'Mode de paiement',
        paymentmodeupdatedsuccessMsg: "Mode de paiement mis à jour avec succès",
        paymentmodeaddedsuccessMsg: "Mode de paiement ajouté avec succès.",
        paymentmodedeletewarningMsg: "Voulez-vous vraiment supprimer le mode de paiement ?",
        paymentmodedeletesuccessMsg: "Mode de paiement supprimé avec succès",




        //Banks
        nobanksfoundMsg: 'Aucune banque trouvée',
        viewbanksMsg: 'Voir la banque',
        addbanksMsg: 'Ajouter le nom de la banque',
        editbanksMsg: 'Modifier le nom de la banque',
        banknameMsg: 'Nom de banque',
        bankupdatedsuccessMsg: "Nom de la banque mis à jour avec succès",
        bankaddedsuccessMsg: "Nom de la banque ajouté avec succès",
        bankdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer le nom de la banque ?",
        bankdeletesuccessMsg: "Nom de la banque supprimé avec succès",


        //Bank Branch
        nobranchsfoundMsg: 'Aucune succursale trouvée ',
        viewbranchsMsg: 'Voir la succursale',
        addbranchsMsg: 'Ajouter une succursale',
        editbranchsMsg: 'Modifier la branche',
        branchnameMsg: 'Nom de la filiale',
        addressMsg: 'Adresse',
        selectbankMsg: 'Sélectionnez la banque',
        branchupdatedsuccessMsg: "Branche mise à jour avec succès",
        branchaddedsuccessMsg: "Branche ajoutée avec succès.",
        branchdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la branche ?",
        branchdeletesuccessMsg: "Branche supprimée avec succès",

        //Bank Account
        noaccountfoundMsg: 'Aucun compte bancaire trouvé',
        viewaccountMsg: 'Voir le compte',
        addaccountMsg: 'Ajouter un compte',
        editaccountMsg: 'Modifier le compte',
        accountnameMsg: 'Nom du compte',
        accountnumberMsg: 'Numéro de compte',
        selectbranchMsg: 'Sélectionnez une succursale',
        bankaccountupdatedsuccessMsg: "Compte bancaire mis à jour avec succès",
        bankaccountaddedsuccessMsg: "Compte bancaire ajouté avec succès.",
        bankaccountdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer le compte bancaire ?",
        bankaccountdeletesuccessMsg: "Compte bancaire supprimé avec succès",


        //Zones
        nozonesfoundMsg: 'Aucune zone trouvée',
        viewzoneMsg: 'Zone de visualisation',
        addzoneMsg: 'Ajouter une zone',
        editzoneMsg: 'Modifier la zone',
        zonenameMsg: 'Nom de la zone',
        zoneupdatedsuccessMsg: "Zone mise à jour avec succès",
        zoneaddedsuccessMsg: "Zone ajoutée avec succès.",
        zonedeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la zone ?",
        zonedeletesuccessMsg: "Zone supprimée avec succès",

        //Zone Fees
        nozonesfeefoundMsg: 'Aucun frais de zones trouvé',
        viewzonefeeMsg: 'Voir les frais de zone',
        addzonefeeMsg: 'Ajouter des frais de zone',
        editzonefeeMsg: 'Modifier les frais de zone',
        zonefeenameMsg: 'Nom des frais',
        amountMsg: 'Montant',
        selectzoneMsg: 'Sélectionner une zone',
        zonefeesupdatedsuccessMsg: "Frais de zone mis à jour avec succès",
        zonefeesaddedsuccessMsg: "Frais de zone ajoutés avec succès.",
        zonefeesdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer les frais de zone ?",
        zonefeesdeletesuccessMsg: "Frais de zone supprimés avec succès",


        //Zone Location
        nozoneslocationfoundMsg: 'Aucun emplacement de zones trouvé',
        viewzonelocationMsg: "Afficher l'emplacement de la zone",
        addzonelocationMsg: 'Ajouter un emplacement de zone',
        editzonelocationMsg: "Modifier l'emplacement de la zone",
        locationnameMsg: 'Nom de la localisation',
        selectzoneMsg: 'Sélectionner une zone',
        zonnelocationupdatedsuccessMsg: "Emplacement de la zone mis à jour avec succès",
        zonnelocationaddedsuccessMsg: "Emplacement de la zone ajouté avec succès.",
        zonnelocationdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer l'emplacement de la zone ?",
        zonnelocationdeletesuccessMsg: "Emplacement de la zone supprimé avec succès",


        //Countries
        nocountriesfoundMsg: 'Aucun pays trouvé',
        viewcountryMsg: 'Voir le pays',
        addcountryMsg: 'Ajouter un pays',
        editcountryMsg: 'Modifier le pays',
        countrynameMsg: 'Nom du pays',
        countrycodeMsg: 'Code postal',
        nationalityMsg: 'Nationalité',
        countryupdatedsuccessMsg: "Pays mis à jour avec succès",
        countryaddedsuccessMsg: "Pays ajouté avec succès.",
        countrydeletewarningMsg: "Voulez-vous vraiment supprimer le pays ?",
        countrydeletesuccessMsg: "Pays supprimé avec succès",


        //Currencies
        nocurrenciesfoundMsg: 'Aucun pays trouvé',
        viewcurrencyMsg: 'Afficher la devise',
        addcurrencyMsg: 'Ajouter une devise',
        editcurrencyMsg: 'Modifier la devise',
        currencynameMsg: 'Nom de la devise',
        currencycodeMsg: 'Code de devise',
        selectcountryMsg: 'Choisissez le pays',
        currencyupdatedsuccessMsg: "Devise mise à jour avec succès",
        currencyaddedsuccessMsg: "Devise ajoutée avec succès.",
        currencydeletewarningMsg: "Voulez-vous vraiment supprimer la devise ?",
        currencydeletesuccessMsg: "Devise supprimée avec succès",



        //Waivers
        nowaiversfoundMsg: 'Aucune dérogation trouvée',
        viewwaiverMsg: 'Voir la renonciation',
        addwaiverMsg: 'Ajouter une renonciation',
        editwaiverMsg: 'Modifier la renonciation',
        financialyearMsg: 'Année financière',
        subcountyMsg: 'Center',
        startdateMsg: 'Date de début',
        enddateMsg: 'Date de fin',
        waiverpercentageMsg: 'Pourcentage de renonciation',
        scopevalueMsg: 'Valeur de la portée',
        statusMsg: 'Statut',
        waiverupdatedsuccessMsg: "Renonciation mise à jour avec succès",
        waiveraddedsuccessMsg: "Renonciation ajoutée avec succès.",
        waiverdeletewarningMsg: "Voulez-vous vraiment supprimer la renonciation ?",
        waiverdeletesuccessMsg: "Renonciation supprimée avec succès",

        //Schedules
        noschedulesfoundMsg: 'Aucun horaire trouvé',
        viewscheduleMsg: "Voir l'horaire",
        addscheduleMsg: 'Ajouter un horaire',
        editscheduleMsg: "Modifier l'horaire",
        schedulenameMsg: "Nom de l'horaire",
        scheduleupdatedsuccessMsg: "l'horaire mise à jour avec succès",
        scheduleaddedsuccessMsg: "l'horaire ajoutée avec succès",
        scheduledeletewarningMsg: "Voulez-vous vraiment supprimer le l'horaire?",
        scheduledeletesuccessMsg: "l'horaire supprimée avec succès",


        //Schedules Parts
        noschedulespartsfoundMsg: "Aucune pièce d'horaires trouvée",
        viewschedulepartMsg: 'Afficher la pièce de nomenclature',
        addschedulepartMsg: 'Ajouter une partie de nomenclature',
        editschedulepartMsg: "Modifier la partie de la nomenclature",
        schedulepartnameMsg: 'Nom de la pièce',
        selectscheduleMsg: "Sélectionnez l'horaire",
        schedulepartupdatedsuccessMsg: "Pièce de planification mise à jour avec succès",
        schedulepartaddedsuccessMsg: "Pièce de planification ajoutée avec succès.",
        schedulepartdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer des parties de l'horaire ?",
        schedulepartdeletesuccessMsg: "Pièce de planification supprimée avec succès",



        //Schedules Sub Parts
        noschedulessubpartsfoundMsg: "Aucune sous-partie d'horaires trouvée",
        viewschedulesubpartMsg: 'Afficher le sous-partie du programme',
        addschedulesubpartMsg: 'Ajouter une sous-partie de nomenclature',
        editschedulesubpartMsg: 'Modifier la sous-partie de la nomenclature',
        subpartnameMsg: 'Nom de la sous-partie',
        selectscheduleparts: 'Sélectionnez la partie de la nomenclature',
        posfee: "Point de vente Frais",
        posservicecategory: "Point de vente Catégorie de service",
        posservicesubcategory: "Point de vente Sous-catégorie de service",
        schedulesubpartupdatedsuccessMsg: "Planifier la sous-partie mise à jour avec succès",
        schedulesubpartaddedsuccessMsg: "Sous-partie de planification ajoutée avec succès.",
        schedulesubpartdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la sous-partie de l'horaire ?",
        schedulesubpartdeletesuccessMsg: "Sous-partie de planification supprimée avec succès",

        //Schedules Sub Parts Fees
        noschedulessubpartsfeefoundMsg: "Aucun frais de sous-pièces d'horaires trouvés",
        viewschedulesubpartfeeMsg: "Voir le barème des frais de sous-partie",
        addschedulesubpartfeeMsg: "Ajouter des frais de sous-partie d'annexe",
        editschedulesubpartfeeMsg: "Modifier les frais de sous-partie de l'horaire",
        subpartfeenameMsg: 'Nom des frais',
        subpartfeedescriptionMsg: 'Description des frais',
        subpartfeeamountMsg: 'Montant',
        posServiceCategoryMsg: 'POS Catégorie de service',
        posServiceSubCategoryMsg: 'POS Sous-catégorie de service',
        unitofmeasureMsg: 'Unité de mesure',
        selectscheduleparts: 'Sélectionnez la sous-partie du programme',
        schedulesubpartfeesupdatedsuccessMsg: "Frais de sous-partie mis à jour avec succès",
        schedulesubpartfeesaddedsuccessMsg: "Frais de sous-partie ajoutés avec succès",
        schedulesubpartfeesdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer les frais des sous-parties du programme ?",
        schedulesubpartfeesdeletesuccessMsg: "Frais de sous-partie supprimée avec succès",

        //Subcounty
        nosubcountiesfoundMsg: "Aucun centre n'a été trouvé",
        viewsubcountyMsg: 'Centre de vue',
        addsubcountyMsg: 'Ajouter un centre',
        editsubcountyMsg: "Centre d'édition",
        subcountynameMsg: 'Nom du centre',
        subcountyupdatedsuccessMsg: "Centre mise à jour avec succès",
        subcountyaddedsuccessMsg: "Centre ajoutée avec succès",
        subcountydeletewarningMsg: "Voulez-vous vraiment supprimer la Centre?",
        subcountydeletesuccessMsg: "Centre supprimée avec succès",



        //Wards
        nowardsfoundMsg: "Aucune post trouvée",
        viewWardMsg: "Voir Post",
        addwardMsg: "Ajouter Post",
        editwardMsg: "Modifier Post",
        wardnameMsg: "Nom du Post",
        selectsubcountyMsg: "Sélectionnez Centre",
        wardupdatedsuccessMsg: "Post mise à jour avec succès",
        wardaddedsuccessMsg: "Post ajoutée avec succès",
        warddeletewarningMsg: "Voulez-vous vraiment supprimer la Post?",
        warddeletesuccessMsg: "Post supprimée avec succès",


        //Towns
        notownsfoundMsg: "Aucune Antennes trouvée",
        viewtownsMsg: "Voir Antennes",
        addtownMsg: "Ajouter Antennes",
        edittownMsg: "Modifier Antennes",
        townnameMsg: "Nom du Antennes",
        selectwardMsg: "Sélectionnez Post",
        townsupdatedsuccessMsg: "Antennes mise à jour avec succès",
        townsaddedsuccessMsg: "Antennes ajoutée avec succès",
        townsdeletewarningMsg: "Voulez-vous vraiment supprimer la Antennes?",
        townsdeletesuccessMsg: "Antennes supprimée avec succès",


        //Villages
        novillagesfoundMsg: "Aucune villages trouvée",
        viewvillagesMsg: "Voir Village",
        addvillagesMsg: "Ajouter Village",
        editvillagesMsg: "Modifier Village",
        villagenameMsg: "Nom du Village",
        villageupdatedsuccessMsg: "Village mis à jour avec succès",
        villageaddedsuccessMsg: "Village ajouté avec succès.",
        villagedeletewarningMsg: "Êtes-vous sûr de vouloir supprimer le village ?",
        villagedeletesuccessMsg: "Village supprimé avec succès",



        //Advertisements
        noadvertisementfoundMsg: "Aucune annonce trouvée",
        viewadvertisementMsg: "Voir la publicité",
        addadvertisementMsg: "Ajouter une publicité",
        editadvertisementMsg: "Modifier la publicité",
        advertisementnameMsg: "Nom de l'annonce",
        longitudeMsg: "Longitude",
        latitudeMsg: "Latitude",
        advertisementsizetypeMsg: "Type de taille de publicité",
        advertisementSize: "Taille de l'annonce",
        advertisementdescriptionMsg: 'Description de la publicité',
        advertisementtypeMsg: 'Type de publicité',
        advertisementSubCategoryMsg: 'Sous-catégorie de publicité',
        physicalLocation: 'Emplacement physique',
        recurrencyMsg: "Récurrence",
        advertisementupdatedsuccessMsg: "Annonce mise à jour avec succès",
        advertisementaddedsuccessMsg: "Publicité ajoutée avec succès.",
        advertisementdeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la publicité ?",
        advertisementdeletesuccessMsg: "Annonce supprimée avec succès",
        assignAdvertismentToOwnerMsg: "Attribuer l'annonce au propriétaire",
        selectTownMsg: "Sélectionnez la ville",
        selectSizeTypeMsg: "Sélectionnez le type de taille",
        selectCategoryMsg: "Sélectionnez Catégorie",
        selectRecurrencyMsg: "Sélectionnez la récurrence",
        validuntilMsg: "Valable jusque",
        parentadMsg: "Publicité pour les parents",

        //Advertisements Size
        noadvertisementsizefoundMsg: "Aucune taille d'annonces trouvée",
        viewadvertisementsizeMsg: "Afficher la taille de l'annonce",
        addadvertisementsizeMsg: "Ajouter la taille de l'annonce",
        editadvertisementsizeMsg: "Modifier la taille de l'annonce",
        advertisementSize: "Taille de l'annonce",
        advertisementsizeupdatedsuccessMsg: "Taille de l'annonce mise à jour avec succès",
        advertisementsizeaddedsuccessMsg: "Taille de l'annonce ajoutée avec succès.",
        advertisementsizedeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la taille de l'annonce ?",
        advertisementsizedeletesuccessMsg: "Taille de l'annonce supprimée avec succès",

        //Advertisment Category
        noadvertisementcategoryfoundMsg: "Aucune catégorie d'annonces trouvée",
        viewadvertisementcategoryMsg: "Voir la catégorie de publicite",
        addadvertisementcategoryMsg: "Ajouter une catégorie de publicité",
        editadvertisementcategoryMsg: "Modifier la catégorie de publicité",
        advertisementCategoryMsg: 'Catégorie de publicité',
        advertcategorynameMsg: "Nom de catégorie",
        advertisementcategoryupdatedsuccessMsg: "Catégorie de publicité mise à jour avec succès",
        advertisementcategoryaddedsuccessMsg: "Catégorie de publicité ajoutée avec succès.",
        advertisementcategorydeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la catégorie d'annonces ?",
        advertisementcategorydeletesuccessMsg: "Catégorie de publicité supprimée avec succès",



        //Advertisements Sub Category
        noadvertisementsubcategoryfoundMsg: "Aucune sous-catégorie d'annonces trouvée",
        viewadvertisementsubcategoryMsg: "Voir la sous-catégorie de publicité",
        addadvertisementsubcategoryMsg: "Ajouter une sous-catégorie de publicité",
        editadvertisementsubcategoryMsg: "Modifier la sous-catégorie de publicité",
        advertisementSubCategoryMsg: 'Sous-catégorie de publicité',
        advertsubcategorynameMsg: "advertisementSubCategoryMsg ",
        advertisementsubcategoryupdatedsuccessMsg: "Sous-catégorie de publicité mise à jour avec succès",
        advertisementsubcategoryaddedsuccessMsg: "Sous-catégorie de publicité ajoutée avec succès.",
        advertisementsubcategorydeletewarningMsg: "Êtes-vous sûr de vouloir supprimer la sous-catégorie d'annonces ?",
        advertisementsubcategorydeletesuccessMsg: "Sous-catégorie de publicité supprimée avec succès",



        //POS
        posManagementMsg: "Gestion des Points de Vente",
        posUsersMsg: "Utilisateurs",
        posTransactionsMsg: "Transactions",
        posDevicesMsg: "Appareils",
        posUserSessionsMsg: "Sessions Utilisateur",
        posTransactionTypesMsg: "Types de Transactions",
        posAgentsMsg: "Agents",
        posAgentAccountsMsg: "Comptes d'agents",
        posBusinessesMsg: "Entreprises",
        posBusinessAccountsMsg: "Comptes d'entreprise",
        posMerchantsMsg: "Marchands",

        //POS Users
        viewPosUsersMsg: "Afficher les utilisateurs",
        addPosUserMsg: "Ajouter un utilisateur",
        editPosUserMsg: "Modifier l'utilisateur",
        noPOSUserFoundMsg: "Aucun utilisateur trouvé",
        posUserLastNameMsg: "Nom de famille",
        posUserFirstNameMsg: "Prénom",
        posUserOtherNamesMsg: "Autres noms",
        posUserFullNameMsg: "Nom complet",
        posUserEmailMsg: "Adresse e-mail",
        posUserPhoneMsg: "Numéro de téléphone",
        posUserBusinessMsg: "Entreprise",
        posUserCountryMsg: "Pays",
        posUserIDNoMsg: "Numéro d'identification",
        posUserPINNoMsg: "Code PIN",
        posUserMidagaSyncStatusMsg: "Statut de la synchronisation Midaga",
        posUserMidagaIdMsg: "Code sur Midaga",
        posUserDeleteWarningMsg: "Êtes-vous sûr de vouloir supprimer l'utilisateur du point de vente?",
        posUserUpdatedSuccessMsg: "POS User updated successfully",
        posUserAddedSuccessMsg: "Utilisateur du point de vente ajouté avec succès.",
        posUserDeleteSuccessMsg: "Utilisateur du point de vente supprimé avec succès",
        posUserSyncedMsg: "Synchronisé",
        posUserUnsyncedMsg: "Non synchronisé",

















    }




};

const i18n = new VueI18n({
    locale: 'fr', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n
