export const GET_CUSTOMER_TYPES = "GET_CUSTOMER_TYPES";
export const GET_OTP_DATA = "GET_OTP_DATA";
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_BUSINESSES = "GET_BUSINESSES";
export const GET_BILLS = "GET_BILLS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_SUPPORTING_FORM_DATA_REQUEST_STATUS =
  "GET_SUPPORTING_FORM_DATA_REQUEST_STATUS";
export const GET_SHOW_USER_DATA = "GET_SHOW_USER_DATA";
