import { requestStatus } from '@/utils/constants'
import { FETCH_COUNTY_DETAILS } from '../actions/county'
import { SET_COUNTY_DETAILS_REQUEST_STATUS, SET_COUNTY_DETAILS } from '../mutations/county'
import { GET_COUNTY_DETAILS, GET_FETCH_COUNTY_DETAILS_REQUEST_STATUS } from '../getters/county'
import apiCall from '@/utils/api'

export default {
    state: {
        countyDetails: null,
        fetchCountyDetailsRequestStatus: requestStatus.NOT_SENT
    },
    getters: {
        [GET_COUNTY_DETAILS]: (state) => {
            const countyDetails = state.countyDetails ?? null
            const countyDetailsNotEmpty = !!countyDetails
            if (countyDetailsNotEmpty) {
                const logoUrl = `${process.env.VUE_APP_API_URL}${countyDetails.logo}`
                return {
                    ...countyDetails,
                    logo: logoUrl
                }
            }
            return countyDetails
        },
        [GET_FETCH_COUNTY_DETAILS_REQUEST_STATUS]: (state) => state.fetchCountyDetailsRequestStatus
    },
    actions: {
        [FETCH_COUNTY_DETAILS]: async ({ commit }) => {
            try {
                commit(SET_COUNTY_DETAILS_REQUEST_STATUS, requestStatus.SENDING)
                const response = await apiCall({
                    url: '/api/client/county',
                    method: 'GET'
                })
                commit(SET_COUNTY_DETAILS, response)
                commit(SET_COUNTY_DETAILS_REQUEST_STATUS, requestStatus.COMPLETE)
            } catch (error) {
                commit(SET_COUNTY_DETAILS_REQUEST_STATUS, requestStatus.ERROR)
            }
        }
    },
    mutations: {
        [SET_COUNTY_DETAILS_REQUEST_STATUS]: (state, requestStatus) => {
            state.fetchCountyDetailsRequestStatus = requestStatus
        },
        [SET_COUNTY_DETAILS]: (state, countyDetails) => {
            state.countyDetails = countyDetails
        }
    }
}