import apiCall from '@/utils/api'

const state = {
	properties: [],
    propertyPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    propertyLoader: false
};

const getters = {
    propertyLoader: (state) => state.propertyLoader,
	properties: (state) => state.properties,
    propertyPagination: (state) => state.propertyPagination
}

const actions = {
    async fetchProperties({commit}, page) {
		const response = await apiCall({url: `/api/client/property?page=${page}`, method: 'GET' });
		commit('setProperties', response)
        commit('stopLoader', response)
	},
    async filterProperties({commit, state},resp){
		commit('setProperties', resp)
	},
	async startPropertyLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPropertyLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setProperties: (state, properties) => {
		state.properties = properties.data
		state.propertyPagination.current_page = properties.current_page
		state.propertyPagination.total = properties.total
		state.propertyPagination.per_page = properties.per_page
	},
    startLoader: (state) => state.propertyLoader = true,
	stopLoader: (state) => state.propertyLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
