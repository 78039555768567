<template>
  <v-app :style="{ maxWidth: '2000px', margin: '0 auto' }">
    <HomeNavbar
      :drawerOpen.sync="drawerOpen"
      :visitorCount="visitorData.data.value?.count ?? 0"
      :visitorCountLoading="visitorData.isFetching.value"
    />

    <NavDrawer :drawerOpen.sync="drawerOpen" />

    <v-main class="">
      <router-view></router-view>
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/layout/navbar";
import HomeNavbar from "@/components/layout/HomeNavbar";
import NavDrawer from "@/components/layout/NavDrawer.vue";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import Snackbar from "@/components/utils/snackbar/Snackbar.vue";
import { USER_ACCOUNT_FORM_DATA } from "@/store/actions/user";
import { FETCH_COUNTY_DETAILS } from "@/store/actions/county";
import { useGetVisitorData } from "./utils/vue_helpers";
import { addNotAuthenticatedHandler } from "./utils/functions";

export default {
  name: "App",

  components: {
    Navbar,
    HomeNavbar,
    Snackbar,
    NavDrawer,
  },
  created() {
    addNotAuthenticatedHandler(this.$router);
  },
  beforeMount() {
    // setSanctumTokenForTesting()
    i18n.locale = this.language;
    if (this.darkState == true) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  async mounted() {
    try {
      this.$store.dispatch(USER_ACCOUNT_FORM_DATA);
      this.$store.dispatch(FETCH_COUNTY_DETAILS);
    } catch (error) {
      console.log(error);
    }
  },
  data: () => ({
    drawerOpen: false,
  }),
  computed: {
    ...mapGetters(["darkState", "language"]),
  },
  setup() {
    const visitorData = useGetVisitorData();

    return {
      visitorData,
    };
  },
};
</script>

<style lang="scss">
$transparent-background-color: transparent;

.primary-secondary-gradient {
  background: linear-gradient(
    145deg,
    var(--v-primary-base) 0,
    var(--v-primary-lighten2) 50%,
    var(--v-secondary-lighten2) 100%
  );
}

.primary-gradient-1 {
  background: linear-gradient(
    90deg,
    var(--v-primary-base) 0,
    var(--v-primary-lighten2) 100%
  );
}

.button-background-secondary-1 {
  background: linear-gradient(
    90deg,
    var(--v-secondary-base) 0,
    var(--v-secondary-lighten1) 100%
  );
}

.text-field-background .v-input__slot {
  background-color: var(--v-background-base) !important;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.slide-right-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-right-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-left-enter {
  transform: translateX(20px);
  opacity: 0;
}

.slide-left-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.text-field {
  height: 40px;
}

.skeleton-fill-height > .v-skeleton-loader__bone {
  height: 100%;
}

.skeleton-fill-width > .v-skeleton-loader__bone {
  width: 100%;
}

.table-header-text {
  color: rgba(0, 0, 0, 0.6);
}

.transparent-background {
  background-color: transparent !important;
}

.theme--light {
  &.v-tabs-items {
    background-color: $transparent-background-color !important;
  }

  &.v-data-table {
    background-color: $transparent-background-color !important;
  }

  &.v-stepper {
    background-color: $transparent-background-color !important;
  }
}

.reduced-opacity-background {
  background-color: rgba(255, 255, 255, 0.85) !important;
}

.table-container {
  &.no-bottom-border td {
    border-bottom: none !important;
  }
}

@media (max-width: 600px) {
  #userwayAccessibilityIcon {
    display: none;
  }
}
</style>
