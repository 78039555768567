<script setup>
import { routes } from "@/router/routes";
import { useRouterFromInstance } from "@/utils/vue_helpers";

const { router } = useRouterFromInstance();

const links = [
  {
    text: "FAQs",
    icon: "mdi-chat-question",
    action() {
      router.push(routes.faq);
    },
  },
];
</script>
<template>
  <v-menu offset-y bottom :open-on-hover="!$mobileBreakpoint" nudge-bottom="">
    <template #activator="{ on, attrs }">
      <v-btn
        text
        class="font-weight-bold primary--text text-uppercase"
        v-on="on"
        v-bind="attrs"
        >Help</v-btn
      >
    </template>
    <v-list dense>
      <v-list-item v-for="link in links" class="px-4" @click="link.action">
        <v-list-item-icon class="mr-2">
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <div>{{ link.text }}</div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
