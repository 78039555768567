<script setup>
import { userDataLoading } from "@/utils/vue_helpers.js";
import SkeletonLoader from "@/components/utils/SkeletonLoader.vue";
import router from "@/router/index";
import store from "@/store/index";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import {
  snackbarData,
  resetSnackbarOnMount,
} from "@/components/utils/snackbar/logic";
import { routes } from "@/router/routes";

resetSnackbarOnMount();

const avatarSize = "32px";

const links = [
  {
    text: "Account",
    icon: "mdi-card-account-details",
    action() {
      router.push(routes.userAccountDetails);
    },
  },
  {
    text: "Profile",
    icon: "mdi-account",
    action() {
      router.push(routes.userProfileView);
    },
  },
  {
    text: "Logout",
    icon: "mdi-lock",
    async action() {
      router.push(routes.logout);
    },
  },
];

async function logUserOut() {
  try {
    await store.commit(AUTH_LOGOUT);
    router.push({
      name: "Login",
    });
  } catch (error) {
    snackbarData.text = "Error logging out";
    snackbarData.color = "red";
    snackbarData.open = true;
  }
}
</script>
<template>
  <v-menu offset-y bottom :open-on-hover="!$mobileBreakpoint" nudge-bottom="">
    <template v-slot:activator="{ on, attrs }">
      <SkeletonLoader
        :loading="userDataLoading"
        type="image"
        :width="avatarSize"
        :height="avatarSize"
      >
        <v-btn class="" text v-on="on" v-bind="attrs">
          <div class="d-flex align-center">
            <v-img
              :width="avatarSize"
              cover
              :src="require('@/assets/images/default_pic.png')"
              :style="{
                clipPath: `circle(calc(${avatarSize} / 2))`,
              }"
            />
          </div>
        </v-btn>
      </SkeletonLoader>
    </template>
    <v-list>
      <v-list-item v-for="link in links" class="px-4" @click="link.action">
        <v-list-item-icon class="mr-4">
          <v-icon small>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <div>{{ link.text }}</div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
// .dropdown-link {
//     &:hover {
//         background-color: var(--v-background-base);
//     }
// }
</style>
