<script setup>
import { computed } from "vue";
import CountyLogoAndTitle from "./CountyLogoAndTitle.vue";
import Services from "./Services.vue";
import { userAuthenticated, useRouterFromInstance } from "@/utils/vue_helpers";
import { getDashboardLinks } from "@/router/dashboard_links";

const props = defineProps({
  drawerOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:drawerOpen"]);

const innerDrawerOpen = computed({
  get() {
    return props.drawerOpen;
  },
  set(newVal) {
    emit("update:drawerOpen", newVal);
  },
});

const { router, route: currentRoute } = useRouterFromInstance();

function onRouteClicked(route) {
  innerDrawerOpen.value = false;
  router.push(route);
}
</script>

<template>
  <v-navigation-drawer
    v-if="$mobileBreakpoint"
    fixed
    app
    v-model="innerDrawerOpen"
    :width="$mobileBreakpoint ? '100vw' : '264px'"
    :style="{ zIndex: 11 }"
    :clipped="!$mobileBreakpoint && userAuthenticated"
  >
    <template v-if="!userAuthenticated || $mobileBreakpoint">
      <v-list-item>
        <v-list-item-title class="d-flex justify-end">
          <v-btn icon @click="innerDrawerOpen = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <CountyLogoAndTitle inDrawer />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list v-if="userAuthenticated" dense nav>
        <v-list-item-group :value="currentRoute.name">
          <v-list-item
            v-for="(dashboardLink, index) in getDashboardLinks()"
            :key="index"
            :value="dashboardLink.route.name"
            @click="onRouteClicked(dashboardLink.route)"
          >
            <v-list-item-icon>
              <v-icon>{{ dashboardLink.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ dashboardLink.text }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list dense>
        <v-list-item-group v-if="userAuthenticated">
          <v-list-item>
            <v-list-item-title> General Billing </v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <Services v-if="$mobileBreakpoint" />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
