import { routes } from "@/router/routes"
import { services } from "@/utils/constants"

export const links = {
    'environment': {
        title: 'Environment',
        links: [
            {
                text: 'Solid Waste Collection',
                route: routes.defaultService.withQuery({
                    serviceName: services.solidWaste,
                    title: 'Solid Waste Collection',
                    serviceCategory: 'environment'
                })
            },
            {
                text: 'Noise Pollution Services',
                route: routes.defaultService.withQuery({
                    serviceName: services.noisePollution,
                    title: 'Noise Pollution Services',
                    serviceCategory: 'environment'
                })
            },
        ]
    },
    'county-services': {
        title: 'County Services',
        links: [
            {
                text: 'General Services',
                route: routes.defaultService.withQuery({
                    serviceName: services.generalServices,
                    title: 'General Services',
                    serviceCategory: 'county-services'
                })
            },
            {
                text: 'Documentary Services',
                route: routes.defaultService.withQuery({
                    serviceName: services.documentaryServices,
                    title: 'Documentary Services',
                    serviceCategory: 'county-services'
                })
            },
            {
                text: 'County Hiring Services',
                route: routes.defaultService.withQuery({
                    serviceName: services.countyHiringServices,
                    title: 'County Hiring Services',
                    serviceCategory: 'county-services'
                })
            },
        ]
    },
    'land': {
        title: 'Land',
        links: [
            {
                text: 'Physical Planning',
                route: routes.defaultService.withQuery({
                    serviceName: services.physicalPlanning,
                    title: 'Physical Planning',
                    serviceCategory: 'land'
                })
            },
        ]
    },
    'safety': {
        title: 'Safety',
        links: [
            {
                text: 'Fire Safety Services',
                route: routes.defaultService.withQuery({
                    serviceName: services.fireSafety,
                    title: 'Fire Safety Services',
                    serviceCategory: 'safety'
                })
            },
        ]
    }
}