import { routes } from "@/router/routes";
import {
  INVALID_OTP,
  errors,
  requestStatus,
  filterTypes,
} from "@/utils/constants";
import axios from "axios";
import crypto from "crypto";
import numeral from "numeral";
import { getPhoneNumberWithCallingCode } from "./phone_number";

export function stringNotEmpty(text) {
  return text !== null && text !== undefined && text.length > 0;
}

export function arrayNotEmpty(arr) {
  return arr !== null && arr !== undefined && arr.length > 0;
}

export function objectNotEmpty(obj) {
  return !!obj && Object.keys(obj).length > 0;
}

export function isOtpInvalid({ errorCode, errorText }) {
  // const statusCodeIs401 = errorCode === 401
  const errorTextMatchesInvalidOtp = errorText === INVALID_OTP;
  const otpIsInvalid = /*statusCodeIs401 &&*/ errorTextMatchesInvalidOtp;
  console.log(errorText);
  if (otpIsInvalid) {
    return true;
  }
  return false;
}

export function getHashedPassword(password) {
  const hashedPassword = crypto
    .createHash("sha256")
    .update(password)
    .digest("hex");
  return hashedPassword;
}

export function parseUserProfile(userProfileFromServer) {
  const parsedProfile = {
    idNumber: userProfileFromServer.profile.id_number,
    customerName: userProfileFromServer.profile.customer_name,
    phoneNumber1: userProfileFromServer.profile.phone_number1,
    phoneNumber2: userProfileFromServer.profile.phone_number2,
    emailAddress: userProfileFromServer.profile.email_address,
    genderId: userProfileFromServer.profile.gender_id,
    postalTown: userProfileFromServer.profile.postal_town,
    postalCode: userProfileFromServer.profile.postal_code,
    postalAddress: userProfileFromServer.profile.postal_address,
    dateOfBirth: userProfileFromServer.profile.date_of_birth,
    disability: userProfileFromServer.profile.disability,
    customerTypeId: userProfileFromServer.profile.customer_type_id,
    ncplwdNumber: userProfileFromServer.profile.ncplwd_no,
    ncplwdNumberIssuanceDate: userProfileFromServer.profile.date_of_issuance,
  };
  return parsedProfile;
}

export function getFormattedMoney(amount) {
  return numeral(amount).format("0,0.00");
}

export function setSanctumTokenForTesting() {
  const token = "1|rY1Bq8HZBGW9PTouMK5rejN6Iyi0k8vy8L89VQmO";
  localStorage.setItem("user-token", "Bearer " + token);
}

export function getFormattedBillDate(
  date,
  includeSeconds = false,
  includeTime = true
) {
  const jsDate = new Date(date);
  const day = jsDate.getDate();
  const month = jsDate.getMonth() + 1;
  const year = jsDate.getFullYear();
  const hours = jsDate.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  const ampmHours = hours === 12 ? 12 : hours % 12;
  const minutes = jsDate.getMinutes();
  const zeroPaddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  let formattedTime = null;
  if (includeSeconds) {
    const seconds = jsDate.getSeconds();
    const zeroPaddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    formattedTime = `${day}/${month}/${year} - ${ampmHours}:${zeroPaddedMinutes}:${zeroPaddedSeconds} ${ampm}`;
  } else if (includeTime) {
    formattedTime = `${day}/${month}/${year} - ${ampmHours}:${zeroPaddedMinutes} ${ampm}`;
  } else {
    formattedTime = `${day}/${month}/${year}`;
  }
  return formattedTime;
}

export function getFormattedReceiptDate(date) {
  const jsDate = new Date(date);
  const day = jsDate.getDate();
  const month = jsDate.getMonth() + 1;
  const year = jsDate.getFullYear();
  const hours = jsDate.getHours();
  const seconds = jsDate.getSeconds();
  const ampm = hours >= 12 ? "pm" : "am";
  const ampmHours = hours === 12 ? 12 : hours % 12;
  const minutes = jsDate.getMinutes();
  const zeroPaddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${day}/${month}/${year} - ${ampmHours}:${zeroPaddedMinutes} ${ampm}`;
  return formattedTime;
}

export function getBillAmount(bill) {
  let billAmount = 0;
  for (const billItem of bill.bill_items) {
    billAmount += Number(billItem.sub_total);
  }
  return billAmount;
}

export function debounce(func, wait = 300) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export function capitalize(text) {
  return text.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
}

export function getCurrentBusinessPermit(business) {
  const permits = business.business_permits;
  const lastPermitIndex = permits.length - 1;
  const currentBusinessPermit = permits[lastPermitIndex];
  return currentBusinessPermit;
}
export function getCurrentVehicles(vehicle) {
  const lastVehicleIndex = vehicle.length - 1;
  const currentVehicle = vehicle[lastVehicleIndex];
  return currentVehicle;
}
export function valueNotEmptyAndNotZero(value) {
  return value !== null && value !== undefined && Number(value) !== 0;
}

export function permitValueNotFalsy(value) {
  return valueNotEmptyAndNotZero(value) && Number(value) !== 2;
}

export function lastInList(index, list) {
  return index === list.length - 1;
}

export function getReceiptFromBill(bill) {
  return bill?.bills_invoices[0]?.invoice?.invoice_receipts[0]?.receipt;
}

export function getReceiptsFromBills(bills) {
  const receipts = [];
  bills?.forEach((bill) => {
    const receiptFromBill = getReceiptFromBill(bill);
    const receiptFromBillNotEmpty = !!receiptFromBill;
    if (receiptFromBillNotEmpty) {
      receipts.push(receiptFromBill);
    }
  });
  return receipts;
}

export function getPaymode(mode, paymodes) {
  return paymodes.find((paymode) => paymode.pay_mode === mode);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getJsDateAsYMD(jsDate) {
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();
  const zeroPaddedMonth = month < 10 ? `0${month}` : month;
  const zeroPaddedDay = day < 10 ? `0${day}` : day;
  return `${year}-${zeroPaddedMonth}-${zeroPaddedDay}`;
}

export function convertYearsToMs(years) {
  return years * 31556952000;
}

export function getPermitForBusiness(business) {
  return (
    business?.business_permits?.[business.business_permits.length - 1] ?? null
  );
}

export function getPublicHealthCertForPermit(permit) {
  return (
    permit?.public_health_certs[permit.public_health_certs.length - 1] ?? null
  );
}

export function getLiquorLicenseForBusiness(business) {
  return (
    business?.liquor_licences?.[business?.liquor_licences?.length - 1] ?? null
  );
}

export function scrollToElement(element) {
  element.scrollIntoView({ block: "center" });
}

export function checkKiriBusinessExemptFromCertainFees(business) {
  const feeCode = Number(business.trade_license_code);
  return (
    feeCode === 196 ||
    feeCode === 201 ||
    feeCode === 205 ||
    feeCode === 206 ||
    feeCode === 210 ||
    feeCode === 215 ||
    feeCode === 220 ||
    feeCode === 295 ||
    feeCode === 296 ||
    feeCode === 297
  );
}

export function checkIsLiquorPermit(permit) {
  return permitValueNotFalsy(permit.liquor_sale);
}

export function getSbpApplicationFee(business, businessFormData) {
  const tradeLicenseCode = business.trade_license_code;
  const zoneId = business.zone_id;
  const sbpFee = businessFormData?.sbp_fee?.find((subpartFee) => {
    const feeCodeMatchesTradeLicenseCode =
      subpartFee.fee_code === tradeLicenseCode;
    const subpartZoneMatchesBusinessZone = subpartFee.zone_id === zoneId;
    const subpartMatches =
      feeCodeMatchesTradeLicenseCode && subpartZoneMatchesBusinessZone;
    return subpartMatches;
  });
  return sbpFee ?? null;
}

export function capitalizeWord(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function capitalizeWordsInSentence(sentence) {
  const lowerCaseSentence = sentence.toLowerCase();
  const capitalizedSentence = lowerCaseSentence
    .split(" ")
    .map((word) => capitalizeWord(word))
    .join(" ");
  return capitalizedSentence;
}

export function getYearsAgoAsYMD(years) {
  return getJsDateAsYMD(new Date(Date.now() - convertYearsToMs(years)));
}

export function addPostalCodeToPostalTown(postalTown) {
  return `${postalTown.postal_town} - ${postalTown.postal_code}`;
}

export function formatDatabasePhoneNumber(phoneNumber) {
  const regex = /^(254)|(7)/;
  return phoneNumber?.replace(regex, "");
}

export function formatPhoneNumberForPayment(phoneNumber) {
  const regex = /^(254)|(7)/;
  return phoneNumber.replace(regex, (_, countryCodeMatch, sevenMatch) => {
    if (!!countryCodeMatch) {
      return "0";
    } else if (!!sevenMatch) {
      return "07";
    }
  });
}

export function getUnpaidBills(bills) {
  return bills.filter((bill) => bill.work_flow_status.status_name === "Unpaid");
}

export function getResponseErrorType(error) {
  const responseError = error?.response ?? null;
  // const errorIsResponseError = !!responseError
  // if (!errorIsResponseError) {
  //     throw (error)
  // }

  const errorText = responseError.data.error;

  switch (errorText) {
    case errors.PROPERTY_NOT_FOUND:
      return errors.PROPERTY_NOT_FOUND;
    case errors.PROPERTY_SEARCH_FEES_NOT_CONFIGURED:
      return errors.PROPERTY_SEARCH_FEES_NOT_CONFIGURED;
    default:
      return requestStatus.ERROR;
  }
}

export function convertStringToJsDate(dateString) {
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate;
}

export function convertJsDateToYMD(jsDate) {
  return jsDate.toISOString().split("T")[0];
}

export function convertDateStringToDMY(dateString) {
  const jsDate = convertStringToJsDate(dateString);
  return jsDate.toLocaleDateString("en-GB");
}

export function hideBodyScroll(hide = true) {
  let overflowY = "hidden";
  let height = "100vh";
  if (!hide) {
    overflowY = "auto";
    height = "auto";
  }
  const element = document.querySelector(".v-application--wrap");
  element.style.overflowY = overflowY;
  element.style.height = height;
}

export function downloadBlob({ blob, filename = "file.pdf" }) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function getFileDownloadUrl({
  billId,
  receiptNumber,
  propertyRateData,
  propertySearchCertPrn,
  vehicleStickerIds,
  shouldDownloadVehicleList,
  vehicles
}) {
  let url = "";
  if (!!billId) {
    url = `/api/client/bill/generate-bill-pdf?bill_id=${billId}`;
  }
  if (!!propertyRateData) {
    url = `/api/client/property/generate-property-rate-statement-pdf?type=pdf&prn=${propertyRateData.prn}&date_from=${propertyRateData.fromDate}&date_to=${propertyRateData.toDate}&county=${propertyRateData.county}`;
  }
  if (!!propertySearchCertPrn) {
    url = `/api/client/property/generate-property-search-pdf?prn=${propertySearchCertPrn}`;
  }
  if (!!vehicleStickerIds) {
    const vehicleIdsAsString = vehicleStickerIds.join(",");
    url = `/api/client/vehicle/generate-vehicle-sticker-pdf?sticker_id=${vehicleIdsAsString}`;
  }
  if (!!vehicles) {
    const vehicleIds = vehicles.map(vehicle=>vehicle.id);
    url = getPrintVehicleStickerUrl(vehicleIds);
  }
  if (!!shouldDownloadVehicleList) {
    url = `/api/client/vehicle?type=pdf`;
  }

  url += `&county=${process.env.VUE_APP_COUNTY}`;

  if (!!receiptNumber) {
    url = `/api/client/receipt/${receiptNumber}?county=${process.env.VUE_APP_COUNTY}`;
  }

  return url;
}

export function getFormattedPhoneNumber({ phoneNumber, countryCode }) {
  const formattedNumber = getPhoneNumberWithCallingCode(
    phoneNumber,
    countryCode
  );
}

export function getErrorFromResponseError(responseError) {
  return responseError?.response ?? null;
}

export function isValidationError(responseError) {
  const error = responseError?.response ?? null;
  const is422 = error?.status === 422;
  const errorMessage = error?.data?.message;
  const backendErrorMessage = "The given data was invalid.";
  return is422 && errorMessage === backendErrorMessage;
}

export function mapValidationErrorsToObj(responseError, obj) {
  const error = getErrorFromResponseError(responseError);
  const validationErrors = error?.data?.errors ?? {};
  Object.entries(validationErrors).forEach(([errorKey, errorMsg]) => {
    obj[errorKey] = errorMsg;
  });
}

export function isFn(possibleFunc) {
  return possibleFunc instanceof Function;
}

export function propertyRatesPayable(property) {
  const propertyHasNoRates = !!!property.rates;
  if (propertyHasNoRates) {
    return `Your property (PRN: ${property.prn}) has not been been assigned any rates. Please visit your
    subcounty revenue office for assistance.`;
  }
  const ratesPayable = property.are_eportal_rates_payable === true;
  if (!ratesPayable) {
    return `Your last property rates payment for this property (PRN: ${property.prn}) was before 1<sup>st</sup> August 2022. Please visit your
      subcounty revenue office for assistance.`;
  }
  return true;
}

export function getVehicleSticker(vehicle) {
  return vehicle?.stickers?.[0];
}

export function getVehicleBill(vehicle) {
  const sticker = getVehicleSticker(vehicle);
  return sticker?.vehicle_bill;
}

export function vehicleStickerExpired(vehicleSticker) {
  if (!!vehicleSticker) {
    const validTo = new Date(vehicleSticker.valid_to).getTime();
    const now = new Date().getTime();
    const stickerExpired = now - validTo >= 0;
    return stickerExpired;
  }
  return true;
}

export function getVehicleDatePaid(vehicle) {
  const sticker = getVehicleSticker(vehicle);
  const datePaid = sticker
    ? getJsDateAsYMD(new Date(sticker.vehicle_bill.date_paid))
    : null;
  return datePaid;
}

export function getVehicleValidTo(vehicle) {
  const sticker = getVehicleSticker(vehicle);
  const validTo = sticker ? getJsDateAsYMD(new Date(sticker.valid_to)) : null;
  return validTo;
}

export function formatVehicleCapacity(vehicleCapacity) {
  const regexToRemove = /Psv Vehicle Sticker:/gi;
  const formattedCapacity = vehicleCapacity.replace(regexToRemove, "");
  return formattedCapacity;
}

export function getFilterItem({
  name,
  value,
  items = null,
  color = "primary",
  type,
  classes = "",
  loading = false,
  requestName,
}) {
  switch (type) {
    case filterTypes.array:
      return {
        name,
        value,
        items,
        color,
        type,
        classes,
        loading,
        requestName,
      };
  }
}

export function removeItemFromArray(item, array) {
  const itemIndex = array.indexOf(item);
  array.splice(itemIndex, 1);
}

export function addNotAuthenticatedHandler(router) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (checkNotAuthenticated(error)) {
        router.push(routes.logout);
      }
      return Promise.reject(error);
    }
  );
}

export function checkNotAuthenticated(error) {
  const responseError = error.response;
  const responseErrorEmpty = !(!!responseError)
  if(responseErrorEmpty){
    return false
  }
  const errorCode = responseError.status;
  const errorText = responseError.data.message;
  const statusCodeIs401 = errorCode === 401;
  const errorTextMatchesAuthError = errorText === "Unauthenticated.";
  if (statusCodeIs401 && errorTextMatchesAuthError) {
    return true;
  }
  return false;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export async function getCsrfToken() {
  const xsrfCookieNotSet = !!!getCookie("XSRF-TOKEN");
  if (xsrfCookieNotSet) {
    await axios.get(process.env.VUE_APP_API_URL + "/sanctum/csrf-cookie");
  }
}

export function isVehicleArchived(vehicle) {
  return vehicle.archived === 1;
}

export function getVehiclesFromReceipt(receipt) {
  return receipt?.invoice_receipts?.[0]?.invoice?.bills_invoice?.bill?.proforma?.proforma_lines?.reduce(
      (vehicles, pfLine) => {
          const vehicle = pfLine?.vehicle;
          if (!!vehicle) {
              vehicles.push(vehicle);
          }
          return vehicles;
      },
      []
  );
}

export function getPrintVehicleStickerUrl(vehicleIds) {
  let vehicleIdsUrlString = "";
  vehicleIds.forEach((vehicleId, index) => {
      if (index !== 0) {
          vehicleIdsUrlString += "&";
      }
      vehicleIdsUrlString += `vehicle_ids[]=${vehicleId}`;
  });
  const stickerPdfUrl = `/api/client/vehicle/generate-bulk-vehicle-sticker-pdf?${vehicleIdsUrlString}`;
  return stickerPdfUrl;
}