import { countyIsKilifi, countyIsKirinyaga } from "@/utils/vue_helpers";
import { routes } from "./routes";

export function getHelpLinks() {
  const kirinyagaHelpLinks = [
    {
      text: "Frequently Asked Questions",
      icon: "mdi-chat-question",
      route: routes.faq,
    },
  ];

  const kilifiHelpLinks = [
    {
      text: "Frequently Asked Questions",
      icon: "mdi-chat-question",
      route: routes.faq,
    },
  ];

  if (countyIsKirinyaga.value) {
    return kirinyagaHelpLinks;
  } else if (countyIsKilifi.value) {
    return kilifiHelpLinks;
  }

  return [];
}
