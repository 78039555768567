var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$mobileBreakpoint)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Services")])],1)]},proxy:true}],null,false,723992646)},_vm._l((_setup.links),function(category,key,index){return _c('v-list-group',{key:index,attrs:{"sub-group":"","no-action":"","value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(category.title))])],1)]},proxy:true}],null,true)},_vm._l((category.links),function(link,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(link.text))])],1)}),1)}),1):_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-bottom":"24","open-on-hover":"","content-class":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs, value }){return [_c('div',_vm._g(_vm._b({style:({ position: 'relative' })},'div',attrs,false),on),[_c('v-btn',{staticClass:"font-weight-bold primary--text text-uppercase",attrs:{"text":""}},[_vm._v("Services")]),_c('div',{style:({
          position: 'absolute',
          height: '24px',
          width: '800px',
          right: 0,
          bottom: 0,
          transform: 'translateY(24px)',
          /* Only active when user has already hovered over button */
          pointerEvents: value === true ? 'initial' : 'none',
        })})],1)]}}])},[_c('v-card',{staticClass:"px-4 pb-6 pt-4",attrs:{"min-width":_vm.$vuetify.breakpoint.mdAndUp ? 800 : null}},[_c('div',{style:({ columnCount: 3, columnGap: '48px' })},_vm._l((_setup.links),function(category,key,index){return _c('div',{key:index,class:{ 'mt-2': index !== 0 },style:({ breakInside: 'avoid' })},[_c('div',{staticClass:"title font-weight-bold",style:({ color: '#014a2f' })},[_vm._v(" "+_vm._s(category.title)+" ")]),_c('ul',_vm._l((category.links),function(link,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('li',{staticClass:"mb-2",style:({
                  transform: hover ? 'scale(1.08)' : null,
                  transformOrigin: 'top left',
                  transition: 'transform 300ms ease-in-out',
                })},[_c('router-link',{class:{
                    'grey--text text-decoration-none': true,
                    'text--darken-3': !hover,
                    'text--darken-4': hover,
                  },attrs:{"to":_setup.countyIsKilifi ? link.route : {}}},[_c('span',[_vm._v(" "+_vm._s(link.text)+" ")])])],1)]}}],null,true)})}),1)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }