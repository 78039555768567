import { parsePhoneNumber as libParsePhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";

export function getPhoneNumberWithCallingCode(phoneNumber, countryCode) {
    const parsedPhoneNumber = libParsePhoneNumber(phoneNumber, countryCode)
    return parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber
}

export function getInternationalNumber(phoneNumber, countryCode) {
    const parsedPhoneNumber = libParsePhoneNumber(phoneNumber, countryCode)
    return parsedPhoneNumber.number
}

export function validatePhoneNumber(phoneNumber, countryCode) {
    const validationResult = validatePhoneNumberLength(phoneNumber, countryCode)
    return validationResult
}

export function getValidPhoneNumberDetails({ phoneNumber, defaultCountryCode }) {
    const phoneNumberWithPlus = `+${phoneNumber}`

    function parsePhoneNumberWithPlus() {
        const parsedPhoneNumberWithPlus = libParsePhoneNumber(phoneNumberWithPlus)
        return parsedPhoneNumberWithPlus
    }

    function parsePhoneNumberWithoutPlus() {
        const parsedPhoneNumberWithoutPlus = libParsePhoneNumber(phoneNumber)
        return parsedPhoneNumberWithoutPlus
    }

    function parseUsingDefaultCountryCode() {
        const numberFromDefaultCountry = libParsePhoneNumber(phoneNumber, defaultCountryCode)
        return numberFromDefaultCountry
    }

    try {
        return parsePhoneNumberWithoutPlus()
    } catch (error) {
        // const countryIsInvalid = error.message === INVALID_COUNTRY
        try {
            return parseUsingDefaultCountryCode()
        } catch (error) {
            throw error
        }

    }
}