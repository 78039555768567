<script setup>
import { routes } from "@/router/routes";
import { lastInList } from "@/utils/functions";
import { countyIsKilifi, useRouter } from "@/utils/vue_helpers";
import { links } from "../services/links";

const { router } = useRouter();

function onLinkClicked(route) {
  const routeNotEmpty = route !== null;
  if (routeNotEmpty) {
    router.value.push(route);
  }
}
</script>

<template>
  <v-list-group v-if="$mobileBreakpoint">
    <template #activator>
      <v-list-item-content>
        <v-list-item-title>Services</v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-group
      v-for="(category, key, index) in links"
      :key="index"
      sub-group
      no-action
      :value="true"
    >
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>{{ category.title }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(link, index) in category.links" :key="index">
        <v-list-item-title>{{ link.text }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
  </v-list-group>

  <v-menu
    v-else
    bottom
    offset-y
    nudge-bottom="24"
    open-on-hover
    content-class=""
  >
    <template #activator="{ on, attrs, value }">
      <div class="" :style="{ position: 'relative' }" v-on="on" v-bind="attrs">
        <v-btn text class="font-weight-bold primary--text text-uppercase"
          >Services</v-btn
        >
        <!-- Makes it so that when we hover outside of button we're still within the absolutely
                    positioned item so menu content still gets shown 
                -->
        <div
          :style="{
            position: 'absolute',
            height: '24px',
            width: '800px',
            right: 0,
            bottom: 0,
            transform: 'translateY(24px)',
            /* Only active when user has already hovered over button */
            pointerEvents: value === true ? 'initial' : 'none',
          }"
        ></div>
      </div>
    </template>
    <v-card
      class="px-4 pb-6 pt-4"
      :min-width="$vuetify.breakpoint.mdAndUp ? 800 : null"
    >
      <div :style="{ columnCount: 3, columnGap: '48px' }">
        <div
          v-for="(category, key, index) in links"
          :key="index"
          :class="{ 'mt-2': index !== 0 }"
          :style="{ breakInside: 'avoid' }"
        >
          <div class="title font-weight-bold" :style="{ color: '#014a2f' }">
            {{ category.title }}
          </div>
          <ul>
            <v-hover v-for="(link, index) in category.links" :key="index">
              <template #default="{ hover }">
                <li
                  class="mb-2"
                  :style="{
                    transform: hover ? 'scale(1.08)' : null,
                    transformOrigin: 'top left',
                    transition: 'transform 300ms ease-in-out',
                  }"
                >
                  <router-link
                    :to="countyIsKilifi ? link.route : {}"
                    :class="{
                      'grey--text text-decoration-none': true,
                      'text--darken-3': !hover,
                      'text--darken-4': hover,
                    }"
                  >
                    <span>
                      {{ link.text }}
                    </span>
                  </router-link>
                </li>
              </template>
            </v-hover>
          </ul>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>
