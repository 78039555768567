import apiCall from '@/utils/api'

const state = {
	client: [],
	clientLoader: false
};

const getters = {
    client: (state) => state.client,
    clientLoader: (state) => state.clientLoader,			
}

const actions = {
    async fetchClient({commit}) {
		const response = await apiCall({url: `/api/client/client`, method: 'GET' });
		commit('setClient', response)
        commit('stopLoader')
	},
	async startClientLoader({commit}){
		commit('startLoader')
	},
	async stopClientLoader({commit}){
		commit('stopLoader')
	},
};

const mutations = {
	setClient: (state, client) => {
		state.client = client
	},	
	startLoader: (state) => state.clientLoader = true,
	stopLoader: (state) => state.clientLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
