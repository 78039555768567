import apiCall from '@/utils/api'

const state = {
	bills: [],
    paidBills: [],
    unpaidBills: [],
    partPaidBills: [],

    billPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    paidBillPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    unpaidBillPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    partPaidBillPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},

    billLoader: false,
};

const getters = {
    billLoader: (state) => state.billLoader,
	bills: (state) => state.bills,
    billPagination: (state) => state.billPagination,

	paidBills: (state) => state.paidBills,
    paidBillPagination: (state) => state.paidBillPagination,

	unpaidBills: (state) => state.unpaidBills,
    unpaidBillPagination: (state) => state.unpaidBillPagination,

	partPaidBills: (state) => state.partPaidBills,
    partPaidBillPagination: (state) => state.partPaidBillPagination,
}

const actions = {
    async fetchBills({commit}, page) {
		const response = await apiCall({url: `/api/client/bill?type=all&page=${page}`, method: 'GET' });
		commit('setBills', response)
        commit('stopLoader', response)
	},
    async fetchPaidBills({commit}, page) {
		const response = await apiCall({url: `/api/client/bill?type=paid&page=${page}`, method: 'GET' });
		commit('setPaidBills', response)
        commit('stopLoader', response)
	},
    async fetchUnPaidBills({commit}, page) {
		const response = await apiCall({url: `/api/client/bill?type=unpaid&page=${page}`, method: 'GET' });
		commit('setUnPaidBills', response)
        commit('stopLoader', response)
	},
    async fetchPartPaidBills({commit}, page) {
		const response = await apiCall({url: `/api/client/bill?type=partpaid&page=${page}`, method: 'GET' });
		commit('setPartPaidBills', response)
        commit('stopLoader', response)
	},

    async filterBills({commit, state},resp){
		commit('setBills', resp)
	},

	async startBillLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopBillLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
    startLoader: (state) => state.billLoader = true,
	stopLoader: (state) => state.billLoader = false,

    setBills: (state, bills) => {
		state.bills = bills.data
		state.billPagination.current_page = bills.current_page
		state.billPagination.total = bills.total
		state.billPagination.per_page = bills.per_page
	},
    setPaidBills: (state, paidBills) => {
		state.paidBills = paidBills.data
		state.paidBillPagination.current_page = paidBills.current_page
		state.paidBillPagination.total = paidBills.total
		state.paidBillPagination.per_page = paidBills.per_page
	},
    setUnPaidBills: (state, unpaidBills) => {
		state.unpaidBills = unpaidBills.data
		state.unpaidBillPagination.current_page = unpaidBills.current_page
		state.unpaidBillPagination.total = unpaidBills.total
		state.unpaidBillPagination.per_page = unpaidBills.per_page
	},
    setPartPaidBills: (state, partPaidBills) => {
		state.partPaidBills = partPaidBills.data
		state.partPaidBillPagination.current_page = partPaidBills.current_page
		state.partPaidBillPagination.total = partPaidBills.total
		state.partPaidBillPagination.per_page = partPaidBills.per_page
	},
};

export default {
	state,
	getters,
	actions,
	mutations
}
