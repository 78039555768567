import MockAdapter from 'axios-mock-adapter'
import axios from 'axios'

const mock = new MockAdapter(axios)

// const clientIdNumber = '20055950'
const clientIdNumber = '123456'
const customerNumber = 'IND-000002'

const withDelay = (delay, response) => config => {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve(response);
        }, delay);
    });
};

const checkAccount = {
    notFound() {
        mock.onPost(new RegExp('/check-account'), {
            id_number: clientIdNumber
        }).reply(200, {
            status: 400
        })
    },
    found() {
        mock.onPost(new RegExp('/check-account'), {
            id_number: clientIdNumber
        }).reply(200, {
            status: 200,
            accountActivated: true,
            obscuredPhoneNumber: '0725XXXX34',
            obscuredCustomerName: 'James XXXXX',
            obscuredEmailAddress: 'jamesXXXXXX@gmXXXX.com',
            customerTypeId: 2
        })
    }
}

const createAccount = {
    otpInvalid() {
        mock.onPost(new RegExp('/create-account'), {
            asymmetricMatch(actual) {
                return actual.otp !== '12345'
            }
        }).reply(401, { error: 'invalid otp' })
    },
    validRequest() {
        mock.onPost(new RegExp('/create-account'), {
            asymmetricMatch(actual) {
                return actual.otp === '12345'
            }
        }).reply(200)
    }
}

const login = {
    authenticateUserSuccess() {
        mock.onPost(new RegExp('/authenticate'), {
            asymmetricMatch(actual) {
                const shouldMatch = actual.id_number === clientIdNumber //&& actual.password === 'password'
                console.log(actual.id_number)
                return shouldMatch
            }
        }).reply(200, {
            phoneNumber: '25476534523',
            emailAddress: 'example@test.com'
        })
    },
    authenticateUserError() {
        mock.onPost(new RegExp('/authenticate'), {
            asymmetricMatch(actual) {
                const shouldMatch = actual.id_number !== '12345678'
                return shouldMatch
            }
        }).reply(401, {
            error: 'account not found'
        })
    },
    otpInvalid() {
        mock.onPost(new RegExp('/login'), {
            asymmetricMatch(actual) {
                return actual.otp !== '12345'
            }
        }).reply(401, { error: 'invalid otp' })
    },
    validRequest() {
        mock.onPost(new RegExp('/login'), {
            asymmetricMatch(actual) {
                return actual.otp === '12345'
            }
        }).reply(200)
    },
}

const logout = {
    success() {
        mock.onGet(new RegExp('/logout'),).reply(withDelay(1000, [200, {}]))
    },
    failure() {
        mock.onGet(new RegExp('/logout'),).reply(200)
    }
}

const validateOtp = {
    valid() {
        mock.onPost(new RegExp('/validate-otp'), {
            asymmetricMatch(actual) {
                return actual.otp === '12345'
            }
        }).reply(200, {
            token: '43433A32434B'
        })
    },
    invalid() {
        mock.onPost(new RegExp('/validate-otp'), {
            asymmetricMatch(actual) {
                return actual.otp !== '12345'
            }
        }).reply(401, { error: 'invalid otp' })
    }
}

const resetPassword = {
    success() {
        mock.onPost(new RegExp('/reset-password'), {
            asymmetricMatch(actual) {
                return actual.new_password === '123456'
            }
        }).reply(200)
    },
    error() {
        mock.onPost(new RegExp('/reset-password'), {
            asymmetricMatch(actual) {
                return actual.new_password !== '123456'
            }
        }).reply(400)
    }
}

const getUserData = {
    success() {
        mock.onGet(new RegExp(`/user/account/${clientIdNumber}`)).reply(withDelay(100, [200, {
            profile: {
                "id": "1",
                "customer_name": "Matthew Njoroge",
                "gender_id": "1",
                "other_gender": null,
                "designation": "IT Specialist",
                "postal_address": "63",
                "postal_code": "9 0131",
                "postal_town": "TALA",
                "residential_address": null,
                "phone_number1": "254727003362",
                "phone_number2": null,
                "email_address": "matthewnj@gmail.com",
                "id_number": clientIdNumber,
                "date_of_birth": "1992-03-13",
                "profile_pic": null,
                "customer_type_id": "1",
                "customer_serial": "EZKQUTQ5A2",
                "customer_number": customerNumber,
                "sub_county_id": null,
                "disability": "1",
                "ncplwd_no": "3432443",
                "date_of_issuance": "2021-12-20",
                "created_at": "2021-12-20 06:48:07",
                "updated_at": "2022-07-07 11:39:55",
                "created_by": "5",
                "updated_by": "1",
                "deleted_by": null,
                "deleted_at": null,
                "ward_id": null,
                "town_id": null,
                "kra_pin": null,
                "verified": "1"
            },
            properties: [
                {
                    "id": 30146,
                    "clients_id": 47344,
                    "property_id": 1931,
                    "is_primary_owner": 1,
                    "owners_group_code": "NHNYJF0NTL",
                    "date_from": null,
                    "date_to": null,
                    "created_at": "2022-12-01T12:31:09.000000Z",
                    "updated_at": "2022-12-01T12:31:09.000000Z",
                    "created_by": "56",
                    "updated_by": null,
                    "deleted_by": null,
                    "is_active": 0,
                    "property": {
                        "id": 1931,
                        "prn": "5-140-00032",
                        "form_no": null,
                        "lr_no": null,
                        "block_no": "KIBIRIGWI",
                        "document_no": null,
                        "minute_no": null,
                        "minute_date": "2011-01-21 15:32:03",
                        "physical_location": "KIBIRIGWI",
                        "longitude": "35.0049816",
                        "latitude": "1.0162455",
                        "property_doc_type_id": 1,
                        "sub_county_id": 5,
                        "ward_id": 17,
                        "town_id": 309,
                        "zone_id": null,
                        "zone_location_id": null,
                        "property_app_source_id": 5,
                        "property_area_unit_id": 1,
                        "property_roll_type_id": 6,
                        "property_land_use_type_id": 1,
                        "property_registration_reason_id": null,
                        "property_valuation_method_id": 1,
                        "property_interest_type_id": 2,
                        "property_ownership_id": 1,
                        "property_rating_id": 1,
                        "property_rent_type_id": 1,
                        "property_category_id": 1,
                        "currency_id": 1,
                        "length": null,
                        "width": null,
                        "area": "0.000000",
                        "total_area": "0.000000",
                        "site_value": "0.00",
                        "annual_rate": "2000.00",
                        "annual_ground_rent": "500.00",
                        "other_charges": "270.00",
                        "last_payment_amount": "3400.00",
                        "last_payment_date": "2022-12-01",
                        "rateable_value": "0.00",
                        "authority_reference": null,
                        "status": 17,
                        "mother_plot_prn": null,
                        "created_at": "2022-07-08T09:32:32.000000Z",
                        "updated_at": "2022-12-01T12:31:09.000000Z",
                        "created_by": "1",
                        "updated_by": "1",
                        "deleted_by": null,
                        "deleted_at": null,
                        "plot_no": "M/S 3",
                        "property_plot_type_id": 4,
                        "subdivision_application_id": null,
                        "primary_property_owner": {
                            "id": 2017,
                            "clients_id": 18084,
                            "property_id": 1931,
                            "is_primary_owner": 1,
                            "owners_group_code": "LFMS27227",
                            "date_from": "2022-07-24",
                            "date_to": null,
                            "created_at": "2022-07-24T14:33:33.000000Z",
                            "updated_at": "2022-07-24T14:33:33.000000Z",
                            "created_by": "1",
                            "updated_by": "1",
                            "deleted_by": null,
                            "is_active": 0
                        },
                        "active_property_owners": [
                            {
                                "id": 2017,
                                "clients_id": 18084,
                                "property_id": 1931,
                                "is_primary_owner": 1,
                                "owners_group_code": "LFMS27227",
                                "date_from": "2022-07-24",
                                "date_to": null,
                                "created_at": "2022-07-24T14:33:33.000000Z",
                                "updated_at": "2022-07-24T14:33:33.000000Z",
                                "created_by": "1",
                                "updated_by": "1",
                                "deleted_by": null,
                                "is_active": 0
                            }
                        ],
                        "rates": {
                            "id": 1673297,
                            "property_id": 1931,
                            "reference_no": "Payment-12-2022",
                            "reference_date": "2022-12-01 15:24:58",
                            "annual_rates_arrears": "0.00",
                            "annual_rates": "0.00",
                            "accumulated_penalty": "0.00",
                            "ground_rent_arrears": "0.00",
                            "ground_rent": "0.00",
                            "other_charges_arrears": "0.00",
                            "other_charges": "0.00",
                            "waiver": "0.00",
                            "receipt_amount": "3400.00",
                            "receipt_no": "R12622",
                            "adjustment": "0.00",
                            "current_balance": "0.00",
                            "created_at": "2022-12-01T12:24:58.000000Z",
                            "updated_at": "2022-12-01T12:24:58.000000Z",
                            "created_by": null,
                            "updated_by": null,
                            "bills": []
                        },
                        "waiver": null,
                        "drafts": null,
                        "property_doc_type": {
                            "id": 1,
                            "name": "Title Deed",
                            "description": "Title Deed",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "sub_county": {
                            "id": 5,
                            "sub_county_name": "Saboti",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin",
                            "refId": null
                        },
                        "ward": {
                            "id": 17,
                            "ward_name": "Matisi",
                            "sub_county_id": 5,
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin",
                            "refId": null
                        },
                        "town": {
                            "id": 309,
                            "town_name": "Matisi Town 309",
                            "ward_id": 17,
                            "created_at": "2022-01-18T03:58:00.000000Z",
                            "updated_at": "2022-01-18T03:58:00.000000Z",
                            "created_by": "1",
                            "updated_by": "1"
                        },
                        "zone": null,
                        "zone_location": null,
                        "property_application_source": {
                            "id": 5,
                            "source_name": "KIRINYAGA COUNTY COUNCIL",
                            "created_at": "2022-07-27T16:03:28.000000Z",
                            "updated_at": "2022-07-27T16:03:28.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_area_unit": {
                            "id": 1,
                            "unit_name": "Sq Feet",
                            "unit_code": "SQF",
                            "description": "Square Feet",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_roll_type": {
                            "id": 6,
                            "roll_type": "This Local Authority",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_land_use_type": {
                            "id": 1,
                            "land_use_type": "Agricultural",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_valuation_method": {
                            "id": 1,
                            "valuation_method": "Unimproved Site Value",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_interest_type": {
                            "id": 2,
                            "interest_type": "Compound Interest",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_ownership": {
                            "id": 1,
                            "ownership": "Leasehold",
                            "created_by": "Admin",
                            "updated_by": "Admin",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z"
                        },
                        "property_rating": {
                            "id": 1,
                            "rating": "Flat Rate By Plot Size",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_rent_type": {
                            "id": 1,
                            "rent_type": "Flat Rate",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "property_category": {
                            "id": 1,
                            "category_name": "Property",
                            "created_at": "2021-12-07T09:49:04.000000Z",
                            "updated_at": "2021-12-07T09:49:04.000000Z",
                            "created_by": "Admin",
                            "updated_by": "Admin"
                        },
                        "currency": {
                            "id": 1,
                            "country_id": 1,
                            "currency_code": "KSH",
                            "currency_name": "Kenyan Shilling",
                            "is_default": 1,
                            "created_at": "2021-12-07T09:49:05.000000Z",
                            "updated_at": "2021-12-07T09:49:05.000000Z",
                            "created_by": "1",
                            "updated_by": null
                        },
                        "property_owners": [
                            {
                                "id": 2017,
                                "clients_id": 18084,
                                "property_id": 1931,
                                "is_primary_owner": 1,
                                "owners_group_code": "LFMS27227",
                                "date_from": "2022-07-24",
                                "date_to": null,
                                "created_at": "2022-07-24T14:33:33.000000Z",
                                "updated_at": "2022-07-24T14:33:33.000000Z",
                                "created_by": "1",
                                "updated_by": "1",
                                "deleted_by": null,
                                "is_active": 0
                            },
                            {
                                "id": 30146,
                                "clients_id": 47344,
                                "property_id": 1931,
                                "is_primary_owner": 1,
                                "owners_group_code": "NHNYJF0NTL",
                                "date_from": null,
                                "date_to": null,
                                "created_at": "2022-12-01T12:31:09.000000Z",
                                "updated_at": "2022-12-01T12:31:09.000000Z",
                                "created_by": "56",
                                "updated_by": null,
                                "deleted_by": null,
                                "is_active": 0
                            }
                        ]
                    }
                }
            ]
            ,
            bills: [
                {
                    "id": 16141,
                    "bill_number": "BL13756",
                    "client_id": 47352,
                    "bill_type": 10,
                    "sub_county_id": 1,
                    "ward_id": 1,
                    "town_id": 2,
                    "remarks": null,
                    "workflow_status_id": 9,
                    "currency_id": 1,
                    "created_at": "2023-02-22T12:00:09.000000Z",
                    "updated_at": "2023-02-22T12:00:09.000000Z",
                    "created_by": "1",
                    "updated_by": null,
                    "deleted_by": null,
                    "deleted_at": null,
                    "amount": 0,
                    "bill_items": [],
                    "subcounty": {
                        "id": 1,
                        "sub_county_name": "Cherengany",
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin",
                        "refId": null
                    },
                    "ward": {
                        "id": 1,
                        "ward_name": "Cherengany",
                        "sub_county_id": 1,
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin",
                        "refId": null
                    },
                    "currency": {
                        "id": 1,
                        "country_id": 1,
                        "currency_code": "KSH",
                        "currency_name": "Kenyan Shilling",
                        "is_default": 1,
                        "created_at": "2021-12-07T09:49:05.000000Z",
                        "updated_at": "2021-12-07T09:49:05.000000Z",
                        "created_by": "1",
                        "updated_by": null
                    },
                    "town": {
                        "id": 2,
                        "town_name": "Kachibora",
                        "ward_id": 1,
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin"
                    },
                    "work_flow_status": {
                        "id": 9,
                        "code": "UPY",
                        "status_name": "Unpaid",
                        "created_at": "2021-12-07T09:49:06.000000Z",
                        "updated_at": "2021-12-07T09:49:06.000000Z"
                    },
                    "bills_invoices": []
                }
            ]
            ,
            businesses: [
                {
                    "id": 17172,
                    "client_id": 47351,
                    "business_name": "POWON FRESH",
                    "cert_of_registration_no": null,
                    "pin": "",
                    "vat_no": null,
                    "postal_address": "",
                    "postal_code": null,
                    "postal_town": "",
                    "phone_number1": "254726443200",
                    "phone_number2": null,
                    "fax_no": null,
                    "email_address": "",
                    "sub_county_id": 3,
                    "ward_id": 20,
                    "village_id": null,
                    "zone_id": 1,
                    "plot_no": "45",
                    "business_physical_address": "234",
                    "business_premises_area": null,
                    "no_of_employees": null,
                    "description_of_activity": "2",
                    "created_at": "2023-02-16T04:02:18.000000Z",
                    "updated_at": "2023-02-16T04:02:18.000000Z",
                    "created_by": "Admin",
                    "updated_by": "Admin",
                    "business_category": "General Traders And Retail Services",
                    "business_type": "BEER/WINE/SPIRITS/ OTHER DEPOTS",
                    "business_sub_type": null,
                    "business_activity": {
                        "id": 2,
                        "activity_name": "Soda Depot",
                        "fee_code": "101",
                        "created_by": "1",
                        "updated_by": "1",
                        "created_at": "2021-12-15T07:10:28.000000Z",
                        "updated_at": "2021-12-15T07:10:28.000000Z"
                    },
                    "identification_no": "234",
                    "trade_license_code": "101",
                    "town_id": 187,
                    "workflow_status_id": null,
                    "latitude": null,
                    "longitude": null,
                    "sub_county": {
                        "id": 3,
                        "sub_county_name": "Kiminini",
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin",
                        "refId": null
                    },
                    "ward": {
                        "id": 20,
                        "ward_name": "Sirende",
                        "sub_county_id": 3,
                        "created_at": "2022-01-18T03:52:47.000000Z",
                        "updated_at": "2022-01-18T03:52:47.000000Z",
                        "created_by": "1",
                        "updated_by": "1",
                        "refId": null
                    },
                    "town": {
                        "id": 187,
                        "town_name": "Sirende Town 187",
                        "ward_id": 20,
                        "created_at": "2022-01-18T03:58:00.000000Z",
                        "updated_at": "2022-01-18T03:58:00.000000Z",
                        "created_by": "1",
                        "updated_by": "1"
                    },
                    "zone": {
                        "id": 1,
                        "zone_name": "Major Urban Areas",
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "1",
                        "updated_by": "1"
                    },
                    "village": null,
                    "business_permits": [
                        {
                            "id": 22421,
                            "sbp_type": "Beer/Wine/Spirits/ Other Depots",
                            "sbp_sub_type": null,
                            "business_id": 17172,
                            "source": "Office",
                            "sub_county_id": 3,
                            "ward_id": 20,
                            "sbp_number": "K3158",
                            "advertisement_fee": 1,
                            "conservancy_fee": 1,
                            "sanitary_inspection": null,
                            "food_drugs_sale": null,
                            "fire_safety_inspection": null,
                            "betting_fee": null,
                            "solid_waste_fee": null,
                            "public_health_certificate": 1,
                            "receipt_no": null,
                            "permit_status": "Pending",
                            "validity_from": "2023-02-16",
                            "validity_to": "2023-12-31",
                            "approved_by": null,
                            "approval_date": null,
                            "no_of_prints": "0",
                            "last_print_date": null,
                            "last_printed_by": null,
                            "created_at": "2023-02-16T04:02:18.000000Z",
                            "updated_at": "2023-02-16T04:02:18.000000Z",
                            "created_by": "1",
                            "updated_by": "1",
                            "ownland": 2,
                            "public_health_inspection": 1,
                            "sublet": 2,
                            "liquor_sale": 2,
                            "advert_signboard_type": null,
                            "advert_signboard_size": null,
                            "advert_signboard_uom": null,
                            "advert_signboard_no_of_faces": null,
                            "is_imported": 0
                        }
                    ],
                    "liquor_licences": []
                },
                {
                    "id": 17171,
                    "client_id": 47351,
                    "business_name": "JOYANT INN BAR",
                    "cert_of_registration_no": null,
                    "pin": "",
                    "vat_no": null,
                    "postal_address": "",
                    "postal_code": null,
                    "postal_town": "",
                    "phone_number1": "254726443200",
                    "phone_number2": null,
                    "fax_no": null,
                    "email_address": "",
                    "sub_county_id": 2,
                    "ward_id": 7,
                    "village_id": null,
                    "zone_id": 3,
                    "plot_no": "39",
                    "business_physical_address": "KIURIA",
                    "business_premises_area": null,
                    "no_of_employees": null,
                    "description_of_activity": "411",
                    "created_at": "2022-12-02T06:51:14.000000Z",
                    "updated_at": "2022-12-02T06:51:14.000000Z",
                    "created_by": "Jane Kamari",
                    "updated_by": "Jane Kamari",
                    "business_category": "Accommodation And Catering",
                    "business_type": "SMALL RESTAURANT WITH BAR / MEMBERSHIP CLUB; UP TO 10 CUSTOMERS / MEMBERS",
                    "business_sub_type": "1",
                    "business_activity": {
                        "id": 411,
                        "activity_name": "Bar Only (On Bar)",
                        "fee_code": "546",
                        "created_by": "1",
                        "updated_by": "1",
                        "created_at": "2021-12-15T07:10:28.000000Z",
                        "updated_at": "2021-12-15T07:10:28.000000Z"
                    },
                    "identification_no": "25015090",
                    "trade_license_code": "546",
                    "town_id": 15,
                    "workflow_status_id": null,
                    "latitude": "1.06628380000067",
                    "longitude": "34.8618699999799",
                    "sub_county": {
                        "id": 2,
                        "sub_county_name": "Endebess",
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin",
                        "refId": null
                    },
                    "ward": {
                        "id": 7,
                        "ward_name": ".",
                        "sub_county_id": 2,
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin",
                        "refId": null
                    },
                    "town": {
                        "id": 15,
                        "town_name": ". Town 15",
                        "ward_id": 7,
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "Admin",
                        "updated_by": "Admin"
                    },
                    "zone": {
                        "id": 3,
                        "zone_name": "Other Areas",
                        "created_at": "2021-12-07T09:49:04.000000Z",
                        "updated_at": "2021-12-07T09:49:04.000000Z",
                        "created_by": "1",
                        "updated_by": "1"
                    },
                    "village": null,
                    "business_permits": [
                        {
                            "id": 22420,
                            "sbp_type": "Small Restaurant With Bar / Membership Club; Up To 10 Customers / Members",
                            "sbp_sub_type": "1",
                            "business_id": 17171,
                            "source": "Office",
                            "sub_county_id": 2,
                            "ward_id": 7,
                            "sbp_number": "K3157",
                            "advertisement_fee": 1,
                            "conservancy_fee": 1,
                            "sanitary_inspection": null,
                            "food_drugs_sale": null,
                            "fire_safety_inspection": null,
                            "betting_fee": null,
                            "solid_waste_fee": null,
                            "public_health_certificate": 1,
                            "receipt_no": null,
                            "permit_status": "Pending",
                            "validity_from": "2022-12-02",
                            "validity_to": "2022-12-31",
                            "approved_by": null,
                            "approval_date": null,
                            "no_of_prints": "0",
                            "last_print_date": null,
                            "last_printed_by": null,
                            "created_at": "2022-12-02T06:51:14.000000Z",
                            "updated_at": "2022-12-02T06:51:14.000000Z",
                            "created_by": "21",
                            "updated_by": "21",
                            "ownland": 2,
                            "public_health_inspection": 1,
                            "sublet": 1,
                            "liquor_sale": 1,
                            "advert_signboard_type": null,
                            "advert_signboard_size": null,
                            "advert_signboard_uom": null,
                            "advert_signboard_no_of_faces": null,
                            "is_imported": 0
                        }
                    ],
                    "liquor_licences": [
                        {
                            "id": 601,
                            "case_type": null,
                            "licence_type": "Small Restaurant With Bar / Membership Club; Up To 10 Customers / Members",
                            "alcoholic_sub_type_id": 1,
                            "business_category_id": null,
                            "business_id": 17171,
                            "source": "Office",
                            "no_of_days": null,
                            "sub_county_id": 2,
                            "ward_id": 7,
                            "liquor_no": "L599",
                            "validity_from": "2022-12-02",
                            "validity_to": "2022-12-31",
                            "approved_by": null,
                            "approval_date": null,
                            "no_of_prints": "0",
                            "last_print_date": null,
                            "last_printed_by": null,
                            "status": "Pending",
                            "created_at": "2022-12-02T06:51:14.000000Z",
                            "updated_at": "2022-12-02T06:51:14.000000Z",
                            "created_by": "21",
                            "updated_by": "21"
                        }
                    ]
                }
            ]
            ,
            receipts: [
                {
                    "id": 12730,
                    "receipt_number": "R12629",
                    "amount": "400",
                    "payment_date": "2022-12-02 09:16:23",
                    "status": "8",
                    "created_at": "2022-12-02T06:16:23.000000Z",
                    "updated_at": "2022-12-02T06:16:23.000000Z",
                    "created_by": "31",
                    "updated_by": null,
                    "deleted_by": null,
                    "deleted_at": null,
                    "receipt_payment_methods": [
                        {
                            "id": 13493,
                            "receipt_id": 12730,
                            "invoice_id": 15451,
                            "paymode_id": 4,
                            "payment_date": "2022-12-02 09:16:19",
                            "amount": "400.00",
                            "source_bank_id": null,
                            "ref_number": "QL214T85HN",
                            "ref_bank_id": null,
                            "ref_bank_acc_id": null,
                            "is_voided": 0,
                            "pay_mode": {
                                "id": 4,
                                "pay_mode": "Mobile Money",
                                "payments_display": 1,
                                "created_at": "2021-12-07T09:49:06.000000Z",
                                "updated_at": "2021-12-07T09:49:06.000000Z",
                                "created_by": "1",
                                "updated_by": null,
                                "deleted_by": null,
                                "deleted_at": null
                            },
                            "bank": null
                        }
                    ],
                    "invoice_receipts": [
                        {
                            "id": 12799,
                            "receipt_id": 12730,
                            "invoice_id": 15451,
                            "invoice": {
                                "id": 15451,
                                "invoice_no": "B15451",
                                "invoice_amount": "400",
                                "invoice_date": "2022-12-02 09:14:14",
                                "description": "",
                                "status": "8",
                                "created_at": "2022-12-02T06:14:14.000000Z",
                                "updated_at": "2022-12-02T06:16:23.000000Z",
                                "created_by": "39",
                                "updated_by": null,
                                "deleted_at": null
                            }
                        }
                    ]
                }
            ]
        }]))
    }
}

const payment = {
    getRefNumberSuccess() {
        mock.onPost(new RegExp('/payment/generate-ref-number')).reply(withDelay(1000, [200, {
            ref_number: 'AGP375'
        }]))
    },
    stkPushSuccess() {
        mock.onPost(new RegExp('/payment/stk-push')).reply(200, {
            merchant_request_id: '123456'
        })
    },
    stkPushFailure() {
        mock.onPost(new RegExp('/payment/stk-push')).reply(400, {
            'error': 'STK_PUSH_FAILED'
        })
    },
    stkValidationSuccess() {
        mock.onPost(new RegExp('/payment/validate')).reply(200, [
            {
                "id": 71,
                "receipt_number": "R14",
                "amount": "600",
                "payment_date": "2023-03-31 00:04:17",
                "status": "8",
                "created_at": "2023-03-30T21:04:17.000000Z",
                "updated_at": "2023-03-30T21:04:17.000000Z",
                "created_by": "2",
                "updated_by": null,
                "deleted_by": null,
                "deleted_at": null,
                "invoice_receipts": [
                    {
                        "id": 69,
                        "receipt_id": 71,
                        "invoice_id": 82,
                        "invoice": {
                            "id": 82,
                            "invoice_no": "000022",
                            "invoice_amount": "600",
                            "invoice_date": "2023-03-31 00:04:17",
                            "description": "",
                            "status": "8",
                            "created_at": "2023-03-30T15:39:55.000000Z",
                            "updated_at": "2023-03-30T21:04:17.000000Z",
                            "created_by": "2",
                            "updated_by": null,
                            "deleted_by": null,
                            "deleted_at": null,
                            "bills_invoice": {
                                "id": 82,
                                "bill_id": 108,
                                "invoice_id": 82,
                                "created_at": "2023-03-30T15:39:55.000000Z",
                                "updated_at": "2023-03-30T15:39:55.000000Z",
                                "created_by": "2",
                                "updated_by": null,
                                "bill": {
                                    "id": 108,
                                    "bill_number": "EP98",
                                    "client_id": 2,
                                    "bill_type": 17,
                                    "sub_county_id": null,
                                    "ward_id": null,
                                    "town_id": null,
                                    "remarks": null,
                                    "workflow_status_id": 8,
                                    "currency_id": 1,
                                    "created_at": "2023-03-30T15:39:54.000000Z",
                                    "updated_at": "2023-03-30T21:04:17.000000Z",
                                    "created_by": "2",
                                    "updated_by": null,
                                    "deleted_by": null,
                                    "deleted_at": null,
                                    "amount": 600,
                                    "work_flow_status": {
                                        "id": 8,
                                        "code": "PYD",
                                        "status_name": "Paid",
                                        "created_at": "2021-12-09T10:53:44.000000Z",
                                        "updated_at": "2021-12-09T10:53:44.000000Z"
                                    },
                                    "bill_items": [
                                        {
                                            "id": 337,
                                            "bill_id": 108,
                                            "fee_id": 1497,
                                            "fee_name": "Abdominal Scan",
                                            "quantity": "1",
                                            "uom": "N/A",
                                            "sub_total": "600",
                                            "created_at": "2023-03-30T15:39:54.000000Z",
                                            "updated_at": "2023-03-30T15:39:54.000000Z",
                                            "created_by": "2",
                                            "updated_by": null,
                                            "deleted_by": null,
                                            "deleted_at": null
                                        }
                                    ]
                                }
                            }
                        }
                    }
                ],
                "work_flow_status": {
                    "id": 8,
                    "code": "PYD",
                    "status_name": "Paid",
                    "created_at": "2021-12-09T10:53:44.000000Z",
                    "updated_at": "2021-12-09T10:53:44.000000Z"
                }
            }
        ])
    }
}

// checkAccount.notFound()
// checkAccount.found()

// createAccount.otpInvalid()
// createAccount.validRequest()

// login.authenticateUserSuccess()
// login.authenticateUserError()
// login.otpInvalid()
// login.validRequest()

// logout.success()

// validateOtp.valid()
// validateOtp.invalid()

// resetPassword.success()
// resetPassword.error()

// getUserData.success()

// payment.getRefNumberSuccess()
// payment.stkPushSuccess()
// payment.stkPushFailure()
// payment.stkValidationSuccess()

mock//.onPost(new RegExp('/send-otp')).reply(withDelay(0, [200, null]))
    // .onPost(new RegExp('/create-account')).reply(401, { error: 'invalid otp' })
    .onAny().passThrough()