import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dotenv from 'dotenv'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n';
import { globalMixin } from './plugins/global_mixin'
import { VueQueryPlugin } from "@tanstack/vue-query"

Vue.config.productionTip = false

// Vue.directive("uppercase", {
//   update: function (el,_,vnode) {
//     console.log(vnode)
//     el.value = el.value.toUpperCase()
//   }
// })

dotenv.config()

Vue.use(VueQueryPlugin)

Vue.mixin(globalMixin)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


document.addEventListener("deviceready", () => {
  StatusBar.backgroundColorByHexString('#6200EE');
  //NavigationBar.backgroundColorByHexString('#ffffff');

});