import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import darkMode from './modules/darkMode'
import client from './modules/client'
import language from './modules/language'
// import abilities from "./modules/abilities";
import loginCard from "./modules/loginCard";
import property from './modules/property'
import bill from './modules/bill'
import business from './modules/business'
import county from './modules/county'

import VuexPersist from 'vuex-persist'


Vue.use(Vuex)


const vuexLocalStorage = new VuexPersist({
  key: 'rms_client_app', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin
  ],

  modules: {
    user,
    auth,
    darkMode,
    language,
    // abilities,
    loginCard,
    client,
    property,
    bill,
    business,
    county
  },
  strict: false,
})
