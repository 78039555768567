const state = {
	dark: false,
};

const getters = {
	darkState: (state) => state.dark,
};

const actions = {
	async changeDarkState({commit}, mode) {
		commit('updateDarkState', mode)
	}
};

const mutations = {
	updateDarkState: (state, mode) => (state.dark = mode)
};

export default {
	state,
	getters,
	actions,
	mutations
}