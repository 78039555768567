const state = {
	language: 'en',
};

const getters = {
	language: (state) => state.language,
};

const actions = {
	async changeLanguage({commit}, resp) {
		commit('updateLanguage', resp)
	}
};

const mutations = {
	updateLanguage: (state, language) => (state.language = language)
};

export default {
	state,
	getters,
	actions,
	mutations
}