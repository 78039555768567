import { countyIsKirinyaga } from "@/utils/vue_helpers";
import { routes } from "./routes";

export function getDashboardLinks() {
  const links = [
    {
      text: "Dashboard",
      icon: "mdi-card-multiple",
      route: routes.userAccountDetails,
    },

    {
      text: "View Bills And Receipts",
      icon: "mdi-receipt-text",
      route: routes.viewBills,
    },

    {
      text: "My Businesses",
      icon: "mdi-briefcase",
      route: routes.viewBusinesses,
    },

    {
      text: "My Plots",
      icon: "mdi-home-city",
      route: routes.viewProperties,
    },

    {
      text: "My Rates Statements",
      icon: "mdi-home-analytics",
      route: routes.propertyRateStatement,
    },

    {
      text: "My Vehicles",
      icon: "mdi-car",
      route: routes.viewVehicles,
    },
    {
      text: "My Houses/Stalls",
      icon: "mdi-city",
      route: routes.viewHouses,
    },

    {
      text: "My Profile",
      icon: "mdi-account",
      route: routes.userProfileView,
    },
  ];

  if (!countyIsKirinyaga.value) {
    links.push({
      text: "Change Password",
      icon: "mdi-lock",
      route: routes.userLoggedInResetPassword,
    });
  }

  return links;
}
