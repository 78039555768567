import Vue from "vue";
import apiCall from "../../utils/api";
import { AUTH_LOGOUT } from "../actions/auth";
import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_ACCOUNT_FORM_DATA,
  USER_FORGOT_PASSWORD_OTP_TOKEN,
  USER_OTP_DATA,
  USER_DATA,
} from "../actions/user";
import {
  GET_CUSTOMER_TYPES,
  GET_OTP_DATA,
  GET_PROFILE_DATA,
  GET_USER_DATA,
  GET_SUPPORTING_FORM_DATA_REQUEST_STATUS,
  GET_SHOW_USER_DATA,
} from "../getters/user";
import {
  USER_ACCOUNT_FORM_DATA_REQUEST_STATUS,
  USER_PROFILE,
  SHOW_USER_DATA,
} from "../mutations/user";
import { requestStatus } from "../../utils/constants";
import {
  countyIsKirinyaga,
  userDataRequestStatus,
} from "../../utils/vue_helpers";

const state = {
  status: "",
  profile: null,
  userData: null,
  userDataRequestStatus: requestStatus.NOT_SENDING,
  supportingFormData: {
    subCounties: [],
    wards: [],
    postalTowns: [],
    customerTypes: [],
    genders: [],
  },
  supportingFormDataRequestStatus: requestStatus.NOT_SENDING,
  otpData: {
    forgotPasswordToken: null,
    phoneNumber: "",
    countryCode: "KE",
    emailAddress: "",
    idNumber: "",
  },
  showUserData: process.env.VUE_APP_COUNTY === "KIRINYAGA" ? true : false,
};

const getters = {
  getProfile: (state) => state.profile,
  isProfileLoaded: (state) => !!state.profile.name,
  [GET_CUSTOMER_TYPES]: (state) => state.supportingFormData.customerTypes,
  getGenders: (state) => state.supportingFormData.genders,
  getSupportingFormData: (state) => state.supportingFormData,
  [GET_OTP_DATA]: (state) => state.otpData,

  [GET_PROFILE_DATA]: (state) => {
    const userDataExists = state.userData !== null;
    if (userDataExists) {
      const userProfile = state.userData.profile;
      const userProfileExists =
        userProfile !== null && userProfile !== undefined;
      if (userProfileExists) {
        return userProfile;
      }
    }
    return null;
  },

  [GET_USER_DATA]: (state) => {
    return state.userData;
  },

  [GET_SUPPORTING_FORM_DATA_REQUEST_STATUS]: (state) => {
    return state.supportingFormDataRequestStatus;
  },

  [GET_SHOW_USER_DATA]: (state) => {
    console.log(state.showUserData);
    return state.showUserData;
  },
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);

    apiCall({ url: "/api/client/get-user", method: "GET" })
      .then((resp) => {
        commit(USER_SUCCESS, resp);
      })
      .catch((resp) => {
        commit(USER_ERROR);
        // dispatch(AUTH_LOGOUT)
        // window.location.reload(true);
      });
  },

  [USER_ACCOUNT_FORM_DATA]: async ({ commit }) => {
    try {
      commit(USER_ACCOUNT_FORM_DATA_REQUEST_STATUS, requestStatus.SENDING);
      const response = await apiCall({
        url: "/api/client/account-form-data",
        method: "GET",
      });
      commit(USER_ACCOUNT_FORM_DATA, response);
      commit(USER_ACCOUNT_FORM_DATA_REQUEST_STATUS, requestStatus.COMPLETE);
      return response;
    } catch (error) {
      commit(USER_ACCOUNT_FORM_DATA_REQUEST_STATUS, requestStatus.ERROR);
    }
  },

  [USER_DATA]: async ({ commit, state }) => {
    try {
      const existingUserIdWithinStore = state.userData.profile.id_number;
      const url = `/api/client/user/account?idNumber=${existingUserIdWithinStore}`;
      userDataRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall({ url, method: "GET" });
      const userData = response;
      commit(USER_DATA, userData);
      userDataRequestStatus.value = requestStatus.COMPLETE;
    } catch (error) {
      userDataRequestStatus.value = requestStatus.ERROR;
      throw error;
    }
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
  },
  [USER_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
  },
  [USER_ACCOUNT_FORM_DATA]: (state, formData) => {
    state.supportingFormData = formData;
  },
  [USER_FORGOT_PASSWORD_OTP_TOKEN]: (state, forgotPasswordOtpToken) => {
    state.otpData.forgotPasswordToken = forgotPasswordOtpToken;
  },
  [USER_OTP_DATA]: (state, otpData) => {
    state.otpData = { ...state.otpData, ...otpData };
  },
  [USER_DATA]: (state, userData) => {
    state.userData = userData;
  },
  [USER_ACCOUNT_FORM_DATA_REQUEST_STATUS]: (state, requestStatus) => {
    state.supportingFormDataRequestStatus = requestStatus;
  },
  [USER_PROFILE]: (state, userProfileData) => {
    const userDataNotEmpty = !!state?.userData;
    if (userDataNotEmpty) {
      state.userData = {
        ...state.userData,
        profile: userProfileData,
      };
    }
  },
  [SHOW_USER_DATA]: (state, shouldShowUserData) => {
    console.log(shouldShowUserData);
    state.showUserData = shouldShowUserData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

function parseUserProfile(userProfileFromServer) {
  const parsedProfile = {
    idNumber: userProfileFromServer.profile.id_number,
    customerName: userProfileFromServer.profile.customer_name,
    phoneNumber1: userProfileFromServer.profile.phone_number1,
    phoneNumber2: userProfileFromServer.profile.phone_number2,
    emailAddress: userProfileFromServer.profile.email_address,
    genderId: userProfileFromServer.profile.gender_id,
    postalTown: userProfileFromServer.profile.postal_town,
    postalCode: userProfileFromServer.profile.postal_code,
    postalAddress: userProfileFromServer.profile.postal_address,
    dateOfBirth: userProfileFromServer.profile.date_of_birth,
    disability: userProfileFromServer.profile.disability,
    customerTypeId: userProfileFromServer.profile.customer_type_id,
    ncplwdNumber: userProfileFromServer.profile.ncplwd_no,
    ncplwdNumberIssuanceDate: userProfileFromServer.profile.date_of_issuance,
  };
  return parsedProfile;
}

function convertDbTypesToRequiredTypes(userProfile) {
  userProfile.customer_type_id = Number(userProfile.customer_type_id);
  userProfile.gender_id = Number(userProfile.gender_id);

  const userDisabled = userProfile.disability === "1";
  if (userDisabled) {
    userProfile.disability = true;
  } else {
    userProfile.disability = false;
  }
}
