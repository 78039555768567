import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    //dark: true,
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#F89406',
        accent: '#D21F26',
        error: '#b71c1c',
        background: "#EEEEEE",
        header: "#FFFFFF",
        popup: "#FFFFFF",
        'field-label': '#757575'
      },
      dark: {
        primary: '#6200EE',
        secondary: '#F89406',
        accent: '#D21F26',
        error: '#b71c1c',
        background: "#121212",
        header: "#272727",
        popup: "#121212"
      },
    },
  },
});
