import axios from "axios";
import "./axios_mocks.js";
import { getCookie } from "./functions.js";

const base_url = process.env.VUE_APP_API_URL;
const apiCall = async ({
  url,
  data,
  method,
  responseType = null,
  withCredentials = true,
}) => {
  return new Promise((resolve, reject) => {
    url = base_url + url;
    console.log(url);
    setTimeout(() => {
      try {
        axios.defaults.headers.common["Authorization"] =
          localStorage.getItem("user-token");
        if (method == "GET") {
          axios
            .get(url, { crossdomain: true, responseType })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(/*new Error(error)*/ error);
            });
        } else if (method == "POST") {
          axios
            .post(url, data, { responseType})
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(/*new Error(error)*/ error);
            });
        } else if (method == "PUT") {
          axios
            .put(url, data)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(/*new Error(error)*/ error);
            });
        } else if (method == "DELETE") {
          axios({ method: "delete", url: url, data: null })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(new Error(error));
            });
        }
      } catch (err) {
        reject(new Error(err));
      }
    }, 1000);
  });
};

export default apiCall;
