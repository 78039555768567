var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app-bar',{staticClass:"flex-grow-0",style:({ zIndex: '10' }),attrs:{"app":"","flat":"","dense":_vm.$vuetify.breakpoint.smAndDown,"color":"grey lighten-4","clipped-left":!_vm.$mobileBreakpoint && _setup.userAuthenticated}},[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-md-none",attrs:{"cols":"auto"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$emit('update:drawerOpen', !_vm.drawerOpen)}}})],1),_c('v-col',{staticClass:"d-none d-md-block mr-md-12",attrs:{"md":"auto"}},[_c(_setup.CountyLogoAndTitle)],1),(!_vm.$mobileBreakpoint)?_c('v-col',{attrs:{"md":"auto"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"primary--text text-body-2"},[_vm._v("Total Visitors:")]),_c(_setup.SkeletonLoader,{staticClass:"ml-2 primary--text",attrs:{"loading":_vm.visitorCountLoading,"type":"image","width":"32px","height":"32px"}},[_c('span',[_vm._v(_vm._s(_vm.visitorCount))])])],1)]):_vm._e(),(_setup.userAuthenticated)?_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"md":"auto"}},[_c('router-link',{attrs:{"to":_setup.routes.generalBilling}},[_c('v-btn',{staticClass:"font-weight-bold primary--text text-uppercase",attrs:{"text":""}},[_vm._v(" General Billing ")])],1)],1):_vm._e(),_c('v-col',{staticClass:"px-0 d-none d-md-block",attrs:{"md":"auto"}},[_c(_setup.Services)],1),_c('v-col',{attrs:{"md":"auto"}},[_c(_setup.HelpMenu)],1),(_setup.userAuthenticated)?[_c('v-col',{staticClass:"pl-6 pr-0",attrs:{"md":"auto"}},[_c(_setup.HomeButton)],1),_c('v-col',{staticClass:"pl-0",attrs:{"md":"auto"}},[_c(_setup.UserDropdown)],1)]:[_c('v-col',{staticClass:"pl-0 pl-md-3",attrs:{"cols":"auto","md":"auto"}},[_c('router-link',{style:({
              textDecoration: 'none',
            }),attrs:{"to":{
              path: '/auth/login',
            }}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"d-flex align-center text-capitalize sign-in-button-background white--text",attrs:{"rounded":""}},[_c('v-icon',[_vm._v("mdi-account-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Sign In")])],1):_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-login")])],1)],1)],1),_c('v-col',{staticClass:"pl-0 pl-md-3",attrs:{"cols":"auto","md":"auto"}},[_c('router-link',{style:({
              textDecoration: 'none',
            }),attrs:{"to":{
              path: '/auth/create',
            }}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"d-flex align-center text-capitalize",attrs:{"outlined":"","rounded":""}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Create Account")])],1):_c('v-btn',{attrs:{"icon":"","color":"secondary"}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1)],1)],1)],(_setup.countyIsKilifi && !_vm.$mobileBreakpoint)?_c('v-col',{attrs:{"cols":"1"}},[_c(_setup.ILabLogo,{attrs:{"height":"48px","contain":""}})],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }