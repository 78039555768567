<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" top :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-app-bar app class="header" elevate-on-scroll>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="isAuthenticated"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!-- <v-layout row wrap class="mr-5 hidden-sm-and-down">
        <v-flex xs9> </v-flex>
        <v-flex xs1>
          <v-img
            v-if="$i18n.locale == 'en'"
            max-width="25"
            src="/img/flags/english.png"
            class="ml-3 mt-1"
          ></v-img>
          <v-img
            v-if="$i18n.locale == 'fr'"
            max-width="25"
            src="/img/flags/french.ico"
            class="ml-3 mt-1"
          ></v-img>
        </v-flex>
        <v-flex xs2>
          <v-select
            :items="languages"
            item-text="title"
            item-value="language"
            v-model="languageChoice"
            class="text_field header"
            @input="changeLocale()"
          ></v-select>
        </v-flex>
      </v-layout> -->

      <v-menu left bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="ml-3 text-none">
            <div>
              {{ getProfile.name }}
            </div>
            <v-avatar v-if="getProfile.profile_pic == null">
              <v-icon dark class="primary--text"> mdi-account-circle </v-icon>
            </v-avatar>
            <v-avatar class="mx-1" v-else>
              <img
                :src="'/storage/profile_pics/' + getProfile.profile_pic"
                :alt="getProfile.name"
              />
            </v-avatar>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/profile">
            <v-list-item-title>
              <div align="center">
                {{ $t("profileMsg") }}
                <v-icon small right>mdi-account</v-icon>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-title>
              <div align="center">
                {{ $t("signOutMsg") }}
                <v-icon small right>mdi-lock</v-icon>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      v-if="isAuthenticated"
      class="header"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <router-link to="/home">
              <v-img class="mt-5" src="img/CGK_Logo.png" />
            </router-link>
            <div class="display-1 secondary--text">
              <b class="primary--text">Kisumu</b> RMS
            </div>
          </v-list-item-title>
          <v-list-item-subtitle>
            <div class="mb-5 hidden-md-and-up ma-1">
              <v-layout row wrap>
                <!-- <v-flex xs2>
                  <div align="right">
                    <v-img
                      v-if="$i18n.locale == 'en'"
                      max-width="25"
                      src="/img/flags/english.png"
                      class="mt-5 mr-2"
                    ></v-img>
                    <v-img
                      v-if="$i18n.locale == 'fr'"
                      max-width="25"
                      src="/img/flags/french.ico"
                      class="mt-5 mr-2"
                    ></v-img>
                  </div>
                </v-flex> -->
                <!-- <v-flex xs10>
                  <v-select
                    :items="languages"
                    item-text="title"
                    item-value="language"
                    v-model="languageChoice"
                    class="text_field header"
                    @input="changeLocale()"
                  ></v-select>
                </v-flex> -->
              </v-layout>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-2"></v-divider>
      <v-layout column>
        <v-flex xs12>
          <v-list dense nav>
            <!--
            <v-list-group no-action prepend-icon="mdi-home-city">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("houseRentalMsg")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in houseRentalPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">{{
                      $t(item.title)
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item :key="index" router :to="item.route" v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <v-list-group :value="true" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("houseConfigurationMsg")
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-home-city</v-icon>
                  </v-list-item-icon>
                </template>
                <template v-for="(item, index) in houseOptionsPages">
                  <v-list-item
                    :key="index"
                    class="primary"
                    router
                    :to="item.route"
                    v-if="item.route == $route.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :key="index" router :to="item.route" v-else>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t(item.title)
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </v-list-group>
-->

            <v-list-group
              no-action
              prepend-icon="mdi-briefcase"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Business Management</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-for="(item, index) in businessPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  :key="index"
                  router
                  :to="item.route"
                  v-if="item.route != $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>

            <!--  <v-list-group
              no-action
              prepend-icon="mdi-google-adwords"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("advertisementsMsg")
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
             <template v-for="(item, index) in advertPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group> -->

            <v-list-group
              no-action
              prepend-icon="mdi-car"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Periodic Billing</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in periodicBillingpages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  :key="index"
                  router
                  :to="item.route"
                  v-if="item.route !== $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <v-list-group :value="true" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title> Options Vehicles </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-car-cog</v-icon>
                  </v-list-item-icon>
                </template>
                <template v-for="(item, index) in vehiclePages">
                  <v-list-item
                    :key="'vehiclePage' + index"
                    class="primary"
                    router
                    :to="item.route"
                    v-if="item.route == $route.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :key="index" router :to="item.route" v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </v-list-group>

            <v-list-group
              no-action
              prepend-icon="mdi-bottle-wine"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Liquor Licensing</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in liquorLicensing">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  :key="index"
                  router
                  :to="item.route"
                  v-if="item.route != $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-group
              no-action
              prepend-icon="mdi-cash"

            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Payments</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in paymentPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  :key="index"
                  router
                  :to="item.route"
                  v-if="item.route != $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-group
              no-action
              prepend-icon="mdi-home-variant"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Properties</v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- <v-list-group :value="true" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Config</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-wrench</v-icon>
                  </v-list-item-icon>
                </template>
                <template v-for="(item, index) in propertyConfigs">
                  <v-list-item
                    :key="index"
                    class="primary"
                    router
                    :to="item.route"
                    v-if="item.route == $route.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :key="index" router :to="item.route" v-if="item.route != $route.path">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group> -->
              <template v-for="(item, index) in propertyPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  :key="index"
                  router
                  :to="item.route"
                  v-if="item.route != $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-group no-action prepend-icon="mdi-home-variant">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>House/Stall Rent</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in housingModulePages">
                <template>
                  <v-list-item
                    :key="index"
                    class="primary"
                    router
                    :to="item.route"
                    v-if="item.route === $route.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :key="index" router :to="item.route" v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
              <v-list-group :value="true" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title> Housing Options </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-car-cog</v-icon>
                  </v-list-item-icon>
                </template>
                <template v-for="(item, index) in houseOptionsPages">
                  <v-list-item
                    :key="'houseRentPage' + index"
                    class="primary"
                    router
                    :to="item.route"
                    v-if="item.route == $route.path"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :key="index" router :to="item.route" v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </v-list-group>

            <template v-for="(item, index) in items">
              <v-list-item
                :key="'items' + index"
                class="primary"
                router
                :to="item.route"
                v-if="item.route == $route.path"
              >
                <v-list-item-icon>
                  <v-icon class="white--text">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    {{ $t(item.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :key="'items' + index"
                router
                :to="item.route"
                v-if="item.route != $route.path"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-group
              no-action
              prepend-icon="mdi-bank-transfer">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Bank Transactions</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in bankTransactionPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item :key="index" router :to="item.route" v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-group
              no-action
              prepend-icon="mdi-briefcase-check"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Reconciliation</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="(item, index) in reconciliationPages">
                <v-list-item
                  :key="index"
                  class="primary"
                  router
                  :to="item.route"
                  v-if="item.route == $route.path"
                >
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ $t(item.title) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon class="white--text">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item :key="index" router :to="item.route" v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-group>
          </v-list>
        </v-flex>
        <!-- <div style="bottom: 0px;">
                <v-layout row wrap>
                    <v-flex xs3>
                        <div class="ml-3 mt-4">Dark</div>
                    </v-flex>
                    <v-flex xs9>
                        <v-switch v-model="darkState" @change="changeDarkState"></v-switch>
                    </v-flex>
                </v-layout>
            </div> -->
        <v-flex xs12>
          <v-container fill-height fluid>
            <v-row align="end" justify="center">
              <v-col class="text-center">
                <div>
                  <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        deprsessed
                        v-on="on"
                        color="info"
                        small
                        @click="changeDarkState"
                      >
                        <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("darkModeOnMsg") }}</span>
                  </v-tooltip>

                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        deprsessed
                        v-on="on"
                        color="info"
                        small
                        @click="changeDarkState"
                      >
                        <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("darkModeOffMsg") }}</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
import apiCall from "../../utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapState, mapActions } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  components: {},

  data: () => ({
    languages: [
      { flag: "us", language: "en", title: "English" },
      { flag: "fr", language: "fr", title: "Français" },
    ],
    path: process.env.VUE_APP_API_URL,
    color: "",
    message: "",
    snackbar: false,
    drawer: true,
    languageChoice: "",

    items: [
      {
        title: "customerManagementMsg",
        icon: "mdi-account-circle",
        route: "/customer-management",
        permission: "customer_view",
      },
      /*
      {
        title: "liquorLicenseMsg",
        icon: "mdi-bottle-wine",
        route: "/liquor-license",
      },
      {
        title: "businessIntelligenceMsg",
        icon: "mdi-briefcase",
        route: "/business-intelligence",
      },


      {
        title: "businessPermitsMsg",
        icon: "mdi-certificate",
        route: "/business-permits",
      },
      */
      {
        title: "generalBillingMsg",
        icon: "mdi-bank",
        route: "/general-billing",
        permission: "bill_view",
      },
      // {
      //   title: "paymentsMsg",
      //   icon: "mdi-account-cash",
      //   route: "/payments"
      // },
      /*
      {
        title: "periodicBillingMsg",
        icon: "mdi-pinwheel",
        route: "/periodioc-billing",
      },
        title: "generalBillingMsg",
        icon: "mdi-bank",
        route: "/general-billing",
      },
      // {
      //   title: "paymentsMsg",
      //   icon: "mdi-account-cash",
      //   route: "/payments"
      // },
      // {
      //   title: "periodicBillingMsg",
      //   icon: "mdi-pinwheel",
      //   route: "/periodioc-billing",
      // },
      {
        title: "creditNoteWaiverMsg",
        icon: "mdi-notebook",
        route: "/credit-note-waiver",
      },
      { title: "reportsMsg", icon: "mdi-note-text", route: "/reports" },
      { title: "selfHelpGroupMsg", icon: "mdi-spa", route: "/self-help-group" },
      {
        title: "elearningMsg",
        icon: "mdi-clipboard-text-multiple",
        route: "/elearning",
      },
      { title: "supportMsg", icon: "mdi-wrench", route: "/support" },
      */
    ],
    propertyPages: [
      {
        title: "Waivers",
        // icon: "mdi-certificate",
        route: "/waiver",
        permission: "waiver_view",
      },
      {
        title: "addNewPropertyMsg",
        // icon: "mdi-home-variant",
        route: "/propertyapplication",
        permission: "property_create",
      },
      {
        title: "propertyApplicationsMsg",
        // icon: "mdi-home-variant",
        route: "/propertyapplications",
        permission: "property_view",
      },
      {
        title: "queryPropertyMsg",
        // icon: "mdi-home-variant",
        route: "/properties",
        permission: "property_view",
      },
      {
        title: "Demand Notices",
        // icon: "mdi-home-variant",
        route: "/bulkdemandnotice",
        permission: "property_view",
      },
    ],
    propertyFees: [
      {
        title: "Rates Calculation",
        route: "/propertyrate",
      },
      {
        title: "Rates Fees",
        // icon: "mdi-certificate",
        route: "/propertyratesfees",
        permission: "property_fee_view",
      },
      {
        title: "Registration Fees",
        // icon: "mdi-certificate",
        route: "/registrationfees",
        permission: "property_fee_view",
      },
      {
        title: "Transfer Fees",
        // icon: "mdi-certificate",
        route: "/propertytransferfees",
        permission: "property_fee_view",
      },
      {
        title: "Subdivision Fees",
        // icon: "mdi-certificate",
        route: "/propertysubdivisonfees",
        permission: "property_fee_view",
      },
      {
        title: "Search Fees",
        // icon: "mdi-certificate",
        route: "/propertysearchfees",
        permission: "property_fee_view",
      },
      {
        title: "Caveat Fees",
        // icon: "mdi-certificate",
        route: "/propertycaveatfees",
        permission: "property_fee_view",
      },
      {
        title: "Under Charge Fees",
        // icon: "mdi-certificate",
        route: "/propertyunderchargefees",
        permission: "property_fee_view",
      },
      /*
      {
        title: "Owner Update Fees",
        // icon: "mdi-certificate",
        route: "/propertyownerupdatefees",
        permission: "property_fee_view",
      },
      */
      {
        title: "Rate Struck",
        // icon: "mdi-certificate",
        route: "/propertyannualrate",
        permission: "property_annual_rate_view",
      },
      {
        title: "Interest Calculation",
        // icon: "mdi-certificate",
        route: "/propertyconfigs",
        permission: "property_interest_rate_view",
      },
    ],
    propertyConfigs: [],
    dashboardPagesA: [
      {
        title: "revenueTargetsMsg",
        route: "/revenue-targets",
      },
      {
        title: "totalRevenueMsg",
        route: "/total-revenue",
      },
    ],
    dashboardPagesB: [
      {
        title: "departmentsMsg",
        route: "/department-revenue",
      },
      {
        title: "marwaMsg",
        route: "/marwa",
      },
      {
        title: "parkingEnforcementMsg",
        route: "/parking-enforcement",
      },
      {
        title: "marketEnforcementMsg",
        route: "/market-enforcement",
      },
      {
        title: "staffPerformanceMsg",
        route: "/staff-performance",
      },
      {
        title: "debtRecoveryMsg",
        route: "/debt-recovery",
      },
    ],

    subCountyPages: [
      {
        title: "overallMsg",
        route: "/home/subcounties-revenue/all",
      },
      {
        title: "kisumuCityMsg",
        route: "/home/subcounties-revenue/8",
      },
      {
        title: "kisumuCentralMsg",
        route: "/home/subcounties-revenue/2",
      },
      {
        title: "kisumuEastMsg",
        route: "/home/subcounties-revenue/1",
      },
      {
        title: "kisumuWestMsg",
        route: "/home/subcounties-revenue/3",
      },
      {
        title: "muhoroniMsg",
        route: "/home/subcounties-revenue/4",
      },
      {
        title: "nyakachMsg",
        route: "/home/subcounties-revenue/5",
      },
      {
        title: "nyandoMsg",
        route: "/home/subcounties-revenue/6",
      },
      {
        title: "semeMsg",
        route: "/home/subcounties-revenue/7",
      },
    ],
    revenueStreamsPages: [
      {
        title: "overallMsg",
        route: "/home/revenue-streams/all",
      },
      {
        title: "marketMsg",
        route: "/home/revenue-streams/1",
      },
      {
        title: "busParkMsg",
        route: "/home/revenue-streams/2",
      },
      {
        title: "cessMsg",
        route: "/home/revenue-streams/3",
      },
      {
        title: "healthMsg",
        route: "/home/revenue-streams/5",
      },
      {
        title: "dailyParkingMsg",
        route: "/home/revenue-streams/10",
      },
    ],
    paymentPages: [
      {
        title: "Process Payments",
        // icon: "mdi-account-cash",
        route: "/process_payments",
        permission: "process_payment",
      },
      {
        title: "Query Payments",
        // icon: "mdi-account-cash",
        route: "/payments",
        permission: "payment_view",
      },

      {
        title: "bankCollectionMsg",
        route: "/collection_banking",
        permission: "bank_collections_view",
      },
      {
        title: "chequeCollectionMsg",
        route: "/cheque_collections",
        permission: "cheque_collections_view",
      },
      {
        title: "Voided Payments",
        // icon: "mdi-account-cash",
        route: "/voided-payments",
        permission: "payment_view",
      },
    ],

    reportPages: [
      {
        title: "PH Payment Analysis",
        route: "/ph_payments",
      },

      {
        title: "PH Register",
        route: "/ph_register",
      },
    ],

    firesafety_reports:[

      {
        title: "Firesafety Certificate Register",
        route: "/firecert_register",
      },
      {
        title: "Firesafety Certificate Payment Analysis",
        route: "/firecert_payment",
      },
    ],

    advertPages: [
      {
        title: "adSizeTypesMsg",
        route: "/advertisement-size",
        permission: "advertisement_size_view",
      },
      {
        title: "adCategoriesMsg",
        route: "/advertisement-category",
        permission: "advertisement_category_view",
      },
      {
        title: "adSubCategoriesMsg",
        route: "/advertisement-subcategory",
        permission: "advertisement_sub_category_view",
      },
      {
        title: "advertisementsMsg",
        route: "/advertisement-module",
        permission: "advertisements_view",
      },
    ],

    liquorLicensing: [
      {
        title: "liquorLicenseMsg",
        route: "/liquor-licence",
        permission: "liquor_permits_view",
      },
    ],

    businessPages: [
      {
        title: "businessRegisterMsg",
        // icon: "mdi-briefcase",
        route: "/register-business",
        permission: "business_create",
      },

      {
        title: "Query Business",
        // icon: "mdi-briefcase",
        route: "/businesses",
        permission: "business_view",
      },

      {
        title: "businessPermitsMsg",
        // icon: "mdi-certificate",
        route: "/business-permits",
        permission: "business_permits_view",
      },
    ],

    periodicBillingpages: [
      {
        title: "Vehicles",
        route: "/vehicle-owners",
        icon: "mdi-car-2-plus",
        permission: "vehicles_view",
      },
      {
        title: "Applications",
        route: "/vehicle-applications",
        icon: "mdi-car-info",
        permission: "vehicles_view",
      },
      {
        title: "Reports",
        icon: "mdi-car-info",
        route: "/periodic-billing/reports",
        permission: "vehicles_view",
      },
      {
        title: "Fee Mapping",
        route: "/vehicle-fee-mapping",
        icon: "mdi-car-info",
        permission: "vehicles_view",
      },
    ],
    vehiclePages: [
      /*{
              title: "vehicleMakeMsg",
              route: "/vehicle_make",
              permission: "vehicle_make_view",
          },
          {
              title: "vehicleModelMsg",
              route: "/vehicle_model",
              permission: "vehicle_model_view",
          },*/
      {
        title: "vehicleCapacityMsg",
        route: "/vehicle_capacity",
        permission: "vehicle_capacity_view",
      },
      {
        title: "vehicleTypeMsg",
        route: "/vehicle_type",
        permission: "vehicle_type_view",
      },
      {
        title: "vehicleAccountMsg",
        route: "/vehicle_account",
        permission: "vehicle_account_view",
      },
      {
        title: "billingCategoryMsg",
        route: "/billing_category",
        permission: "billing_category_view",
      },
      {
        title: "vehicleMsg",
        route: "/vehicle",
        permission: "vehicles_view",
      },
    ],
    houseRentalPages: [
      // {
      //   title: "houseGenerateInvoiceMsg",
      //   route: "/generateInvoice",
      // },
      {
        title: "houseLandlordsMsg",
        route: "/houseLandlords",
      },
      {
        title: "houseApartmentMsg",
        route: "/houseApartments",
      },
      {
        title: "houseRegistrationMsg",
        route: "/registerHousing",
      },
      {
        title: "houseTenantsMsg",
        route: "/houseTenants",
      },

      // {
      //   title: "housepplicationMsg",
      //   route: "/houseApplication",
      // },
    ],
    houseOptionsPages: [
      {
        title: "tenantPaymodeMsg",
        route: "/tenantPaymode",
      },
      {
        title: "tenantEmployerMsg",
        route: "/tenantEmployer",
      },
      {
        title: "houseEstatesMsg",
        route: "/houseEstates",
      },
      {
        title: "houseStallTypeMsg",
        route: "/houseStallType",
      },
      {
        title: "houseStatusMsg",
        route: "/houseStatus",
      },
    ],
    reconciliationPages: [
      {
        title: "Collector Reconciliation",
        // icon: "mdi-certificate",
        route: "/reconciliation/collector",
        permission: "collector_reconciliation_view",
      },
      // {
      //   title: "Cashier Reconciliation",
      //   // icon: "mdi-certificate",
      //   route: "/reconciliation/bank",
      //   permission: "bank_reconciliation_view",
      // },
    ],
    housingModulePages: [
      {
        title: "Registration",
        route: "/house-stall-registration",
      },
      {
        title: "Query House/Stall",
        route: "/housing-management",
      },
      {
        title: "Applications",
        route: "/housing-applications",
      },
      {
        title: "Rent Fee Mapping",
        route: "/house-stall-fee-mapping",
      },
    ],

    bankTransactionPages: [
      {
        title: "Bank Transactions",
        route: "/bank/transactions",
        permission: "bank_transactions_view",
      },
      {
        title: "Voided Bank Transactions",
        route: "/bank/transactions-voided",
        permission: "voided_bank_transactions_view",
      },      
    ],
  }),
  watch: {
    darkState(oldval, newval) {
      this.handledarkmode();
    },
  },
  created() {
    this.$store.dispatch(USER_REQUEST);
    this.initialize();
  },
  methods: {
    ...mapActions([
      "changeLoginCardState",
      "changeDarkState",
      "changeLanguage",
    ]),
    initialize() {
      this.languageChoice = this.language;
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },
    changeLocale() {
      i18n.locale = this.languageChoice;
      this.changeLanguage(this.languageChoice);
    },
    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then((response) => {
        // this.$router.push("/")
        // this.$store.dispatch("changeLoginCardState");
        this.$router.go("/");
      });
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "darkState",
      "language",
    ]),
  },
};
</script>
