/* eslint-disable promise/param-names */
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from '../actions/auth'
import { USER_REQUEST } from '../actions/user'
import { AUTH_TOKEN } from '../mutations/auth'
import apiCall from '../../utils/api'

const state = { token: localStorage.getItem('user-token') || '', status: '', hasLoadedOnce: false }

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
}

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)

      apiCall({ url: '/api/client/login', data: user, method: 'POST' }).then(resp => {
        commit(AUTH_SUCCESS, resp)
        localStorage.setItem('user-token', 'Bearer ' + resp.token)
        // dispatch(USER_REQUEST)
        resolve(resp)
      }).catch(err => {
        commit(AUTH_ERROR, err)
        localStorage.removeItem('user-token')
        reject(err)
      })
    })
  },
  [AUTH_LOGOUT]: async ({ commit, rootState, }) => {
    try {
      const userId = rootState.user.userData.profile.id_number
      const url = `/api/client/logout?id_number=${userId}`
      await apiCall(
        { url, method: 'GET' }
      )
    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      commit(AUTH_LOGOUT)
    }
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success'
    state.token = token
    localStorage.setItem('user-token', 'Bearer ' + token)
    // state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    localStorage.setItem('user-token', '')
    state.token = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
